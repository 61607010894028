import React from "react";

import ThirdPartyTableDeleteAction from "./thirdPartyTableDeleteAction";

const ThirdPartyTableActions = ({ rowData }: any) => {
  return (
    <ThirdPartyTableDeleteAction
      rowData={rowData}
      key="thirdPartyTableDeleteAction"
    />
  );
};

export default ThirdPartyTableActions;
