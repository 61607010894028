import React from "react";

import { useParams } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";
import { useMutation, useQueryClient } from "react-query";

import {
  Form,
  Input,
  Button,
  Offcanvas,
  useDrawerContext,
  OffcanvasContents,
  OffCanvasDismissButton,
} from "components";

import { addPageKey, updateKey } from "api";

import { useSelectedItemForEditContext } from "context";

import { error, success } from "utils";

import { validationSchema } from "./validationSchema";

const AddPageKey = () => {
  const { selectedItemForEdit, setSelectedItemForEdit } =
    useSelectedItemForEditContext();
  const params = useParams();

  const [, setIsOpen] = useDrawerContext();

  const queryClient = useQueryClient();

  const addMutation = useMutation(addPageKey, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("pageKeyList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const updateMutation = useMutation(updateKey, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("pageKeyList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const handleSubmit = (values, formik) => {
    if (selectedItemForEdit) {
      updateMutation
        .mutateAsync({
          id: selectedItemForEdit?._id,
          ...values,
        })
        .then(() => {
          formik.setSubmitting(false);
          formik.resetForm();
        })
        .then(() => setIsOpen(false));
    } else {
      addMutation
        .mutateAsync({
          page: params?.labelPageId,
          ...values,
        })
        .then(() => {
          formik.setSubmitting(false);
          formik.resetForm();
        })
        .then(() => setIsOpen(false));
    }
  };
  return (
    <Formik
      initialValues={{
        keyName: selectedItemForEdit ? selectedItemForEdit?.keyName : "",
        description: selectedItemForEdit
          ? selectedItemForEdit?.description
          : "",
      }}
      enableReinitialize={true}
      onSubmit={() => console.log("clicked")}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { isSubmitting, isValid, dirty, values } = formik;

        return (
          <Form as={FormikForm}>
            <OffcanvasContents
              title={selectedItemForEdit ? "Update Key" : "Add Key"}
            >
              <Offcanvas.Body>
                <Input
                  name="keyName"
                  label="Enter name"
                  placeholder="Enter name"
                />
                <Input
                  name="description"
                  label="Enter description"
                  placeholder="Enter description"
                />
              </Offcanvas.Body>
              <Offcanvas.Footer>
                <OffCanvasDismissButton>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setSelectedItemForEdit(null)}
                  >
                    Cancel
                  </Button>
                </OffCanvasDismissButton>
                <Button
                  type="button"
                  onClick={() => handleSubmit(values, formik)}
                  className="ms-2"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  {selectedItemForEdit ? "Update" : "Submit"}
                </Button>
              </Offcanvas.Footer>
            </OffcanvasContents>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddPageKey;
