import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { FieldArray, Form as FormikForm, Formik } from "formik";
import { CiCirclePlus, CiCircleRemove } from "react-icons/ci";
import { schema } from "screens/setting/applicationSetting/paymentSettingForm/paymentSettingFormValidationSchema";

import {
  Form,
  Input,
  Button,
  Offcanvas,
  useDrawerContext,
  OffcanvasContents,
  OffCanvasDismissButton,
  Select,
} from "components";

import { addGamificationActivity, updateGamificationActivity } from "api";

import { useActionOnData } from "hooks";

import { useSelectedItemForEditContext } from "context";

const ruleTypeOptions = [
  {
    label: "AND",
    value: "AND",
  },
  {
    label: "OR",
    value: "OR",
  },
];
const initialRuleOptions = [
  {
    label: "LESS_THAN",
    value: "LESS_THAN",
    isDisabled: false,
  },
  {
    label: "GREATHER_THAN",
    value: "GREATER_THAN",
    isDisabled: false,
  },
  {
    label: "EQUAL",
    value: "EQUAL",
    isDisabled: false,
  },
];

const keyOptions = [
  {
    label: "Progress",
    value: "PROGRESS",
  },
  {
    label: "Enrolment",
    value: "ENROLMENT",
  },
];

const activityTypes = [
  {
    label: "Pathways",
    value: "Pathways",
  },
  {
    label: "Courses",
    value: "Courses",
  },
  {
    label: "Bundles",
    value: "Bundles",
  },
  {
    label: "Auth",
    value: "Auth",
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  activityType: Yup.string().required("Activity type is required"),

  condition: Yup.object().shape({
    key: Yup.string().required("Key is required"),
    rules: Yup.array()
      .min(1, "At least one rule is required")
      .when("key", (key, schema) =>
        schema.of(
          Yup.object().shape({
            label: Yup.string().required("Rule key is required"),
            value: Yup.number()
              .required("Rule value is required")
              .min(0, "Minimum value is 0")
              .max(
                key === "ENROLMENT" ? 1 : 100,
                `Maximum value is ${key === "ENROLMENT" ? "1" : "100"}`
              ),
          })
        )
      ),
    ruleType: Yup.string().when("rules", (rules, schema) =>
      rules?.length > 1
        ? schema.required("Rule type is required when there are multiple rules")
        : schema
    ),
  }),
});

const AddGamificationActivity = () => {
  const [ruleOptions, setRuleOptions] = useState(initialRuleOptions);
  const { selectedItemForEdit, setSelectedItemForEdit } =
    useSelectedItemForEditContext();
  const [, setIsOpen] = useDrawerContext();

  const { mutateAsync: createGamificationActivity } = useActionOnData({
    actionFunction: addGamificationActivity,
    queryToBeInvalidated: "gamificationActivities",
  });

  const { mutateAsync: modifyCourseProvider } = useActionOnData({
    actionFunction: updateGamificationActivity,
    queryToBeInvalidated: "gamificationActivities",
  });

  const handleSubmit = (data, formik) => {
    formik.setSubmitting(true);
    data.condition.rules = data.condition.rules.map((item) => ({
      key: item.label,
      value: String(item.value),
    }));

    if (selectedItemForEdit) {
      modifyCourseProvider({
        _id: selectedItemForEdit?._id,
        ...data,
        action: "UPDATE",
      })
        .then(() => formik.setSubmitting(false))
        .then(() => {
          formik.resetForm();
          setIsOpen(false);
        });
    } else {
      createGamificationActivity({
        ...data,
        action: "ADD",
      })
        .then(() => formik.setSubmitting(false))
        .then(() => {
          formik.resetForm();
          setIsOpen(false);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        name: selectedItemForEdit ? selectedItemForEdit?.name : "",
        activityType: selectedItemForEdit
          ? selectedItemForEdit?.activityType
          : "",
        condition: {
          key: selectedItemForEdit ? selectedItemForEdit?.condition?.key : "",
          rules: selectedItemForEdit
            ? selectedItemForEdit?.condition?.rules.map((item) => ({
                label: item.key,
                value: item.value,
              }))
            : [{ label: "", value: "" }],
          ruleType: selectedItemForEdit
            ? selectedItemForEdit?.condition.ruleType
            : "",
        },
      }}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { isSubmitting, isValid, dirty, values, setFieldValue } = formik;
        const {
          activityType,
          condition: { key, rules, ruleType },
        } = values;
        useEffect(() => {
          if (rules && key !== "ENROLMENT") {
            setRuleOptions((prevOptions) =>
              prevOptions?.map((item) => {
                const selectedKeys = rules.map(
                  (selectedRule) => selectedRule.label
                );

                return selectedKeys.includes(item.value)
                  ? { ...item, isDisabled: true }
                  : { ...item, isDisabled: false };
              })
            );
          }
        }, [rules, key]);

        useEffect(() => {
          if (key === "ENROLMENT") {
            setRuleOptions((prevOptions) =>
              prevOptions?.map((item) => {
                return item.value === "EQUAL"
                  ? { ...item, isDisabled: false }
                  : { ...item, isDisabled: true };
              })
            );
            setFieldValue(`condition.rules`, [
              {
                label: "EQUAL",
                value: "1",
              },
            ]);
          }
        }, [key]);

        return (
          <Form as={FormikForm}>
            <OffcanvasContents
              title={selectedItemForEdit ? "Update Activity" : "Add Activity"}
            >
              <Offcanvas.Body>
                <Input name="name" label="name" placeholder="name" />
                <Select
                  name="activityType"
                  options={activityTypes}
                  placeholder="Select activity type"
                  defaultValue={
                    activityType
                      ? activityTypes.find(
                          (item) => item.value === activityType
                        )
                      : null
                  }
                />
                <Select
                  name="condition.key"
                  options={keyOptions}
                  placeholder="Select Key"
                  defaultValue={
                    key ? keyOptions.find((item) => item.value === key) : null
                  }
                />
                <FieldArray
                  name="condition.rules"
                  render={(arrayHelpers) => (
                    <div>
                      {rules &&
                        rules.map((rule, index) => (
                          <div key={index} className="position-relative">
                            <div
                              className="d-flex gap-2"
                              key={rule.key}
                              style={{
                                marginBottom: "-10px",
                              }}
                            >
                              <Select
                                name={`condition.rules.${index}.label`}
                                options={ruleOptions}
                                placeholder="Select Rule"
                                isDisabled={key === ""}
                                defaultValue={
                                  rules
                                    ? rules.find(
                                        (item, ruleIndex) => ruleIndex === index
                                      )
                                    : null
                                }
                              />

                              <Input
                                name={`condition.rules.${index}.value`}
                                label="Enter Rule Value"
                                placeholder="Enter Rule Value"
                                disabled={key === ""}
                              />
                            </div>

                            <CiCircleRemove
                              onClick={() => arrayHelpers.remove(index)}
                              className="position-absolute text-danger"
                              style={{
                                height: "20px",
                                width: "20px",
                                top: "-13px",
                                right: "-20px",
                                cursor: "pointer",

                                display:
                                  rules.length === 1 ? "none" : "inline-block",
                              }}
                            />
                          </div>
                        ))}
                      <div
                        style={{
                          cursor: "pointer",
                          marginBottom: "20px",
                          display:
                            rules.length === 3 ||
                            key === "" ||
                            key === "ENROLMENT"
                              ? "none"
                              : "inline-block",
                        }}
                        className=" text-primary "
                        onClick={() =>
                          arrayHelpers.push({ label: "", value: 0 })
                        } // insert an empty string at a position
                      >
                        <CiCirclePlus className="me-1" />
                        add new rule
                      </div>
                    </div>
                  )}
                />

                {rules.length > 1 && (
                  <Select
                    name="condition.ruleType"
                    options={ruleTypeOptions}
                    placeholder="Select Rule Type"
                    defaultValue={
                      ruleType
                        ? ruleTypeOptions.find(
                            (item) => item.value === ruleType
                          )
                        : null
                    }
                  />
                )}
              </Offcanvas.Body>
              <Offcanvas.Footer>
                <OffCanvasDismissButton>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setSelectedItemForEdit(null)}
                  >
                    Cancel
                  </Button>
                </OffCanvasDismissButton>
                <Button
                  type="button"
                  onClick={() => handleSubmit(values, formik)}
                  className="ms-2"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  {selectedItemForEdit ? "Update  Activity" : "Create Activity"}
                </Button>
              </Offcanvas.Footer>
            </OffcanvasContents>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddGamificationActivity;
