import React, { useState } from "react";

import { AiOutlineLink } from "react-icons/ai";

import { Badge, Button } from "components";

import { linkToStripe } from "api/courseProvider";

import { useActionOnData } from "hooks";

const LinkToStripe = ({ rowData }: any) => {
  const [isClicked, setIsClicked] = useState(false);
  const { mutateAsync } = useActionOnData({
    actionFunction: linkToStripe,
    queryToBeInvalidated: "courseProviderList",
  });
  const {
    provider: { acct_domain, acct_email },
    isLinkedToStripe,
  } = rowData;
  if (isClicked) {
    return (
      <Badge className="badge badge-round d-flex align-items-center">
        Email Sent
      </Badge>
    );
  }
  return (
    <Button
      type="button"
      variant="leading-icon"
      className={`${isLinkedToStripe ? "d-none" : ""} leading-icon`}
      onClick={() =>
        mutateAsync({
          domain: acct_domain,
          email: acct_email,
        }).then((res) => {
          if (res.status === "success") {
            setIsClicked(true);
          }
        })
      }
    >
      <AiOutlineLink
        style={{
          color: "rgb(111, 117, 188)",
          fontSize: "14px",
        }}
      />
      Link To Stripe
    </Button>
  );
};

export default LinkToStripe;
