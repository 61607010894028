import { Col, Row, SecondaryHeader, SecondaryHeaderInfo } from "components";

import useAppSetting from "./useAppSetting";
import ApplicationSettingForm from "./applicationSettingForm";
import PaymentSettingForm from "./paymentSettingForm";

const ApplicationSetting = () => {
  const { data, isLoading, mutateAsync } = useAppSetting();

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryText
            SecondaryHeaderText=""
            SecondaryHeaderTitle="Application Settings"
          />
        </div>
      </SecondaryHeader>
      <Row>
        <Col xl={4}>
          <ApplicationSettingForm
            data={data}
            isLoading={isLoading}
            mutateAsync={mutateAsync}
          />
        </Col>
        <Col xl={8}>
          <PaymentSettingForm
            data={data}
            isLoading={isLoading}
            mutateAsync={mutateAsync}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ApplicationSetting;
