import React from "react";

import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";

import {
  Input,
  OffcanvasContents,
  OffCanvasDismissButton,
  Slider,
  useDrawerContext,
  Button,
  Form,
  Offcanvas,
} from "components";
import { AsyncPaginateSelect } from "components/formik";

import { getMykademyOrganizations } from "api/organization.api";
import { addCourseProvider, updateCourseProvider } from "api";

import { useActionOnData } from "hooks";

import { useSelectedItemForEditContext } from "context";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  provider: Yup.object().required("Provider is required").nullable(),
});

const AddCourseProvider = () => {
  const { selectedItemForEdit, setSelectedItemForEdit } =
    useSelectedItemForEditContext();
  const [, setIsOpen] = useDrawerContext();

  const { mutateAsync: createCourseProvider } = useActionOnData({
    actionFunction: addCourseProvider,
    navigateTo: "courseProviderList",
    queryToBeInvalidated: "courseProviderList",
  });

  const { mutateAsync: modifyCourseProvider } = useActionOnData({
    actionFunction: updateCourseProvider,
    navigateTo: "courseProviderList",
    queryToBeInvalidated: "courseProviderList",
  });

  const handleSubmit = (data, formik) => {
    formik.setSubmitting(true);

    if (selectedItemForEdit) {
      modifyCourseProvider({
        id: selectedItemForEdit?.id,
        updatedData: {
          paymentConfig: data?.paymentConfig,
          title: data?.title,
        },
      })
        .then(() => formik.setSubmitting(false))
        .then(() => {
          formik.resetForm();
          setIsOpen(false);
        });
    } else {
      createCourseProvider({
        title: data?.title,
        provider: data?.provider?.value,
        paymentConfig: data?.paymentConfig,
      })
        .then(() => formik.setSubmitting(false))
        .then(() => {
          formik.resetForm();
          setIsOpen(false);
        });
    }
  };

  const handleOrganizationLoadOptions = async (
    inputText,
    loadedOptions,
    { page }
  ) => {
    const res = await getMykademyOrganizations(page, inputText);
    return {
      options: res?.data?.data?.accounts?.map((item) => ({
        label: item.acct_name,
        value: item,
      })),
      hasMore: res?.data?.data?.accounts?.length >= 1,
      additional: {
        page: inputText ? 2 : page + 1,
      },
    };
  };

  return (
    <Formik
      initialValues={{
        title: selectedItemForEdit ? selectedItemForEdit?.title : "",
        provider: selectedItemForEdit
          ? {
              value: selectedItemForEdit?.provider,
              label: selectedItemForEdit?.provider?.acct_name,
            }
          : null,

        paymentConfig: {
          admin: selectedItemForEdit
            ? selectedItemForEdit.paymentConfig?.admin
            : 100,
          provider: selectedItemForEdit
            ? selectedItemForEdit?.paymentConfig?.provider
            : 0,
        },
      }}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { isSubmitting, isValid, dirty, values, setFieldValue } = formik;

        return (
          <Form as={FormikForm}>
            <OffcanvasContents
              title={selectedItemForEdit ? "Update Label" : "Add Label"}
            >
              <Offcanvas.Body>
                <Input name="title" label="title" placeholder="title" />
                <AsyncPaginateSelect
                  handleLoadOptions={handleOrganizationLoadOptions}
                  name="provider"
                  isDisabled={!!selectedItemForEdit}
                  key="async-paginate"
                  placeholder="Search Organization"
                />

                <div>
                  <h3>Payment Configuration</h3>
                  <div className="d-flex gap-4 w-100 align-items-center justify-content-between">
                    <Slider
                      value={values?.paymentConfig?.admin}
                      label="Admin"
                      handleChange={(value) => {
                        setFieldValue(`paymentConfig.admin`, value);
                        setFieldValue(`paymentConfig.provider`, 100 - value);
                      }}
                    />
                    <Slider
                      value={values?.paymentConfig?.provider}
                      label="Provider"
                      handleChange={(value) => {
                        setFieldValue(`paymentConfig.provider`, value);
                        setFieldValue(`paymentConfig.admin`, 100 - value);
                      }}
                    />
                  </div>
                </div>
              </Offcanvas.Body>
              <Offcanvas.Footer>
                <OffCanvasDismissButton>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setSelectedItemForEdit(null)}
                  >
                    Cancel
                  </Button>
                </OffCanvasDismissButton>
                <Button
                  type="button"
                  onClick={() => handleSubmit(values, formik)}
                  className="ms-2"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  {selectedItemForEdit
                    ? "Update Course Provider"
                    : "Create Course Provider"}
                </Button>
              </Offcanvas.Footer>
            </OffcanvasContents>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddCourseProvider;
