import React from "react";

import { Formik, Form as FormikForm, FieldArray, Field } from "formik";

import { Button, Card, Form, Input, Spinner } from "components";

import { schema } from "./paymentSettingFormValidationSchema";
import StripeIcon from "./stripeIcon";
import RazorpayIcon from "./razorpayIcon";
import PaypalIcon from "./paypalIcon";

const setDefaultPaymentSetting = (data: any) => {
  const modifiedData = { ...data };
  for (let i = 0; i < modifiedData.paymentSettings.length; i++) {
    if (modifiedData.paymentSettings[i].payment_name === modifiedData.default) {
      modifiedData.paymentSettings[i].isDefault = true;
    } else {
      modifiedData.paymentSettings[i].isDefault = false;
    }
  }
  return modifiedData;
};

const PaymentSettingForm = ({ data, isLoading, mutateAsync }: any) => {
  const handleSubmit = (values, formik) => {
    const modifiedValue = setDefaultPaymentSetting(values);

    formik.setSubmitting(true);
    mutateAsync({
      paymentSettings: modifiedValue["paymentSettings"],
      id: data?.id,
    }).then(() => formik.setSubmitting(false));
  };
  return (
    <div className="p-4">
      <Card>
        <Card.Body>
          {isLoading ? (
            <div
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <Formik
              initialValues={{
                paymentSettings: data?.paymentSettings?.map((setting) => ({
                  payment_name: setting?.payment_name,
                  isDefault: setting?.isDefault,
                  publishable_key: setting?.publishable_key,
                  secret_key: setting?.secret_key,
                  isActive: setting?.isActive,
                })),
                default: data?.paymentSettings?.find(
                  (setting) => setting.isDefault
                )?.payment_name,
              }}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={schema}
            >
              {(formik) => {
                const { isSubmitting, isValid, dirty, values, setFieldValue } =
                  formik;

                return (
                  <Form as={FormikForm} data-testid="payment-setting-form">
                    <FieldArray
                      name="paymentSettings"
                      render={() => {
                        return (
                          <>
                            {values?.paymentSettings &&
                              values?.paymentSettings?.map((item, index) => {
                                return (
                                  <div key={index} className="mb-4">
                                    {item.payment_name === "stripe" && (
                                      <StripeIcon />
                                    )}
                                    {item.payment_name === "paypal" && (
                                      <PaypalIcon />
                                    )}
                                    {item.payment_name === "razorpay" && (
                                      <RazorpayIcon />
                                    )}

                                    <Input
                                      name={`paymentSettings.${index}.publishable_key`}
                                      label="Enter publishable key"
                                      placeholder="Enter publishable key"
                                      marginBottom="10px"
                                      disabled={true}
                                    />
                                    <Input
                                      name={`paymentSettings.${index}.secret_key`}
                                      label="Enter secret key"
                                      placeholder="Enter secret key"
                                      marginBottom="10px"
                                      disabled={true}
                                    />
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        name="default"
                                        type="radio"
                                        value={item.payment_name}
                                        disabled={true}
                                        defaultChecked={item.isDefault}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "default",
                                            e.target.value
                                          )
                                        }
                                      />

                                      <label>Default</label>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        );
                      }}
                    />

                    <Button
                      variant="dark"
                      type="submit"
                      className="mt-2"
                      disabled={!(isValid && dirty) || isSubmitting}
                    >
                      {isSubmitting ? "Updating" : "Update"}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PaymentSettingForm;
