/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useMemo, useRef, useState } from "react";

import { FormikProps } from "formik";
import JoditEditor, { JoditProps } from "jodit-react";

interface Props {
  name: string;
  placeholder?: string;
  defaultValue?: string;
  formik: FormikProps<any>;
  optionList?: string[];
}

const getConfig = (props: string[]) => {
  const config: Partial<JoditProps["config"]> = {
    readonly: false,
    //@ts-ignore
    toolbarAdaptive: false,
    disablePlugins: ["inline-popup"],
    placeholder: "Enter template message",
    tabIndex: -1,
    height: "600px",
    buttons: [
      "source",
      "|",
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "image",
      "video",
      "table",
      "link",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "copyformat",
      "|",
      "fullsize",
      "print",
      "about",
    ],
    colors: ["#ff0000", "#00ff00", "#0000ff"],
    colorPickerDefaultTab: "background",
    spellcheck: true,
    editorCssClass: false,
    triggerChangeEvent: true,
    enter: "p",
    showBrowserColorPicker: true,
    iframe: false,
  };
  if (props) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    config["buttons"].push({
      name: "Email Variables",
      tooltip: "Select email variables",
      list: props,
      exec(editor: any, _: any, { control }: any) {
        const value = control.args && control.args[0];
        if (value) {
          editor.s.insertHTML(`$\{${value}}`);
        }
        editor.setEditorValue();

        return false;
      },
    });
  }
  return config;
};

export const Editor: FC<Props> = ({
  name,
  formik,
  defaultValue,
  optionList,
}) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { setFieldValue, setFieldTouched, touched, errors } = formik;
  const error = errors[name];

  const newConfig = useMemo(() => getConfig(optionList), [optionList]);

  useEffect(() => {
    if (defaultValue) {
      setContent(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (content) {
      setFieldValue(name, content);
    }
  }, [content, name, setFieldValue]);

  return (
    <div>
      <JoditEditor
        ref={editor}
        value={content}
        //@ts-ignore
        config={newConfig}
        onBlur={(newContent) => {
          setFieldTouched(name, true);
          setContent(newContent);
        }}
      />

      {touched[name] && typeof error === "string" ? (
        <small className="text-danger ">{error}</small>
      ) : null}
    </div>
  );
};
