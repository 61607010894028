import React from "react";

import { useLocation } from "react-router-dom";

import { Drawer, OffcanvasOpenButton, Button } from "components";

import { useSelectedItemForEditContext } from "context";

import Edit from "assets/images/edit.svg";

import PaymentSettingForm from "../paymentSettingForm/paymentSettingForm";

const PaymentSettingTableActions = ({ rowData }: any) => {
  const { setSelectedItemForEdit } = useSelectedItemForEditContext();
  const location = useLocation();

  return (
    <Drawer handleExit={() => setSelectedItemForEdit(null)}>
      <OffcanvasOpenButton>
        <Button
          type="button"
          variant="leading-icon"
          className="leading-icon"
          onClick={() =>
            setSelectedItemForEdit({
              ...rowData,
              domain: location?.state?.data?.domain,
            })
          }
        >
          <img src={Edit} alt="edit" />
          Edit
        </Button>
      </OffcanvasOpenButton>

      <PaymentSettingForm />
    </Drawer>
  );
};

export default PaymentSettingTableActions;
