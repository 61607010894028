import { FC, useContext, useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { useMutation, useQueryClient } from "react-query";

import { ModalContext, Select, Table, Button } from "components";

import { assignedPlanToOrganization } from "api";

import { error, success } from "utils";

const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Max",
    accessor: "max_members",
  },
  {
    Header: "Min",
    accessor: "min_members",
  },
  {
    Header: "Price",
    accessor: "price",
  },
];

interface IChangePlanProps {
  planList: any;
  org: any;
  currentPage: number;
  perPage: number;
}

const ChangePlan: FC<IChangePlanProps> = ({
  planList,
  org,
  currentPage,
  perPage,
}) => {
  const queryClient = useQueryClient();
  const [membershipDetails, setMemberShipDetails] = useState(null);

  const planOptions = planList?.data?.data?.map((p) => ({
    label: p.title,
    value: p._id,
  }));

  const [, setIsOpen] = useContext(ModalContext);
  const assignmentMutation = useMutation(assignedPlanToOrganization, {
    onSuccess: (data: any) => {
      if (data) {
        queryClient.invalidateQueries([
          "organizationList",
          currentPage,
          perPage,
        ]);
      } else {
        error("Not Updated..");
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const handleSubmit = async (data: any, formik) => {
    try {
      formik.setSubmitting(true);
      assignmentMutation.mutate(data);
      success("Plan Successfully Updated");
    } catch (err) {
      error(err.message);
    } finally {
      formik.setSubmitting(false);
      setIsOpen(false);
      formik.resetForm();
    }
  };

  useEffect(() => {
    if (planList) {
      const defaultMemberShips = planList?.data?.data?.find(
        (item) => item._id === org.plan_id._id
      );

      setMemberShipDetails(defaultMemberShips?.membershipPlans);
    }
  }, [org.plan_id._id, planList]);

  return (
    <>
      <Formik
        initialValues={{
          plan_id: org.plan_id._id,
          price_id: org.plan_id?.plan_price_id,
          subscription_id: org?.stripe_subscription_item_id,
        }}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          const { setFieldValue, isSubmitting, isValid, dirty, values } =
            formik;
          return (
            <>
              <Form>
                <Select
                  defaultValue={planOptions?.find(
                    (item) => item.value === values.plan_id
                  )}
                  name="plan_id"
                  options={planOptions}
                  onChangeCallBack={() => {
                    const foundItem = planList?.data?.data?.find(
                      (item) => item._id === values?.plan_id
                    );
                    setMemberShipDetails(foundItem?.membershipPlans);
                    setFieldValue("price_id", foundItem?.plan_price_id);
                  }}
                  placeholder="Select Plan"
                />

                <input type="hidden" name="price_id" />
                <input type="hidden" name="subscription_id" />
                <div className="mb-3 overflow-auto">
                  <Table
                    columnData={columns}
                    tableData={membershipDetails ? membershipDetails : []}
                  />
                </div>

                <Button
                  variant="secondary"
                  type="submit"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ChangePlan;
