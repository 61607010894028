import React, { Dispatch, FC, SetStateAction } from "react";

import {
  Card,
  Table,
  Button,
  CustomModal,
  ModalContents,
  ModalOpenButton,
  AskPasswordForm,
} from "components";

interface IOrganizationDetailComponentTableProps {
  data: any;
  columns: any;
  title: string;
  isLoading: boolean;
  pageCount?: number;
  noDataText: string;
  currentPage?: number;
  pagination?: boolean;
  showPaginationInsideTable?: boolean;
  showPrimaryButton?: boolean;
  showSecondaryButton?: boolean;
  handleClickPrimaryButton?: () => void;
  handleClickSecondaryButton?: () => void;
  setPerPage?: Dispatch<SetStateAction<number>>;
  setCurrentPage?: Dispatch<SetStateAction<number>>;
  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
  showResetButton?: boolean;
  actionType?: string;
}

const OrganizationDetailComponentTable: FC<
  IOrganizationDetailComponentTableProps
> = ({
  data,
  actionType,
  title,
  columns,
  isLoading,
  pageCount,
  setPerPage,
  noDataText,
  pagination,
  currentPage,
  setCurrentPage,
  showPaginationInsideTable,
  showPrimaryButton = false,
  showSecondaryButton = false,
  handleClickPrimaryButton,
  handleClickSecondaryButton,
  primaryButtonTitle,
  secondaryButtonTitle,
  showResetButton = false,
}) => {
  return (
    <Card>
      <Card.Header className="d-flex justify-content-end align-items-center">
        <strong
          style={{
            fontSize: "16px",
            flexGrow: "1",
            textAlign: "center",
          }}
        >
          {title}
        </strong>
        <div className="d-flex align-items-center justify-content-center gap-2">
          <Button
            variant="secondary"
            className={showPrimaryButton ? "d-flex" : "d-none"}
            onClick={handleClickPrimaryButton}
          >
            {primaryButtonTitle}
          </Button>
          <Button
            variant="secondary"
            className={showSecondaryButton ? "d-flex" : "d-none"}
            onClick={handleClickSecondaryButton}
          >
            {secondaryButtonTitle}
          </Button>
          {showResetButton && (
            <CustomModal className="w-80vw">
              <ModalOpenButton>
                <Button variant="secondary" type="button">
                  Reset
                </Button>
              </ModalOpenButton>
              <ModalContents title="Enter Password To Reset">
                <AskPasswordForm actionType={actionType} />
              </ModalContents>
            </CustomModal>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <Table
          tableData={data}
          isLoading={isLoading}
          pageCount={pageCount}
          columnData={columns}
          noDataText={noDataText}
          pagination={pagination}
          setPerPage={setPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          showPaginationInsideTable={showPaginationInsideTable}
        />
      </Card.Body>
    </Card>
  );
};

export default OrganizationDetailComponentTable;
