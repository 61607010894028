import React, { FC } from "react";

import { DotLoader, Card } from "components";

interface IOrganizationMainDetailProps {
  title: string;
  value: any;
  isLoading?: boolean;
}

const OrganizationMainDetailCard: FC<IOrganizationMainDetailProps> = ({
  title,
  value,
  isLoading,
}) => {
  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex flex-column align-items-center justify-content-center ">
          {isLoading ? (
            <DotLoader />
          ) : (
            <>
              <strong
                style={{
                  fontSize: "16px",
                }}
              >
                {title}
              </strong>
              <p>{value}</p>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default OrganizationMainDetailCard;
