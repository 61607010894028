import React from "react";

import { EmailSettingTableActions } from "../emailSettingTableActions";

export const emailSettingTableColumns = [
  {
    Header: "Scope",
    accessor: "scope",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Tags",
    Cell: ({ row: { index, original } }: any) => {
      return (
        <div className="d-flex gap-2" key={index}>
          {original.email_variables.map((emailVariable) => (
            <div className="badge badge-secondary" key={emailVariable}>
              {emailVariable}
            </div>
          ))}
        </div>
      );
    },
  },
  {
    Header: "Action",
    Cell: ({ row: { original } }: any) => {
      return (
        <EmailSettingTableActions rowData={original} key="emailSettingAction" />
      );
    },
  },
];
