import config from "../configs";
import { getToken } from "../utils/token";
import { errorHandler } from "./errorHandler";

export async function postThirdParty(data: { name: string }) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/thirdparty/generate-auth-creds`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function getThirdParty() {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/thirdparty/auth-creds`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function deleteThirdParty(id: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/auth-creds/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}
