import { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Col,
  Row,
  Form,
  Input,
  Button,
  CheckBox,
  ShowFilter,
  CustomModal,
  ModalContents,
  ModalOpenButton,
  SecondaryHeader,
  SecondaryHeaderInfo,
} from "components";

import { getVleVideo, saveVideoList, updateVideo } from "api";

import { VideoListContext } from "context";

import { error, success } from "utils";

import VideoForm from "./videoForm";
import VideoList from "./videoList";

const validationSchema = Yup.object().shape({
  isActive: Yup.boolean(),
  name: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

const AddVideo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isEdit] = useState(params && params.vleVideoId ? true : false);
  const queryClient = useQueryClient();
  const { state }: any = useLocation();
  const [vleVideo, setVleVideo] = useState<any>(null);
  const [isVideoListDirty, setIsVideoListDirty] = useState(false);

  const { data } = useQuery<any>(
    ["vleVideo", isEdit ? params.vleVideoId : null],
    () => getVleVideo(params.vleVideoId),
    {
      enabled: isEdit,
    }
  );

  const saveMutation = useMutation(saveVideoList, {
    onSuccess: (data) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        if (isEdit && params.vleVideoId) {
          queryClient.invalidateQueries([
            "vleVideo",
            isEdit ? params.vleVideoId : null,
          ]);
        }
        navigate("/videoList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const updateMutation = useMutation(
    (data) => updateVideo(data, params.vleVideoId),
    {
      onSuccess: (data) => {
        if (data && data.status && data.status === "success") {
          success(data.message);
          if (isEdit && params.vleVideoId) {
            queryClient.invalidateQueries([
              "vleVideo",
              isEdit ? params.vleVideoId : null,
            ]);
          }
          navigate("/videoList");
        } else {
          error(data.message);
        }
      },
      onError: () => {
        error("there was an error");
      },
    }
  );

  const [videoList, setVideoList] = useState<any>([]);
  const [video, setVideo] = useState<any>({});

  const handleSubmit = (data: any, formik) => {
    if (videoList.length > 0) {
      formik.setSubmitting(true);
      setTimeout(() => {
        data.videoList = videoList;
        if (isEdit) {
          data._id = params.vleVideoId;
          updateMutation.mutate(data);
        } else {
          saveMutation.mutate(data);
        }
        formik.setSubmitting(false);
      }, 1500);
    } else {
      error("Video list required.");
    }
  };
  //add from context
  useEffect(() => {
    if (video && video?.videoLink) {
      const array = [...videoList, video];
      setVideoList(array);
      // setUpdatePrice(true);
      setVideo({});
    }
  }, [video, videoList]);

  useEffect(() => {
    if (state && state._id) {
      setVleVideo(state);
      setVideoList(state.videoList);
    }
  }, [state]);

  useEffect(() => {
    if (data && data.data) {
      setVleVideo(data.data);
      setVideoList(data.data.vleVideoList);
    }
  }, [data, state]);

  const updateVideoData = (id: any, data: any) => {
    let index = -1;
    if (typeof id === "string") {
      index = videoList.findIndex((x) => x._id === id);
    } else {
      index = id;
    }
    if (index > -1) {
      let updateItem = videoList[index];
      updateItem = {
        ...data,
      };

      if (typeof id === "string") {
        updateItem._id = id;
      }

      setVideoList([
        ...videoList.slice(0, index),
        updateItem,
        ...videoList.slice(index + 1),
      ]);
    }
  };

  const deleteVideo = (id: any) => {
    let index = -1;
    if (typeof id === "string") {
      index = videoList.findIndex((x) => x._id === id);
    } else {
      index = id;
    }

    if (index > -1) {
      // assigning the list to temp variable
      const temp = [...videoList];

      // removing the element using splice
      temp.splice(index, 1);

      setVideoList(temp);
    }
  };
  return (
    <div className="content-wrapper">
      <Formik
        initialValues={{
          name: vleVideo && vleVideo.name ? vleVideo.name : "",
          description:
            vleVideo && vleVideo.description ? vleVideo.description : "",
          isActive: vleVideo && vleVideo.isActive ? vleVideo.isActive : false,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {(formik) => {
          const { values, dirty, isValid, isSubmitting } = formik;

          return (
            <Form as={FormikForm}>
              <SecondaryHeader>
                <div className="secondary-header-wrap">
                  <BsFillArrowLeftCircleFill
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  />
                  <SecondaryHeaderInfo
                    SecondaryHeaderTitle={isEdit ? "Update Video" : "Add Video"}
                    SecondaryHeaderText=""
                    SecondaryText
                  />
                </div>

                <div className="button-wrap">
                  <Button
                    variant="secondary"
                    type="submit"
                    disabled={
                      !(isValid && (dirty || isVideoListDirty)) || isSubmitting
                    }
                  >
                    {isEdit ? "Update Video" : "Create Video"}
                  </Button>
                </div>
              </SecondaryHeader>
              <div className="filter-block invisible">
                <ShowFilter triggerTitle="Show Filters" />
              </div>
              <Row className="m-4 px-0 py-2">
                <Col xl={4}>
                  <Input label="name" placeholder="name" name="name" />
                  <Input
                    label="description"
                    placeholder="description"
                    name="description"
                  />

                  <CheckBox
                    label="active"
                    name="isActive"
                    defaultChecked={values?.isActive}
                  />
                </Col>
                <Col xl={8}>
                  <VideoListContext.Provider
                    value={[video, setVideo, setIsVideoListDirty]}
                  >
                    <div className="card-header">
                      <CustomModal>
                        <ModalOpenButton>
                          <Button variant="secondary" type="button">
                            Add Video
                          </Button>
                        </ModalOpenButton>
                        <ModalContents title="Add Video">
                          <VideoForm />
                        </ModalContents>
                      </CustomModal>
                    </div>

                    <VideoList
                      videoList={videoList}
                      updateVideo={updateVideoData}
                      deleteVideo={deleteVideo}
                    />
                  </VideoListContext.Provider>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddVideo;
