import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";

import {
  Form,
  Input,
  Button,
  Offcanvas,
  useDrawerContext,
  OffcanvasContents,
  OffCanvasDismissButton,
} from "components";

import { postThirdParty } from "api";

import { useActionOnData } from "hooks";

import { useSelectedItemForEditContext } from "context";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

const AddThirdParty = () => {
  const { selectedItemForEdit, setSelectedItemForEdit } =
    useSelectedItemForEditContext();
  const [, setIsOpen] = useDrawerContext();
  const { mutateAsync } = useActionOnData({
    actionFunction: postThirdParty,
    queryToBeInvalidated: "thirdPartyList",
  });
  const handleSubmit = async (data, formik) => {
    mutateAsync(data).then(() => {
      formik.resetForm();
      setIsOpen(false);
    });
  };

  return (
    <Formik
      initialValues={{
        name: "",
      }}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { isSubmitting, isValid, dirty, values } = formik;

        return (
          <Form as={FormikForm} data-testid="thirdparty-form">
            <OffcanvasContents
              title={selectedItemForEdit ? "Update Label" : "Add Label"}
            >
              <Offcanvas.Body>
                <Input name="name" label="name" placeholder="name" />
              </Offcanvas.Body>
              <Offcanvas.Footer>
                <OffCanvasDismissButton>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setSelectedItemForEdit(null)}
                  >
                    Cancel
                  </Button>
                </OffCanvasDismissButton>
                <Button
                  type="button"
                  onClick={() => handleSubmit(values, formik)}
                  className="ms-2"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  Create third party
                </Button>
              </Offcanvas.Footer>
            </OffcanvasContents>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddThirdParty;
