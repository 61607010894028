import React, { FC } from "react";

import { Field } from "formik";

interface SwitchButtonProps {
  label: string;
  name: string;
}

const SwitchButton: FC<SwitchButtonProps> = (props) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <label className="switch-button">
        <Field type="checkbox" name={props.name} />
        <span className="slider-switch-button round"></span>
      </label>
      <div>{props.label}</div>
    </div>
  );
};

export default SwitchButton;
