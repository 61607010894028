import { useQuery } from "react-query";

import { getEmailSettings } from "api";

import { error } from "utils";

const useGetEmailSettings = () => {
  const { data, isLoading, isError } = useQuery(
    ["emailSettings"],
    getEmailSettings,
    {
      select: (data) =>
        data?.data?.map((item) => ({
          id: item._id,
          scope: item.scope,
          description: item.description,
          email_variables: item.email_variables,
          content: item.default_template.content,
          subject: item.default_template.subject,
        })),
    }
  );

  if (isError) {
    error("Oops !!there is an error while fetching email settings");
  }
  return {
    data,
    isLoading,
  };
};

export default useGetEmailSettings;
