import React, { useContext, useState } from "react";

import { useMutation, useQueryClient } from "react-query";

import { ModalContext, ModalDismissButton, Button, Modal } from "components";

import { deleteKey } from "api";

import { error, success } from "utils";

const KeyTableDeleteAction = ({ data }) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setIsOpen] = useContext(ModalContext);

  const deleteMutation = useMutation(deleteKey, {
    onSuccess: (data) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("keyList");
        queryClient.removeQueries("keyList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  return (
    <>
      <Modal.Body>
        Click delete button to delete or cancel button to cancel action.
      </Modal.Body>
      <Modal.Footer>
        <ModalDismissButton>
          <Button variant="outline" type="button">
            Cancel
          </Button>
        </ModalDismissButton>

        <Button
          onClick={() => {
            setIsSubmitting(true);
            deleteMutation.mutateAsync(data?._id).then(() => {
              setIsOpen(false);
              setIsSubmitting(false);
            });
          }}
          disabled={isSubmitting}
          type="submit"
          variant="orange"
        >
          {isSubmitting ? "Deleting..." : "Delete"}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default KeyTableDeleteAction;
