import {
  Table,
  Drawer,
  Button,
  SecondaryHeader,
  OffcanvasOpenButton,
  SecondaryHeaderInfo,
  SecondaryHeaderSearchField,
} from "components";

import { useSelectedItemForEditContext } from "context";

// import CourseProviderFilter from "../courseProviderFilter";
// import useGetCourseProviderList from "../useGetCourseProviderList";
import { gamificationActivityTableColumns } from "./gamificationActivityTableColumns";
import AddPathwayActivity from "./addGamificationActivity";
import useGetGamificationActivities from "./useGetGamificationActivities";

const GamificationActivities = () => {
  const { data, isLoading } = useGetGamificationActivities();

  const { setSelectedItemForEdit } = useSelectedItemForEditContext();

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryText
            SecondaryHeaderText={`${data?.length} gamification activities`}
            SecondaryHeaderTitle="Gamification Activities"
          />
        </div>

        <div className="button-wrap ">
          <Drawer handleExit={() => setSelectedItemForEdit(null)}>
            <OffcanvasOpenButton>
              <Button type="button" variant="secondary">
                Create
              </Button>
            </OffcanvasOpenButton>

            <AddPathwayActivity />
          </Drawer>
        </div>
      </SecondaryHeader>

      <div className="content-block px-4 py-2">
        <Table
          tableData={data ? data : []}
          isLoading={isLoading}
          columnData={gamificationActivityTableColumns}
        />
      </div>
    </div>
  );
};

export default GamificationActivities;
