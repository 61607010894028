import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";

import { Button, Card, Form } from "components";

import { modifyOrganizationSetting } from "api";

import { useActionOnData } from "hooks";

import { error } from "utils";

import FormGroup from "./FormGroup";
import { fieldData } from "./fieldData";

const OrganizationSettingForm = () => {
  const [initialValues, setInitialValues] = useState({
    showPowerBi: "",
    showSsoButton: "",
    showLoginPage: "",
    showContentPro: "",
    showPaymentSetting: "",
    showCommunityForum: "",
    showGetStartedPage: "",
    showVideoClassRoom: "",
    showUpcomingWidget: "",
    showClientMarketPlace: "",
    canUnEnrollPaidCourse: "",
    showLearnerMarketPlace: "",
    // showSSoRoleUpdateButton: "",
    showGamificationSetting: "",
    showTutorApprovalProcess: "",
    showPendingUserInvitation: "",
  });
  const { state }: any = useLocation();
  const { mutateAsync } = useActionOnData({
    actionFunction: modifyOrganizationSetting,
    queryToBeInvalidated: "getSingleOrg",
    navigateTo: `organization/${state?.domain}`,
  });

  const handleSubmit = (values) => {
    try {
      if (state) {
        mutateAsync({
          domain: state?.domain,
          show_login_page:
            values?.showLoginPage === undefined
              ? state?.show_login_page
              : values?.showLoginPage,
          show_sso_button: values?.showSsoButton,
          show_content_pro: values?.showContentPro,
          show_get_started: values?.showGetStartedPage,
          show_video_classroom: values?.showVideoClassRoom,
          show_community_forum: values?.showCommunityForum,
          show_payment_setting: values?.showPaymentSetting,
          enable_gamification: values?.showGamificationSetting,
          show_client_marketplace: values?.showClientMarketPlace,
          show_upcoming_event_widget: values?.showUpcomingWidget,
          can_unenroll_paid_course: values?.canUnEnrollPaidCourse,
          show_learner_marketplace: values?.showLearnerMarketPlace,
          show_tutor_approval_process: values?.showTutorApprovalProcess,
          show_pending_user_invitation: values?.showPendingUserInvitation,

          show_powerbi:
            values?.showPowerBi === undefined
              ? state?.show_powerbi
              : values?.showPowerBi,
          // show_sso_update_button:
          //   values?.showSSoRoleUpdateButton === undefined
          //     ? state?.show_sso_update_button
          //     : values?.showSSoRoleUpdateButton,
        });
      }
    } catch (err) {
      error(err.message);
    }
  };

  useEffect(() => {
    if (state) {
      setInitialValues({
        showPowerBi: state?.show_powerbi,
        showSsoButton: state?.show_sso_button,
        showLoginPage: state?.show_login_page,
        showContentPro: state?.show_content_pro,
        showGetStartedPage: state?.show_get_started,
        showPaymentSetting: state?.show_payment_setting,
        showCommunityForum: state?.show_community_forum,
        showVideoClassRoom: state?.show_video_classroom,
        showGamificationSetting: state?.enable_gamification,
        showClientMarketPlace: state?.show_client_marketplace,
        showUpcomingWidget: state?.show_upcoming_event_widget,
        canUnEnrollPaidCourse: state?.can_unenroll_paid_course,
        // showSSoRoleUpdateButton: state?.show_sso_update_button,
        showLearnerMarketPlace: state?.show_learner_marketplace,
        showTutorApprovalProcess: state?.show_tutor_approval_process,
        showPendingUserInvitation: state?.show_pending_user_invitation,
      });
    }
  }, [state]);

  return (
    <Card>
      <Card.Header
        style={{
          fontWeight: "bold",
          fontSize: "0.9rem",
        }}
      >
        Enable/Disable organization setting
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            const { isSubmitting, isValid, dirty } = formik;

            return (
              <Form as={FormikForm}>
                {state?.tenant_id ? (
                  <>
                    <FormGroup
                      label="Enable Login Page"
                      fieldName="showLoginPage"
                    />
                    {/* <FormGroup
                      label="Enable SSO role update button"
                      fieldName="showSSoRoleUpdateButton"
                    /> */}
                    <FormGroup
                      label="Enable Power Bi"
                      fieldName="showPowerBi"
                    />
                  </>
                ) : null}

                {fieldData?.map(({ id, label, fieldName }) => (
                  <FormGroup key={id} label={label} fieldName={fieldName} />
                ))}

                <Button
                  variant="dark"
                  type="submit"
                  className="mt-2"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  {isSubmitting ? "Updating" : "Update"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default OrganizationSettingForm;
