import configs from "configs";
import config from "configs";

import { getToken } from "utils";

import { errorHandler } from "./errorHandler";

export async function getPurchaseHistory(
  page = 0,
  perPage = 10,
  search = "",
  filters: {
    payment_status: "";
    payment_method: "";
    datePicked: ["", ""];
  }
) {
  const token: string = getToken();

  let url = `/purchase-history?page=${page}&perPage=${perPage}`;
  if (search) {
    url = url + `&search=${search}`;
  }
  if (filters["payment_status"]) {
    url = url + `&payment_status=${filters["payment_status"]}`;
  }
  if (filters["payment_method"]) {
    url = url + `&payment_method=${filters["payment_method"]}`;
  }

  if (filters["payment_method"]) {
    url = url + `&payment_method=${filters["payment_method"]}`;
  }
  if (filters["datePicked"][0]) {
    url = url + `&fromDate=${filters["datePicked"][0]}`;
  }
  if (filters["datePicked"][1]) {
    url = url + `&toDate=${filters["datePicked"][1]}`;
  }
  const response = await fetch(configs.mainApiEndpoint + url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}
