import { Card, SwitchButton } from "components";

const OrganizationGamificationPathway = () => {
  return (
    <Card>
      <Card.Header
        style={{
          fontWeight: "bold",
          fontSize: "1rem",
        }}
      >
        <SwitchButton name="isPathwayEnabled" label="Allow Learning Pathway" />
      </Card.Header>
      <Card.Body></Card.Body>
    </Card>
  );
};

export default OrganizationGamificationPathway;
