import { useEffect, useState } from "react";

import * as Yup from "yup";
import { useQuery } from "react-query";
import { Formik, Form as FormikForm } from "formik";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Col,
  Row,
  Form,
  Input,
  Editor,
  Select,
  Button,
  ShowFilter,
  SecondaryHeader,
  SecondaryHeaderInfo,
} from "components";

import {
  editEmailSettings,
  getAvailabeScopes,
  getEmailVariables,
  createEmailSetting,
} from "api";

import { useActionOnData } from "hooks";

import { error } from "utils";

const validationSchema = Yup.object().shape({
  scope: Yup.string().required("Scope Name is Required!"),
  description: Yup.string().required("Description is Required!"),
  email_variables: Yup.array()
    .of(Yup.string().required("Tag Name is Required!"))
    .min(1, "Email variables shouldn't be empty"),
  subject: Yup.string().required("Subject is required!"),
  content: Yup.string().required("Content is required!"),
});

const AddScope = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    scope: "",
    subject: "",
    content: "",
    description: "",
    email_variables: [],
  });
  const { state }: any = useLocation();
  const params = useParams();

  const { data } = useQuery(["scopes"], getAvailabeScopes, {
    select: (data) =>
      data?.data?.map((item) => ({
        label: item,
        value: item,
      })),
  });
  const { data: emailVariables } = useQuery(
    ["emailVariables"],
    getEmailVariables,
    {
      select: (data) =>
        data?.data?.map((item) => ({
          label: item,
          value: item,
        })),
    }
  );

  const { mutate: updateEmailSettings } = useActionOnData({
    actionFunction: editEmailSettings,
    navigateTo: "settings/email",
    queryToBeInvalidated: "emailSettings",
  });
  const { mutate: addEmailSetting } = useActionOnData({
    actionFunction: createEmailSetting,
    navigateTo: "settings/email",
    queryToBeInvalidated: "emailSettings",
  });

  const handleSubmit = (values) => {
    try {
      if (state) {
        updateEmailSettings({
          id: params?.emailSettingId,
          email_variables: values.email_variables,
          description: values.description,
          default_template: {
            subject: values.subject,
            content: values.content,
          },
        });
      } else {
        addEmailSetting({
          email_variables: values.email_variables,
          default_template: {
            subject: values.subject,
            content: values.content,
          },
          scope: values.scope,
          description: values.description,
        });
      }
    } catch (err) {
      error(err.message);
    }
  };

  useEffect(() => {
    if (state) {
      setInitialValues({
        scope: state.scope,
        subject: state.subject,
        content: state.content,
        description: state.description,
        email_variables: state.email_variables,
      });
    }
  }, [state]);

  return (
    <>
      <div className="content-wrapper">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {(formik) => {
            const { isSubmitting, isValid, dirty, values } = formik;

            return (
              <Form as={FormikForm}>
                <SecondaryHeader>
                  <div className="secondary-header-wrap">
                    <BsFillArrowLeftCircleFill
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(-1)}
                    />
                    <SecondaryHeaderInfo
                      SecondaryHeaderTitle="Add Scope"
                      SecondaryHeaderText=""
                      SecondaryText
                    />
                  </div>

                  <div className="button-wrap">
                    <Button
                      variant="secondary"
                      type="submit"
                      disabled={!(isValid && dirty) || isSubmitting}
                    >
                      {state
                        ? isSubmitting
                          ? "Updating"
                          : "Update"
                        : isSubmitting
                        ? "Saving"
                        : "Save"}
                    </Button>
                  </div>
                </SecondaryHeader>
                <div className="filter-block invisible">
                  <ShowFilter triggerTitle="Show Filters" />
                </div>
                <Row className=" m-4 px-0 py-2">
                  <Col xl={6}>
                    <Select
                      key="scope"
                      isDisabled={!!state}
                      name="scope"
                      options={data}
                      placeholder="Select Scope"
                      defaultValue={
                        values?.scope
                          ? {
                              label: values?.scope,
                              value: values?.scope,
                            }
                          : null
                      }
                      isMulti={false}
                      isClearable={false}
                    />

                    <Input
                      name="description"
                      label="description"
                      placeholder="description"
                    />
                    <Select
                      name="email_variables"
                      key="email_variables"
                      placeholder="Select Email Variables"
                      options={emailVariables}
                      defaultValue={values?.email_variables?.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      isClearable={true}
                      isMulti={true}
                    />
                  </Col>
                  <Col xl={6}>
                    <Input
                      name="subject"
                      placeholder="subject"
                      label="subject"
                    />
                    <Editor
                      formik={formik}
                      name="content"
                      defaultValue={values?.content}
                      placeholder="Enter Content"
                    />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddScope;
