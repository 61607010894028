import PaymentSettingTableActions from "../paymentSettingTableActions";

//predefined columns for table
export const paymentSettingTableColumns = [
  {
    Header: "Payment Method",
    accessor: "name",
  },
  {
    Header: "Account Id",
    accessor: "account_id",
    Cell: ({ row: { original } }: any) => {
      return (
        <div
          className="d-inline-block text-truncate"
          style={{
            width: "250px",
          }}
        >
          {original?.account_id ?? ""}
        </div>
      );
    },
  },
  {
    Header: "Publishable Key",
    accessor: "publishableKey",
    Cell: ({ row: { original } }: any) => {
      return (
        <div
          className="d-inline-block text-truncate"
          style={{
            width: "250px",
          }}
        >
          {original?.publishableKey}
        </div>
      );
    },
  },
  {
    Header: "Secret Key",
    accessor: "secretKey",
    Cell: ({ row: { original } }: any) => {
      return (
        <div
          className="d-inline-block text-truncate"
          style={{
            width: "250px",
          }}
        >
          {original?.secretKey}
        </div>
      );
    },
  },
  {
    Header: "Default",
    accessor: "isDefault",
    Cell: ({ row: { original } }: any) => {
      return <span>{original?.isDefault ? "yes" : "no"}</span>;
    },
  },
  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      return <PaymentSettingTableActions rowData={original} />;
    },
  },
];
