import { useNavigate } from "react-router-dom";

import { Button } from "components";

import Info from "assets/images/info.svg";

export const purchaseHistoryTableColumns = [
  {
    Header: "Email",
    Cell: ({ row: { original } }: any) => {
      const { userDetails } = original;
      return <div>{userDetails?.email}</div>;
    },
  },
  {
    Header: "Domain",
    Cell: ({ row: { original } }: any) => {
      const { userDetails } = original;
      return <div>{userDetails?.domain}</div>;
    },
  },
  {
    Header: "Role",
    Cell: ({ row: { original } }: any) => {
      const { userDetails } = original;
      return <div>{userDetails?.role_name}</div>;
    },
  },

  {
    Header: "Currency",
    Cell: ({ row: { original } }: any) => {
      const { currency } = original;
      return <div>{currency}</div>;
    },
  },
  {
    Header: "Type",
    Cell: ({ row: { original } }: any) => {
      const { type } = original;
      return <div>{type}</div>;
    },
  },
  {
    Header: "Total Items",
    accessor: "totalCourses",
  },
  {
    Header: "Payment Status",
    accessor: "paymentStatus",
  },
  {
    Header: "Payment Method",
    accessor: "paymentMethod",
  },
  {
    Header: "Created Date",
    accessor: "createdAt",
  },
  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      const navigate = useNavigate();
      return (
        <Button
          variant="leading-icon"
          className="leading-icon"
          onClick={() =>
            navigate(`/purchaseHistory/${original.id}`, {
              state: {
                data: original,
              },
            })
          }
        >
          <img src={Info} alt="view detail" />
          Details
        </Button>
      );
    },
  },
];
