import React, { FC, useContext, useState } from "react";

import * as Yup from "yup";
import { Formik, Form as FormikForm } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Input, ModalContext, Form, Button } from "components";

import {
  removeSSO,
  resetOrganizationEmailSetting,
  modifyOrganizationEmailSetting,
  resetAsset,
  deleteLogs,
  deleteGamificationActivity,
} from "api";
import { resetAllLabelPage, resetSingleLabelPage } from "api/labelPage.api";

import { useActionOnData } from "hooks";

import { error, success } from "utils/toast";

import Hide from "assets/images/hide.svg";
import Show from "assets/images/show.svg";

export type AskPasswordForm = {
  password: string;
};

interface IAskPasswordProps {
  subject?: string;
  content?: string;
  actionType: string;
  organizationId?: string;
  templateId?: string;
  data?: any;
}

export const AskPasswordFormSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
});

const AskPassword: FC<IAskPasswordProps> = ({
  content,
  subject,
  actionType,
  organizationId,
  templateId,
  data,
}) => {
  const navigateTo = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const queryClient = useQueryClient();
  const params = useParams();
  const { state } = useLocation();
  const [, setIsOpen] = useContext(ModalContext);

  const deactivateGamificationActivity = useActionOnData({
    actionFunction: deleteGamificationActivity,
    queryToBeInvalidated: "gamificationActivities",
  });

  const modifyTemplate = useMutation(
    (updateData: any) =>
      modifyOrganizationEmailSetting(organizationId, templateId, updateData),
    {
      onSuccess: (data: any) => {
        if (data && data.status && data.status === "success") {
          success(data.message);
          queryClient.invalidateQueries("organization-email-settings");
          navigateTo(-1);
        } else {
          error(data.message);
        }
      },
      onError: () => {
        error("there was an error");
      },
    }
  );

  const removeSSOConfig = useMutation(
    (updateData: any) => removeSSO(organizationId, updateData),
    {
      onSuccess: (data: any) => {
        if (data && data.status && data.status === "success") {
          success(data.message);
          queryClient.invalidateQueries("organizationList");
          navigateTo(-1);
        } else {
          error(data.message);
        }
      },
      onError: () => {
        error("there was an error");
      },
    }
  );

  const removeAllLabelPages = useMutation(resetAllLabelPage, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("orgLabelPageList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const removeSingleLabelPage = useMutation(resetSingleLabelPage, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("orgLabelList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const resetTemplate = useMutation(
    (updateData: any) =>
      resetOrganizationEmailSetting(organizationId, templateId, updateData),
    {
      onSuccess: (data: any) => {
        if (data && data.status && data.status === "success") {
          success(data.message);
          queryClient.invalidateQueries("organization-email-settings");
          navigateTo(-1);
        } else {
          error(data.message);
        }
      },
      onError: () => {
        error("there was an error");
      },
    }
  );

  const resetAssetData = useMutation(resetAsset, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("orgVideoList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const deleteAllLogs = useMutation(deleteLogs, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("logList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const buttonLabel = () => {
    let label = "";
    if (actionType === "modify") {
      label = "submit";
    } else if (actionType === "reset") {
      label = "reset";
    } else if (actionType === "removeSSO") {
      label = "remove";
    } else if (actionType === "resetSingleLabelPage") {
      label = "reset";
    } else if (actionType === "resetAllLabelPages") {
      label = "reset";
    } else if (actionType === "resetAsset") {
      label = "reset";
    } else if (actionType === "deleteLogs") {
      label = "delete";
    } else if (actionType === "deleteGamificationActivity") {
      label = "delete";
    }
    return label;
  };
  const handleSubmit = (values, formik) => {
    try {
      formik.setSubmitting(true);
      if (actionType === "modify") {
        modifyTemplate
          .mutateAsync({
            subject,
            content,
            password: values.password,
          })
          .then(() => formik.setSubmitting(false));
      } else if (actionType === "reset") {
        formik.setSubmitting(false);
        resetTemplate
          .mutateAsync(values)
          .then(() => formik.setSubmitting(false));
      } else if (actionType === "removeSSO") {
        removeSSOConfig
          .mutateAsync(values)
          .then(() => formik.setSubmitting(false));
      } else if (actionType === "resetSingleLabelPage") {
        removeSingleLabelPage
          .mutateAsync({
            password: values?.password,
            pageId: params?.labelPageId,
          })
          .then(() => formik.setSubmitting(false));
      } else if (actionType === "resetAllLabelPages") {
        removeAllLabelPages
          .mutateAsync({
            password: values?.password,
            domain: state?.data?.domain,
          })
          .then(() => formik.setSubmitting(false));
      } else if (actionType === "resetAsset") {
        resetAssetData
          .mutateAsync({
            password: values?.password,
            domain: state?.data?.domain,
          })
          .then(() => formik.setSubmitting(false));
      } else if (actionType === "deleteLogs") {
        deleteAllLogs
          .mutateAsync({
            password: values?.password,
          })
          .then(() => formik.setSubmitting(false));
      } else if (actionType === "deleteGamificationActivity") {
        deactivateGamificationActivity
          .mutateAsync({
            password: values?.password,
            action: "DELETE",
            _id: data?.id,
          })
          .then(() => formik.setSubmitting(false));
      }
      setTimeout(() => {
        formik.setSubmitting(false);
        setIsOpen(false);
      }, 1500);
    } catch (err) {
      error(err);
      formik.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={AskPasswordFormSchema}
    >
      {({ isSubmitting, dirty, isValid }) => {
        return (
          <Form className="login-form" as={FormikForm}>
            <div className="position-relative">
              <Input
                label="password"
                name="password"
                placeholder="password"
                type={showPassword ? "text" : "password"}
              />
              <span
                style={{
                  position: "absolute",
                  top: "14px",
                  right: "10px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={showPassword ? Show : Hide}
                  alt="eye"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              </span>
            </div>

            <Button
              variant="secondary"
              className="w-100 button-style text-capitalize"
              type="submit"
              disabled={!(isValid && dirty) || isSubmitting}
            >
              {buttonLabel()}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AskPassword;
