import { useQuery } from "react-query";

import { getGamificationActivities } from "api";

const useGetGamificationActivities = () => {
  const { data, isLoading, isSuccess } = useQuery(
    ["gamificationActivities"],
    getGamificationActivities,
    {
      select(data) {
        return data?.data;
      },
    }
  );

  return {
    data,

    isLoading,
    isSuccess,
  };
};

export default useGetGamificationActivities;
