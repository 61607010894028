import React from "react";

import { Drawer, OffcanvasOpenButton, Button } from "components";

import { useSelectedItemForEditContext } from "context";

import Edit from "assets/images/edit.svg";

import AddLabel from "../addLabel/addLabel";

const LabelPageTableEditAction = ({ rowData }) => {
  const { setSelectedItemForEdit } = useSelectedItemForEditContext();
  return (
    <Drawer handleExit={() => setSelectedItemForEdit(null)}>
      <OffcanvasOpenButton>
        <Button
          type="button"
          variant="leading-icon"
          className="leading-icon"
          onClick={() => setSelectedItemForEdit(rowData)}
        >
          <img src={Edit} alt="edit" />
          Edit
        </Button>
      </OffcanvasOpenButton>

      <AddLabel />
    </Drawer>
  );
};

export default LabelPageTableEditAction;
