import { Badge } from "components";

import CourseProviderTableActions from "../courseProviderTableActions";

export const courseProviderTableColumns = [
  {
    Header: "Name",
    accessor: "title",
  },

  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row: { original } }: any) => {
      const { isActive } = original;
      return (
        <div>
          {isActive ? (
            <Badge className="badge badge-round">active</Badge>
          ) : (
            <Badge className="badge dark badge-round">inactive</Badge>
          )}
        </div>
      );
    },
  },
  {
    Header: "Email Status",
    accessor: "isMailSent",
    Cell: ({ row: { original } }: any) => {
      const { isMailSent } = original;
      return (
        <div>
          {isMailSent ? (
            <Badge className="badge badge-round">sent</Badge>
          ) : (
            <Badge className="badge dark badge-round">not sent</Badge>
          )}
        </div>
      );
    },
  },
  {
    Header: "Linked To Stripe",
    accessor: "isLinkedToStripe",
    Cell: ({ row: { original } }: any) => {
      const { isLinkedToStripe } = original;

      return <span>{isLinkedToStripe ? "Yes" : "No"}</span>;
    },
  },
  {
    Header: "Default",
    accessor: "default",
    Cell: ({ row: { original } }: any) => {
      const { isDefault } = original;
      return <div>{isDefault ? "Yes" : "No"}</div>;
    },
  },
  {
    Header: "Actions",
    width: 300,
    Cell: ({ row: { original } }: any) => {
      return (
        <CourseProviderTableActions
          rowData={original}
          key="courseProviderActions"
        />
      );
    },
  },
];
