import {
  Table,
  Button,
  CustomModal,
  ModalContents,
  ModalOpenButton,
  AskPasswordForm,
  SecondaryHeader,
  SecondaryHeaderInfo,
  SecondaryHeaderSearchField,
} from "components";

import LogFilter from "../logFilter";
import useGetLogList from "../useGetLogList";
import { logTableColumns } from "../logTableColumns";

const LogList = () => {
  const {
    data,
    search,
    isLoading,
    setFilters,
    setPerPage,
    currentPage,
    selectedOrg,
    onClearSearch,
    onSearchChange,
    setCurrentPage,
    setSelectedOrg,
    selectedOperation,
    setSelectedOperation,
  } = useGetLogList();

  const params = new URLSearchParams(window.location?.search);

  const clear = params.get("clear");

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle="Logs"
            SecondaryHeaderText={`${data?.total} logs`}
            SecondaryText
          />
        </div>
        <SecondaryHeaderSearchField
          placeholder="Search Log..."
          value={search}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={onSearchChange}
          onClear={onClearSearch}
        />
        <div className="button-wrap">
          {clear && clear === "true" && data?.data?.length > 0 && (
            <CustomModal className="w-80vw">
              <ModalOpenButton>
                <Button variant="secondary" type="button">
                  Clear Logs
                </Button>
              </ModalOpenButton>
              <ModalContents title="Enter Password To Reset">
                <AskPasswordForm actionType="deleteLogs" />
              </ModalContents>
            </CustomModal>
          )}
        </div>
      </SecondaryHeader>
      <div className="filter-block">
        <LogFilter
          isLoading={isLoading}
          selectedOperation={selectedOperation}
          selectedOrg={selectedOrg}
          setFilters={setFilters}
          setSelectedOperation={setSelectedOperation}
          setSelectedOrg={setSelectedOrg}
          key="log-filter"
        />
      </div>
      <div className="content-block px-4 py-2">
        <Table
          tableData={data ? data?.data : []}
          pageCount={data?.total_pages}
          isLoading={isLoading}
          columnData={logTableColumns}
          setPerPage={setPerPage}
          pagination={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default LogList;
