import { roles } from "screens/organization/addOrgSection/addOrgSection";

import { Badge } from "components";

import VideoTableActions from "./orgAssetTableActions";

export const orgAssetTableColumns = [
  {
    Header: "Name",
    accessor: "name",

    Cell: ({ row: { original } }: any) => {
      return (
        <div
          className="d-inline-block text-truncate"
          style={{
            width: "250px",
          }}
        >
          {original?.name}
        </div>
      );
    },
  },
  {
    Header: "Section",
    accessor: "section",

    Cell: ({ row: { original } }: any) => {
      return (
        <div
          className="d-inline-block text-truncate"
          style={{
            width: "250px",
          }}
        >
          {original?.section}
        </div>
      );
    },
  },
  {
    Header: "Role",
    Cell: ({ row: { index, original } }) => {
      return (
        <div
          className="d-flex gap-2 flex-wrap"
          key={index}
          style={{
            width: "250px",
          }}
        >
          {original?.role?.map((item) => (
            <Badge key={item} className="badge dark">
              {roles?.find((role) => role.value === item)?.label}
            </Badge>
          ))}
        </div>
      );
    },
  },
  {
    Header: "No. Of Assets",
    Cell: ({ row: { original } }: any) => {
      return <span>{original && original?.assets?.length}</span>;
    },
  },
  {
    Header: "Actions",
    Cell: ({ row: { original } }: any) => {
      return <VideoTableActions rowData={original} key="videoTableActions" />;
    },
  },
];
