import { Badge } from "components";

import LabelPageTableActions from "../labelPageTableActions";

//predefined columns for table
export const labelPageTableColumns = [
  {
    Header: "Name",
    accessor: "labelPage",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row: { original } }: any) => {
      const { isActive } = original;
      return (
        <div>
          {isActive ? (
            <Badge className="badge badge-round">active</Badge>
          ) : (
            <Badge className="badge dark badge-round">inactive</Badge>
          )}
        </div>
      );
    },
  },
  {
    Header: "Created Date",
    Cell: ({ row: { original } }) => {
      return <span>{new Date(original?.createdAt).toDateString()}</span>;
    },
  },
  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      return <LabelPageTableActions rowData={original} />;
    },
  },
];
