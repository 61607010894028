import React from "react";

import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import {
  Select,
  Button,
  Col,
  Form,
  Row,
  SecondaryHeader,
  SecondaryHeaderInfo,
} from "components";

import { getDefaultVideoList, saveOrgVideo } from "api";

import { error, success } from "utils";

const addValidationSchema = Yup.object().shape({
  section: Yup.object().shape({
    isActive: Yup.boolean(),
    name: Yup.string().required("Required"),
    description: Yup.string(),
    role: Yup.array().min(1).nullable(),
    assets: Yup.array()
      .of(
        Yup.object().shape({
          isActive: Yup.boolean(),
          description: Yup.string(),
          title: Yup.string().required("Required"),
          link: Yup.string().required("Required"),
          type: Yup.string().required("Required"),
          role: Yup.array().min(1).nullable(),
        })
      )
      .required("Required"),
  }),
});

const AddOrgSection = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const params = useParams();

  const { data } = useQuery<any>(["defaultVideoList"], getDefaultVideoList, {
    select(data) {
      return data?.data?.map((item) => ({
        label: item.name,
        value: {
          name: item.name,
          description: item.description,
          domain: params?.domain,
          isActive: item.isActive,
          assets: item?.vleVideoList?.map((video) => ({
            title: video.title,
            description: video.description,
            type: "video",
            isActive: video.isActive,
            link: video.videoLink,
            role: video?.type,
          })),
          role: ["all"],
        },
      }));
    },
  });

  const saveMutation = useMutation(saveOrgVideo, {
    onSuccess: (data) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("orgVideoList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const handleSubmit = (values, formik) => {
    saveMutation
      .mutateAsync({
        ...values.section,
      })
      .then(() => formik.setSubmitting(false));
  };

  return (
    <>
      <div className="content-wrapper">
        <Formik
          initialValues={{
            section: null,
          }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={addValidationSchema}
        >
          {(formik) => {
            const { isSubmitting, isValid, dirty, values } = formik;

            return (
              <Form as={FormikForm}>
                <SecondaryHeader>
                  <div className="secondary-header-wrap">
                    <BsFillArrowLeftCircleFill
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(-1)}
                    />
                    <SecondaryHeaderInfo
                      SecondaryHeaderTitle={
                        state ? "Update Media" : "Add Media"
                      }
                      SecondaryHeaderText=""
                      SecondaryText
                    />
                  </div>

                  <div className="button-wrap">
                    <Button
                      variant="secondary"
                      type="submit"
                      disabled={!(isValid && dirty) || isSubmitting}
                    >
                      {!state ? "Create Media" : "Update Media"}
                    </Button>
                  </div>
                </SecondaryHeader>

                <Row className="mx-4 my-4">
                  <Col xl={4}>
                    <Select
                      name="section"
                      options={data}
                      placeholder="Select Section"
                      defaultValue={
                        values?.section
                          ? {
                              label: values?.section?.name,
                              value: values?.section,
                            }
                          : null
                      }
                    />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddOrgSection;
