import { ReactNode, useState } from "react";

import { Button, Offcanvas } from "components";

function ShowFilterOffCanvas({
  triggerTitle,
  children,
  handleApply,
  isLoading,
}: {
  triggerTitle: string;
  children?: ReactNode;
  handleApply?: () => string;
  isLoading?: boolean;
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const offCanvasKeyDown = (e) => e.stopPropagation();

  return (
    <>
      <div onClick={handleShow} className="filter-icon">
        <svg
          width="21"
          height="13"
          viewBox="0 0 21 13"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.076 1.5H.354M16.532 6.5H3.897M13.09 11.5H7.35"
            stroke="#1B1C21"
            strokeWidth="2"
          ></path>
        </svg>
        <span>{triggerTitle}</span>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header closeButton backButton>
          <Offcanvas.Title>
            Apply Filter
            <p className="help-text">Select the filter options</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{children ? children : null}</Offcanvas.Body>
        <Offcanvas.Footer>
          <Button variant="default" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              const res = handleApply();
              if (res === "success") {
                setTimeout(handleClose, 1000);
              }
            }}
            disabled={isLoading}
          >
            Apply Filter
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default ShowFilterOffCanvas;
