import React from "react";

import { useParams } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  Form,
  Input,
  Select,
  Button,
  Offcanvas,
  useDrawerContext,
  OffcanvasContents,
  OffCanvasDismissButton,
} from "components";

import { addLabel, getAllKeyList, updateLabel } from "api";

import { useSelectedItemForEditContext } from "context";

import { error, success } from "utils";

import { validationSchema } from "./validationSchema";

const AddLabel = () => {
  const { selectedItemForEdit, setSelectedItemForEdit } =
    useSelectedItemForEditContext();
  const params = useParams();

  const [, setIsOpen] = useDrawerContext();

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery("allKeyList", getAllKeyList, {
    select(data) {
      return data?.data?.map((item: any) => ({
        label: item?.keyName,
        value: item?.keyName,
      }));
    },
  });

  const addMutation = useMutation(addLabel, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("labelList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const updateMutation = useMutation(updateLabel, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("labelList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const handleSubmit = (values, formik) => {
    if (selectedItemForEdit) {
      updateMutation
        .mutateAsync({
          labelPageId: params?.labelPageId,
          labelId: selectedItemForEdit?._id,
          ...values,
        })
        .then(() => {
          formik.setSubmitting(false);
          formik.resetForm();
        })
        .then(() => setIsOpen(false));
    } else {
      addMutation
        .mutateAsync({
          labelPageId: params?.labelPageId,
          ...values,
        })
        .then(() => {
          formik.setSubmitting(false);
          formik.resetForm();
        })
        .then(() => setIsOpen(false));
    }
  };
  return (
    <Formik
      initialValues={{
        key: selectedItemForEdit ? selectedItemForEdit?.key : "",
        label: selectedItemForEdit ? selectedItemForEdit?.label : "",
        placeholder: selectedItemForEdit
          ? selectedItemForEdit?.placeholder
          : "",
      }}
      enableReinitialize={true}
      onSubmit={() => console.log("clicked")}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { isSubmitting, isValid, dirty, values } = formik;

        return (
          <Form as={FormikForm}>
            <OffcanvasContents
              title={selectedItemForEdit ? "Update Label" : "Add Label"}
            >
              <Offcanvas.Body>
                <Select
                  key="key"
                  name="key"
                  isDisabled={isLoading}
                  options={data}
                  placeholder="Select Key"
                  defaultValue={
                    values?.key
                      ? {
                          label: values?.key,
                          value: values?.key,
                        }
                      : null
                  }
                  isMulti={false}
                  isClearable={false}
                />

                <Input
                  name="label"
                  label="Enter label"
                  placeholder="Enter label"
                />
                <Input
                  name="placeholder"
                  label="Enter placeholder"
                  placeholder="Enter placeholder"
                />
              </Offcanvas.Body>
              <Offcanvas.Footer>
                <OffCanvasDismissButton>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setSelectedItemForEdit(null)}
                  >
                    Cancel
                  </Button>
                </OffCanvasDismissButton>
                <Button
                  type="button"
                  onClick={() => handleSubmit(values, formik)}
                  className="ms-2"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  {selectedItemForEdit ? "Update" : "Submit"}
                </Button>
              </Offcanvas.Footer>
            </OffcanvasContents>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddLabel;
