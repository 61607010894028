import { FC, ReactNode, useState } from "react";

import { NavBar } from "./navBar";
import { SideBar } from "./sideBar";

interface ILayoutProps {
  children: ReactNode | ReactNode[];
}

export interface INavBarAndSideBarProps {
  menuActive?: boolean;
  handleHamburgerClick: (e: any) => void;
}

const Layout: FC<ILayoutProps> = ({ children }) => {
  const [menuActive, setMenuActive] = useState(false);
  const handleHamburgerClick = () => {
    const body = document.body;
    if (body.classList.contains("menu-active")) {
      body.classList.remove("menu-active");
      setMenuActive(false);
    } else {
      body.classList.add("menu-active");
      setMenuActive(true);
    }
  };
  return (
    <>
      <NavBar
        menuActive={menuActive}
        key="navbar"
        handleHamburgerClick={handleHamburgerClick}
      />
      <main className="main-wrapper">
        <SideBar
          menuActive={menuActive}
          key="navbar"
          handleHamburgerClick={handleHamburgerClick}
        />
        {children}
      </main>
    </>
  );
};

export default Layout;
