import { useEffect, useState } from "react";

import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Form as FormikForm, Formik } from "formik";
import { useMutation } from "react-query";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import {
  Col,
  Row,
  Form,
  Input,
  Badge,
  Editor,
  Button,
  CustomModal,
  ModalContents,
  AskPasswordForm,
  ModalOpenButton,
  SecondaryHeader,
  SecondaryHeaderInfo,
} from "components";
import { AsyncPaginateSelect } from "components/formik";

import { filterOrganization, resetEmailSettingToOtherOrganization } from "api";

import { error, success } from "utils";

const validationSchema = Yup.object().shape({
  subject: Yup.string().required("Subject is required!"),
  content: Yup.string().required("Content is required!"),
});

export const EditOrganizationEmailSetting = () => {
  const [initialValues, setInitialValues] = useState({
    subject: "",
    content: "",
  });
  const { state }: any = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      setInitialValues({
        subject: state.subject,
        content: state.content,
      });
    }
  }, [state]);

  const resetMutation = useMutation(resetEmailSettingToOtherOrganization, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const handleOrganizationLoadOptions = async (
    inputText,
    loadedOptions,
    { page }
  ) => {
    const res = await filterOrganization(page, inputText);

    return {
      options: res?.data?.map((item) => ({
        label: item.domain,
        value: item.domain,
      })),
      hasMore: res?.data?.length >= 1,
      additional: {
        page: inputText ? 2 : page + 1,
      },
    };
  };

  const handleReset = (values, formik) => {
    resetMutation
      .mutateAsync({
        resetFromDomain: values?.organization?.value,
        domain: state?.domain,
        superAdminEmailTemplateId: state?.super_admin_setting_id,
      })
      .then(() => formik.setSubmitting(false))
      .then(() => navigate(-1));
  };

  return (
    <>
      <div className="content-wrapper">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => console.log("values", values)}
          validationSchema={validationSchema}
        >
          {(formik) => {
            const { values, isValid, dirty } = formik;

            return (
              <Form as={FormikForm}>
                <SecondaryHeader>
                  <div className="secondary-header-wrap">
                    <BsFillArrowLeftCircleFill
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(-1)}
                    />
                    <SecondaryHeaderInfo
                      SecondaryHeaderTitle="Edit Email Template"
                      SecondaryHeaderText=""
                      SecondaryText
                    />
                  </div>

                  <div className="button-wrap">
                    <CustomModal className="w-80vw">
                      <ModalOpenButton>
                        <Button variant="secondary" type="button">
                          Reset To
                        </Button>
                      </ModalOpenButton>
                      <ModalContents title="Search Organization and Click Submit">
                        <Formik
                          initialValues={{
                            organization: null,
                          }}
                          enableReinitialize={true}
                          onSubmit={() => console.log("ok")}
                          validationSchema={{
                            provider: Yup.object()
                              .required("Provider is required")
                              .nullable(),
                          }}
                        >
                          {(formik) => {
                            return (
                              <>
                                <AsyncPaginateSelect
                                  handleLoadOptions={
                                    handleOrganizationLoadOptions
                                  }
                                  name="organization"
                                  key="async-paginate"
                                  placeholder="Search Organization"
                                />
                                <Button
                                  variant="secondary"
                                  type="button"
                                  onClick={() =>
                                    handleReset(formik.values, formik)
                                  }
                                  disabled={
                                    !(formik.isValid && formik.dirty) ||
                                    formik.isSubmitting
                                  }
                                >
                                  Submit
                                </Button>
                              </>
                            );
                          }}
                        </Formik>
                      </ModalContents>
                    </CustomModal>
                    <CustomModal className="w-80vw">
                      <ModalOpenButton>
                        <Button variant="secondary" type="button">
                          Reset
                        </Button>
                      </ModalOpenButton>
                      <ModalContents title="Enter Password To Reset">
                        <AskPasswordForm
                          subject={values.subject}
                          content={values.content}
                          actionType="reset"
                          organizationId={state.orgId}
                          templateId={state.id}
                        />
                      </ModalContents>
                    </CustomModal>
                    <CustomModal className="w-80vw">
                      <ModalOpenButton>
                        <Button
                          variant="secondary"
                          type="button"
                          disabled={!(isValid && dirty)}
                        >
                          Update
                        </Button>
                      </ModalOpenButton>
                      <ModalContents title="Enter password to update">
                        <AskPasswordForm
                          subject={values.subject}
                          content={values.content}
                          actionType="modify"
                          organizationId={state.orgId}
                          templateId={state.id}
                        />
                      </ModalContents>
                    </CustomModal>
                  </div>
                </SecondaryHeader>

                <Row className=" m-4 px-0 py-2">
                  <Col lg={8} className="mb-4">
                    <Input
                      name="subject"
                      placeholder="subject"
                      label="subject"
                    />
                    <Editor
                      formik={formik}
                      name="content"
                      defaultValue={values?.content}
                      placeholder="Enter Content"
                      optionList={state && state.email_variables}
                    />
                  </Col>
                  <Col lg={4} css={{ width: "100%" }}>
                    <h1>Instructions</h1>
                    <p>
                      System variabes are used by system to generate dynamic
                      values. We support following system variables. Use
                      dropdrown in editor for using variables.
                    </p>
                    <ul
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "start",
                        flexWrap: "wrap",
                        alignItems: "center",
                        paddingLeft: 0,
                      }}
                    >
                      {state &&
                        state.email_variables.map((item: string) => (
                          <Badge bg="primary" key={item} pill>
                            {item}
                          </Badge>
                        ))}
                    </ul>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
