import React, { useContext, useState } from "react";

import { Button, Modal } from "components";
import {
  CustomModal,
  ModalContext,
  ModalContents,
  ModalOpenButton,
  ModalDismissButton,
} from "components";

import { deleteCourseProvider } from "api";

import { useActionOnData } from "hooks";

import Delete from "assets/images/delete.svg";

const ModalContent = ({ rowData }: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setIsOpen] = useContext(ModalContext);

  const { mutateAsync } = useActionOnData({
    actionFunction: deleteCourseProvider,
    queryToBeInvalidated: "courseProviderList",
  });

  return (
    <>
      <Modal.Body>
        Click delete button to delete or cancel button to cancel delete action.
      </Modal.Body>
      <Modal.Footer>
        <ModalDismissButton>
          <Button variant="outline" type="button">
            Cancel
          </Button>
        </ModalDismissButton>

        <Button
          onClick={() => {
            setIsSubmitting(true);
            mutateAsync(rowData?.id).then(() => {
              setIsOpen(false);
              setIsSubmitting(false);
            });
          }}
          disabled={isSubmitting}
          type="submit"
          variant="orange"
        >
          {isSubmitting ? "Deleting..." : "Delete"}
        </Button>
      </Modal.Footer>
    </>
  );
};

const CourseProviderTableDeleteAction = ({ rowData }: any) => {
  const { isDefault } = rowData;
  const params = new URLSearchParams(window.location?.search);

  const clear = params.get("clear");
  return (
    <CustomModal centered={false} backdrop={false}>
      <ModalOpenButton>
        <Button
          type="button"
          className={`${
            !isDefault && clear && clear === "true" ? "" : "d-none"
          } leading-icon`}
          variant="leading-icon"
          style={{
            marginRight: "10px",
          }}
        >
          <img src={Delete} alt="delete" />
          Delete
        </Button>
      </ModalOpenButton>
      <ModalContents title="Do you want to delete this provider?">
        <ModalContent rowData={rowData} />
      </ModalContents>
    </CustomModal>
  );
};

export default CourseProviderTableDeleteAction;
