import {
  cloneElement,
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";

import { Offcanvas } from "components";

const OffcanvasContext = createContext<
  [isOpen: boolean, setIsOpen: (state: boolean) => null, handleExit: () => null]
>([false, () => null, () => null]);

const callAll =
  (...fns: any) =>
  (...args: any) =>
    fns.forEach((fn: any) => fn && fn(...args));

function Drawer(props: any) {
  const [isOpen, setIsopen] = useState(false);
  const { handleExit } = props;

  return (
    <OffcanvasContext.Provider
      value={[isOpen, setIsopen, handleExit]}
      {...props}
    />
  );
}

function OffCanvasDismissButton({ children }: { children: any }) {
  const [, setIsOpen] = useContext(OffcanvasContext);

  return cloneElement(children, {
    onClick: callAll(() => setIsOpen(false), children.props.onClick),
  });
}

function OffcanvasOpenButton({ children }: { children: any }) {
  const [, setIsOpen] = useContext(OffcanvasContext);

  return cloneElement(children, {
    onClick: callAll(() => setIsOpen(true), children.props.onClick),
  });
}

function SubmitButton({ children }: { children: any }) {
  const [, setIsOpen] = useContext(OffcanvasContext);

  return cloneElement(children, {
    onClick: callAll(children.props.onClick, () => setIsOpen(false)),
  });
}
function useDrawerContext() {
  const context = useContext(OffcanvasContext);

  if (context === undefined) {
    throw new Error(`useModalContext must be used withing a Modal component`);
  }

  return context;
}
const OffcanvasBase = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen, handleExit] = useContext(OffcanvasContext);
  return (
    <Offcanvas
      show={isOpen}
      onHide={() => setIsOpen(false)}
      placement="end"
      onExited={handleExit}
    >
      {children}
    </Offcanvas>
  );
};
function OffcanvasContents({
  title,
  children,

  ...props
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <OffcanvasBase {...props}>
      <Offcanvas.Header closeButton backButton>
        <Offcanvas.Title>
          {title}
          <p className="help-text">Enter field values</p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      {children}
    </OffcanvasBase>
  );
}

export {
  Drawer,
  SubmitButton,
  useDrawerContext,
  OffcanvasContext,
  OffcanvasContents,
  OffcanvasOpenButton,
  OffCanvasDismissButton,
};
