import config from "configs";

import { getToken } from "utils";

import { errorHandler } from "./errorHandler";

export async function getGamificationActivities() {
  const token: string = getToken();
  const url = `/setting/gamification/activities`;

  const response = await fetch(config.mainApiEndpoint + url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function addGamificationActivity(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + "/setting/gamification/activities",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function deleteGamificationActivity(data) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/setting/gamification/activities`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function updateGamificationActivity(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/setting/gamification/activities`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function configureGamification(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/configure-gamification`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
}
