import React from "react";

import OrgAssetDeleteAction from "./orgAssetDeleteAction";
import OrgAssetEditAction from "./orgAssetEditAction";
import OrgAssetViewDetails from "./orgAssetViewDetails";

const OrgAssetTableActions = ({ rowData }: any) => {
  const { is_default } = rowData;
  return (
    <div className="d-flex align-items-center gap-3">
      <OrgAssetViewDetails rowData={rowData} key="assets" />
      <OrgAssetEditAction rowData={rowData} key="videoTableEditAction" />

      {is_default === 1 ? null : (
        <OrgAssetDeleteAction rowData={rowData} key="videoTableDeleteAction" />
      )}
    </div>
  );
};

export default OrgAssetTableActions;
