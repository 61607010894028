import React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "components";

import Info from "assets/images/info.svg";

const OrgAssetViewDetails = ({ rowData }: any) => {
  const { _id } = rowData;
  const navigate = useNavigate();

  return (
    <Button
      variant="leading-icon"
      className="leading-icon"
      type="button"
      onClick={() =>
        navigate(`/organization/section/assetList/${_id}`, {
          state: {
            role: rowData?.role,
          },
        })
      }
    >
      <img src={Info} alt="edit" />
      View Details
    </Button>
  );
};

export default OrgAssetViewDetails;
