import { useQuery } from "react-query";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Table,
  Drawer,
  Button,
  SecondaryHeader,
  OffcanvasOpenButton,
  SecondaryHeaderInfo,
} from "components";

import { getPageKeyList } from "api";

import { useSelectedItemForEditContext } from "context";

import { pageKeyTableColumns } from "./pageKeyTableColumns";
import AddPageKey from "./addPageKey";

const PageKeyList = () => {
  const params = useParams();
  const { state } = useLocation();

  const navigate = useNavigate();

  const { setSelectedItemForEdit } = useSelectedItemForEditContext();

  const { isLoading, data } = useQuery(
    ["pageKeyList", { params }],
    () => getPageKeyList(params?.labelPageId),
    {
      select: (data) => {
        return data?.data;
      },
    }
  );

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <BsFillArrowLeftCircleFill
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />

          <SecondaryHeaderInfo
            SecondaryHeaderTitle={state}
            SecondaryHeaderText={`${data?.length} data`}
            SecondaryText
          />
        </div>

        <div className="button-wrap">
          <Drawer handleExit={() => setSelectedItemForEdit(null)}>
            <OffcanvasOpenButton>
              <Button type="button" variant="primary">
                Add Page Key
              </Button>
            </OffcanvasOpenButton>

            <AddPageKey />
          </Drawer>
        </div>
      </SecondaryHeader>

      <div className="content-block px-4 py-2">
        <Table
          columnData={pageKeyTableColumns}
          tableData={data ? data : []}
          pageCount={data?.length}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default PageKeyList;
