import React, { useState } from "react";

import * as Yup from "yup";
import { useMutation } from "react-query";
import Form from "react-myoneui/lib/Form";
import Button from "react-myoneui/lib/Button";
import { Form as FormikForm, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { Input } from "components";

import { login } from "api";

import { error, success } from "utils";

import Hide from "assets/images/hide.svg";
import Show from "assets/images/show.svg";
import oliveLogo from "assets/images/logo.svg";

export type LoginForm = {
  email: string;
  password: string;
};

export const loginFormSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
});

function Login() {
  const navigateTo = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const redirectPath = location.state?.path || "/";

  const loginMutation = useMutation(login, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success" && data.data.token) {
        success(data.message);
        localStorage.setItem("token", data.data.token);
        navigateTo(redirectPath, { replace: true });
      } else {
        error(data?.message);
      }
    },
  });

  const handleSubmit = (values: LoginForm, formik) => {
    loginMutation
      .mutateAsync(values)
      .finally(() => formik.setSubmitting(false));
  };

  return (
    <div className="login-wrapper">
      <div className="container">
        <img src={oliveLogo} className="login-logo" alt="logo" />
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={loginFormSchema}
        >
          {({ isSubmitting, dirty, isValid }) => {
            return (
              <Form
                className="login-form"
                as={FormikForm}
                data-testid="login-form"
              >
                <Input label="Email Id" name="email" placeholder="Email Id" />
                <div className="position-relative">
                  <Input
                    label="password"
                    name="password"
                    placeholder="password"
                    type={showPassword ? "text" : "password"}
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: "14px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={showPassword ? Show : Hide}
                      alt="eye"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  </span>
                </div>

                <Button
                  variant="secondary"
                  className="w-100 button-style"
                  type="submit"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  {isSubmitting ? "Logging..." : "Login"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
