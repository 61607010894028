import KeyTableActions from "../keyTableActions";

//predefined columns for table
export const keyTableColumns = [
  {
    Header: "Name",
    accessor: "keyName",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Created Date",
    Cell: ({ row: { original } }) => {
      return <span>{new Date(original?.createdAt).toDateString()}</span>;
    },
  },
  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      return <KeyTableActions rowData={original} />;
    },
  },
];
