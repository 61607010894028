import config from "../configs";
import { getToken } from "../utils/token";
import { errorHandler } from "./errorHandler";

/**
 * This function is used to get app loader status
 * @returns - appSetting
 */
export async function getAppLoaderStatus() {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/app-setting/loader-status`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

/**
 * This function is used to get client market place status
 * @returns - appSetting
 */
export async function getAppClientMarketPlaceStatus() {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/app-setting/client-marketplace-status`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

/**
 * This function updates app setting
 * @param dataId - Id of setting which is to be edited
 * @param bodyData - Data which is to be updated
 * @returns - appSetting
 */
export async function modifyAppSettings(data: any) {
  const token: string = getToken();
  const { id, ...rest } = data;
  const response = await fetch(config.mainApiEndpoint + `/app-setting/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(rest),
  });
  errorHandler(response);
  return response.json();
}

export async function getAppSettings() {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/app-setting`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}
