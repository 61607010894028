import React, { FC } from "react";

import {
  Button,
  CustomModal,
  ModalContents,
  ModalOpenButton,
} from "components";

import Delete from "assets/images/delete.svg";

import LabelTableEditAction from "./labelTableEditAction";
import LabelTableDeleteAction from "./labelTableDeleteAction";

interface IPlanTableActionsProps {
  rowData: any;
}

const LabelPageTableActions: FC<IPlanTableActionsProps> = ({ rowData }) => {
  return (
    <div className="d-flex align-items-center gap-3">
      <LabelTableEditAction rowData={rowData} key="labelTableEditAction" />

      <CustomModal centered={false} backdrop={false}>
        <ModalOpenButton>
          <Button type="button" className="leading-icon" variant="leading-icon">
            <img src={Delete} alt="delete" />
            Delete
          </Button>
        </ModalOpenButton>
        <ModalContents title="Are you sure? You want to delete this label?">
          <LabelTableDeleteAction data={rowData} />
        </ModalContents>
      </CustomModal>
    </div>
  );
};

export default LabelPageTableActions;
