import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import {
  Table,
  Drawer,
  Button,
  SecondaryHeader,
  OffcanvasOpenButton,
  SecondaryHeaderInfo,
} from "components";

import { getOrgVideo } from "api";

import { useSelectedItemForEditContext } from "context";

import { assetTableColumns } from "./assetTableColumns";
import AssetForm from "./sectionForm";

const LabelPageList = () => {
  const params = useParams();

  const navigate = useNavigate();

  const { setSelectedItemForEdit } = useSelectedItemForEditContext();

  const { data, isLoading } = useQuery<any>(
    ["orgSection", params.sectionId],
    () => getOrgVideo(params.sectionId),
    {
      enabled: !!params.sectionId,
      select(data) {
        return data?.data;
      },
    }
  );

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <BsFillArrowLeftCircleFill
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />

          <SecondaryHeaderInfo
            SecondaryHeaderTitle={data?.name}
            SecondaryHeaderText={`${data?.assets?.length} data`}
            SecondaryText
          />
        </div>

        <div className="button-wrap">
          <Drawer
            handleExit={() => {
              setSelectedItemForEdit(null);
            }}
          >
            <OffcanvasOpenButton>
              <Button type="button" variant="primary">
                Add Media
              </Button>
            </OffcanvasOpenButton>

            <AssetForm />
          </Drawer>
        </div>
      </SecondaryHeader>

      <div className="content-block px-4 py-2">
        <Table
          columnData={assetTableColumns}
          tableData={data ? data?.assets : []}
          pageCount={data?.assets?.length}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default LabelPageList;
