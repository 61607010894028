import React, { useContext, useState } from "react";

import { useMutation, useQueryClient } from "react-query";

import { Button, Modal } from "components";
import {
  CustomModal,
  ModalContext,
  ModalContents,
  ModalOpenButton,
  ModalDismissButton,
} from "components";

import { modifyCourseProviderStatus } from "api";

import { error, success } from "utils";

import ActivateIcon from "assets/images/activate.svg";
import DeactivateIcon from "assets/images/deactivate.svg";

const ModalContent = ({ rowData }: any) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setIsOpen] = useContext(ModalContext);

  const modifyMutation = useMutation(modifyCourseProviderStatus, {
    onSuccess: (data) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("courseProviderList");
      } else {
        error(data.message);
      }
    },
    onError: (err: any) => {
      error("there was an error");
    },
  });
  return (
    <>
      <Modal.Body>
        {rowData?.isActive
          ? "Click deactive button to deactivate or cancel button to cancel deactivation."
          : "Click active button to activate or cancel button to cancel activation."}
      </Modal.Body>
      <Modal.Footer>
        <ModalDismissButton>
          <Button variant="outline" type="button">
            Cancel
          </Button>
        </ModalDismissButton>

        <Button
          onClick={() => {
            setIsSubmitting(true);
            modifyMutation
              .mutateAsync({
                id: rowData?.id,
                updatedData: {
                  isActive: !rowData?.isActive,
                },
              })
              .then(() => {
                setIsOpen(false);
                setIsSubmitting(false);
              });
          }}
          disabled={isSubmitting}
          type="submit"
          variant={`${rowData?.isActive ? "orange" : "secondary"}`}
        >
          {rowData?.isActive
            ? isSubmitting
              ? "Deactivating..."
              : "Deactivate"
            : isSubmitting
            ? "Activating..."
            : "Activate"}
        </Button>
      </Modal.Footer>
    </>
  );
};

const CourseProviderTableDeactivateAction = ({ rowData }: any) => {
  const { isDefault, isActive } = rowData;
  return (
    <CustomModal centered={false} backdrop={false}>
      <ModalOpenButton>
        <Button
          type="button"
          className={`${isDefault ? "d-none" : ""} leading-icon`}
          variant="leading-icon"
          style={{
            marginRight: "10px",
          }}
        >
          <img
            src={isActive ? DeactivateIcon : ActivateIcon}
            alt="activate or deactivate"
          />
          {isActive ? "Deactivate" : "Activate"}
        </Button>
      </ModalOpenButton>
      <ModalContents
        title={`Do you want to ${
          isActive ? "deactivate" : "activate"
        } this provider?`}
      >
        <ModalContent rowData={rowData} />
      </ModalContents>
    </CustomModal>
  );
};

export default CourseProviderTableDeactivateAction;
