import React from "react";

import { useLocation } from "react-router-dom";

import { Card, Col, Row } from "components";

import Profile from "assets/images/profile.svg";

export const PendingUserInvitationDetail = () => {
  const { state }: any = useLocation();

  return (
    <div className="content-wrapper">
      <div className="p-4">
        <Row>
          <Col xl={6}>
            <Card>
              <Card.Header className="d-flex justify-content-center">
                <strong
                  style={{
                    fontSize: "16px",
                  }}
                >
                  User Details
                </strong>
              </Card.Header>
              <Card.Body>
                <div className="mb-3">
                  <img
                    src={state?.image ? state?.image : Profile}
                    alt="profile"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div>
                  <strong>Learner Id</strong>
                  <p>{state?.learner_id}</p>
                </div>
                <div>
                  <strong>Learner Name</strong>
                  <p>{state?.name}</p>
                </div>
                <div>
                  <strong>Learner Email</strong>
                  <p>{state?.email}</p>
                </div>
                <div>
                  <strong>Country</strong>
                  <p>{state?.country}</p>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
