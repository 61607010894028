import React, { useContext, useState } from "react";

import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { ModalContext, ModalDismissButton, Button, Modal } from "components";

import { deleteAsset } from "api";

import { error, success } from "utils";

const AssetTableDeleteAction = ({ data }) => {
  const params = useParams();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setIsOpen] = useContext(ModalContext);

  const deleteMutation = useMutation(deleteAsset, {
    onSuccess: (data) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("orgSection");
        queryClient.removeQueries("orgSection");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  return (
    <>
      <Modal.Body>
        Click delete button to delete or cancel button to cancel action.
      </Modal.Body>
      <Modal.Footer>
        <ModalDismissButton>
          <Button variant="outline" type="button">
            Cancel
          </Button>
        </ModalDismissButton>

        <Button
          onClick={() => {
            setIsSubmitting(true);
            deleteMutation
              .mutateAsync({
                sectionId: params?.sectionId,
                assetId: data?._id,
              })
              .then(() => {
                setIsOpen(false);
                setIsSubmitting(false);
              });
          }}
          disabled={isSubmitting}
          type="submit"
          variant="orange"
        >
          {isSubmitting ? "Deleting..." : "Delete"}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default AssetTableDeleteAction;
