import React from "react";

import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";

import { SecondaryHeader, SecondaryHeaderInfo } from "components";

import UpdateSupportEmailForm from "../updateSupportEmailForm";
import OrganizationSettingForm from "../organizationSettingForm";
import SsoRoleSettingForm from "../ssoRoleSettingForm/ssoRoleSettingForm";

export const Columns = {
  default: 2,
  1200: 2,
  1000: 2,
  700: 1,
};

const OrganizationSetting = () => {
  const { state }: any = useLocation();

  const navigate = useNavigate();
  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <BsFillArrowLeftCircleFill
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <SecondaryHeaderInfo
            SecondaryHeaderTitle="Change organization setting"
            SecondaryHeaderText=""
            SecondaryText
          />
        </div>
      </SecondaryHeader>
      <div className="p-3">
        <Masonry
          breakpointCols={Columns}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <OrganizationSettingForm />
          {state?.tenant_id && (
            <SsoRoleSettingForm
              tenantId={state?.tenant_id}
              domain={state?.domain}
            />
          )}
          <UpdateSupportEmailForm />
        </Masonry>
      </div>
    </div>
  );
};

export default OrganizationSetting;
