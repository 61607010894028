import { FC } from "react";

import oliveLogo from "assets/images/logo.svg";
import Cross from "assets/images/cross.svg";
import Hamburger from "assets/images/hamburger.svg";

import NavUserProfileDropDown from "./profileDropDown";
import { INavBarAndSideBarProps } from "./layout";

export const NavBar: FC<INavBarAndSideBarProps> = ({
  menuActive,
  handleHamburgerClick,
}) => {
  return (
    <>
      <nav className="navbar navbar-expand-md sticky-top backdrop-filter">
        <div className="container-fluid">
          <div className="logo-toggle-wrapper d-flex align-items-center gap-4">
            <div
              className="mobile-menu d-flex d-md-none gap-2"
              onClick={handleHamburgerClick}
            >
              <span className="sidebar-toggle">
                <img
                  src={menuActive ? Cross : Hamburger}
                  alt="hamburger-cross"
                />
              </span>
            </div>
            <img
              src={oliveLogo}
              style={{ width: "80px", height: "50px" }}
              alt="Logo"
            />
          </div>
          <NavUserProfileDropDown />
        </div>
      </nav>
    </>
  );
};
