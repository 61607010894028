import React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "components";

import Edit from "assets/images/edit.svg";

const OrgAssetEditAction = ({ rowData }: any) => {
  const { _id } = rowData;
  const navigate = useNavigate();

  return (
    <Button
      variant="leading-icon"
      className="leading-icon"
      type="button"
      onClick={() =>
        navigate(`/organization/editSection/${_id}`, { state: rowData })
      }
    >
      <img src={Edit} alt="edit" />
      Edit
    </Button>
  );
};

export default OrgAssetEditAction;
