import { useState } from "react";

import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  Table,
  Button,
  SecondaryHeader,
  SecondaryHeaderInfo,
} from "components";

import { getLabelPageList } from "api/labelPage.api";

import { labelPageTableColumns } from "./labelPageTableColumns";

const LabelPageList = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, data } = useQuery(
    ["labelPageList", currentPage, perPage],
    () => getLabelPageList(currentPage, perPage),
    {
      select(data) {
        return {
          list: data?.data?.datas,
          totalCount: data?.data?.totalCounts,
          totalPages: Math.ceil(data?.data?.totalCounts / perPage),
        };
      },
    }
  );
  console.log("data", data);

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle="Label Page"
            SecondaryHeaderText={`${data?.totalCount} data`}
            SecondaryText
          />
        </div>

        <div className="button-wrap">
          <Button variant="secondary" onClick={() => navigate("/addLabelPage")}>
            Create
          </Button>
        </div>
      </SecondaryHeader>

      <div className="content-block px-4 py-2">
        <Table
          columnData={labelPageTableColumns}
          tableData={data ? data?.list : []}
          pageCount={data?.totalPages}
          isLoading={isLoading}
          setPerPage={setPerPage}
          pagination={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default LabelPageList;
