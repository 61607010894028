import { FC } from "react";

import { useField } from "formik";
import ReactSelect, { components, StylesConfig } from "react-select";

export const getCustomStyles = ({
  error,
  touched,
}: {
  error?: any;
  touched?: any;
}) => {
  const customStyles: StylesConfig = {
    control: (base, state) => ({
      ...base,
      height: "auto",
      borderRadius: "8px",
      boxShadow: error && touched ? "1px solid #f46582" : "1px solid #7175AC",
      border:
        state.isFocused || state.hasValue
          ? error && touched
            ? "2px solid #f46582"
            : "2px solid #7175AC"
          : error && touched
          ? "1px solid #f46582"
          : "1px solid #cdd3de",

      "&:hover": {
        border: error && touched ? "2px solid #f46582" : "2px solid #7175AC",
      },
      outline: "none",
    }),
    placeholder: (base) => ({
      ...base,
      display: "none",
    }),
    option: (styles, { isDisabled }) => ({
      ":active": {
        ...styles[":active"],
      },
      ":hover": {
        background: isDisabled ? "" : "#EBECF4",
      },
      padding: "10px",
      margin: "10px",
      borderRadius: "5px",
      cursor: "pointer",
      color: isDisabled ? "#C6C6C6" : "",
    }),
    valueContainer: (base) => ({
      ...base,
      width: "100%",
      display: "flex",
      marginTop: "18px",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      position: "absolute",
      right: 0,
      top: "15%",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "transform 0.2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
    }),
    clearIndicator: (base) => ({
      ...base,
      cursor: "pointer",
    }),
  };
  return customStyles;
};

export const Control = (props: any) => {
  return (
    <>
      <label
        style={{
          position: "absolute",
          color: "#13171f",
          opacity: 0.7,
          zIndex: "999",
          pointerEvents: "none",
          transition: "0.2s ease all",
          left: "12px",
          top: props.isFocused || props.hasValue ? "5px" : "35%",
          fontSize: props.isFocused || props.hasValue ? "0.6rem" : "0.75rem",
        }}
      >
        {props.placeholder}
      </label>
      <components.Control {...props} />
    </>
  );
};

export interface ISelectProps {
  options: Option[];
  name: string;
  placeholder?: string;
  defaultValue?: Option[] | Option;
  isMulti?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  onChangeCallBack?: () => void;
}

export interface Option {
  label: string;
  value: string;
}

export const Select: FC<ISelectProps> = ({
  defaultValue,
  options,
  name,
  isClearable = false,
  isMulti = false,
  isDisabled,
  placeholder,
  onChangeCallBack,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  const { setTouched, setValue } = helpers;
  const { touched, error } = meta;

  const handleBlur = () => {
    setTouched(true);
  };

  const handleChange = (property: any) => {
    if (isMulti) {
      const values = property.map((item: Option) => item.value);
      setValue(values);
      onChangeCallBack && onChangeCallBack();
    } else {
      setValue(property.value);
      onChangeCallBack && onChangeCallBack();
    }
  };
  return (
    <div className="mb-3 w-100">
      <ReactSelect
        options={options}
        key={name}
        isDisabled={isDisabled}
        closeMenuOnSelect={true}
        components={{
          Control: (props) => <Control {...props} placeholder={placeholder} />,
        }}
        isMulti={isMulti}
        styles={getCustomStyles({ error, touched })}
        value={defaultValue}
        onChange={handleChange}
        onBlur={handleBlur}
        isClearable={isClearable}
        isOptionDisabled={(option: any) => option?.isDisabled}
      />
      {touched && error ? (
        <small className="text-danger ">{error}</small>
      ) : null}
    </div>
  );
};
