import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";
import { useQuery } from "react-query";

import { Button, Form, Card, Spinner } from "components";
import { Select } from "components/formik";

import { getRole, updateOrganizationRole } from "api";

import { useActionOnData } from "hooks";

const validationSchema = Yup.object().shape({
  teamsAdministratorRole: Yup.string().required(
    "Teams administrator role is required"
  ),
  userAdminRole: Yup.string().required("User admin role is required"),
  globalAdminRole: Yup.string().required("Global admin role is required"),
});

const roles = [
  { value: "Tutor", label: "Tutor" },
  { value: "Mentor", label: "Mentor" },
  { value: "Subadmin", label: "Subadmin" },

  { value: "Content Editor", label: "Content Editor" },
  { value: "Branch Manager", label: "Branch Manager" },
];

const SsoRoleSettingForm = ({ domain, tenantId }: any) => {
  const { data, isLoading } = useQuery(
    ["orgRole", domain],
    () => getRole(domain),
    {
      enabled: !!tenantId && !!tenantId,
      select: (data) => {
        return data?.data?.activeRole;
      },
    }
  );

  const [initialValue, setInitialValue] = useState({
    userAdminRole: "",
    teamsAdministratorRole: "",
    globalAdminRole: "",
  });
  const { mutateAsync } = useActionOnData({
    actionFunction: updateOrganizationRole,
    queryToBeInvalidated: "orgRole",
  });

  useEffect(() => {
    if (data) {
      setInitialValue({
        globalAdminRole: data?.global?.role_type,
        teamsAdministratorRole: data?.teams?.role_type,
        userAdminRole: data?.user?.role_type,
      });
    }
  }, [data]);
  const handleSubmit = (data, formik) => {
    formik.setSubmitting(true);
    mutateAsync({
      user: data?.userAdminRole,
      teams: data?.teamsAdministratorRole,
      global: data?.globalAdminRole,
      domain: domain,
    }).finally(() => formik.setSubmitting(false));
  };

  return (
    <Card>
      <Card.Header
        style={{
          fontSize: "16px",
          fontWeight: "800",
        }}
      >
        Select the role you want your Active Directory Administrators to login
        as:
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(formik) => {
              const { isSubmitting, isValid, dirty, values } = formik;

              return (
                <Form as={FormikForm}>
                  <Select
                    key="globalAdminRole"
                    name="globalAdminRole"
                    options={roles}
                    placeholder="AD Global Admin"
                    defaultValue={
                      values?.globalAdminRole
                        ? {
                            label: values?.globalAdminRole,
                            value: values?.globalAdminRole,
                          }
                        : null
                    }
                    isMulti={false}
                    isClearable={false}
                  />
                  <Select
                    key="userAdminRole"
                    name="userAdminRole"
                    options={roles}
                    placeholder="AD User Admin"
                    defaultValue={
                      values?.userAdminRole
                        ? {
                            label: values?.userAdminRole,
                            value: values?.userAdminRole,
                          }
                        : null
                    }
                    isMulti={false}
                    isClearable={false}
                  />
                  <Select
                    key="teamsAdministratorRole"
                    name="teamsAdministratorRole"
                    options={roles}
                    placeholder="AD Teams Admin"
                    defaultValue={
                      values?.teamsAdministratorRole
                        ? {
                            label: values?.teamsAdministratorRole,
                            value: values?.teamsAdministratorRole,
                          }
                        : null
                    }
                    isMulti={false}
                    isClearable={false}
                  />

                  <Button
                    variant="dark"
                    type="button"
                    onClick={() => handleSubmit(values, formik)}
                    className="ms-2"
                    disabled={!(isValid && dirty) || isSubmitting}
                  >
                    Update
                  </Button>
                </Form>
              );
            }}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default SsoRoleSettingForm;
