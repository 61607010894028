import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  Table,
  Button,
  SecondaryHeader,
  SecondaryHeaderInfo,
} from "components";

import { getKeyList } from "api";

import { keyTableColumns } from "../keyTableColumns";

const LabelPageList = () => {
  const navigate = useNavigate();
  const { isLoading, data } = useQuery("keyList", getKeyList);

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle="Key List"
            SecondaryHeaderText={`${data?.data?.length} data`}
            SecondaryText
          />
        </div>

        <div className="button-wrap">
          <Button variant="secondary" onClick={() => navigate("/addLabelKey")}>
            Create
          </Button>
        </div>
      </SecondaryHeader>

      <div className="content-block px-4 py-2">
        <Table
          columnData={keyTableColumns}
          tableData={data ? data?.data : []}
          pageCount={data?.data?.length}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default LabelPageList;
