import config from "configs";

import { getToken } from "utils";

import { errorHandler } from "./errorHandler";

export async function getOperations() {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/operations`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}
