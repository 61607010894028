import React from "react";

import VideoTableEditAction from "./videoTableEditAction";
import VideoTableDeleteAction from "./videoTableDeleteAction";

const VideoTableActions = ({ rowData }: any) => {
  const { is_default } = rowData;
  return (
    <div className="d-flex align-items-center gap-3">
      <VideoTableEditAction rowData={rowData} key="videoTableEditAction" />

      {is_default === 1 ? null : (
        <VideoTableDeleteAction
          rowData={rowData}
          key="videoTableDeleteAction"
        />
      )}
    </div>
  );
};

export default VideoTableActions;
