import { useEffect, useState } from "react";

import Masonry from "react-masonry-css";
import { Form as FormikForm, Formik } from "formik";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Form,
  SecondaryHeader,
  SecondaryHeaderInfo,
  SwitchButton,
} from "components";

import { configureGamification } from "api/gamification.api";

import { useActionOnData } from "hooks";

import { error } from "utils";

import { Columns } from "../organizationSetting/organizationSetting";
import OrganizationGamificationActivities from "../organizationGamificationActivities";
import OrganizationGamificationPathway from "../organizationGamificationPathway/organizationGamificationPathway";
import OrganizationBadgesActivities from "../organizationBadgesActivities";

const GamificationSetting = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState(null);
  const { state }: any = useLocation();
  const { mutateAsync } = useActionOnData({
    actionFunction: configureGamification,
    queryToBeInvalidated: "getSingleOrg",
    navigateTo: `organization/${state?.domain}`,
  });

  

  const handleSubmit = (values) => {
    try {
      if (state) {
        mutateAsync({
          domain: state?.domain,
          reputationActivities: values.reputationActivities,
          badgeActivities: values.badgeActivities,
          isPathwayEnabled: values.isPathwayEnabled,
          isBadgeEnabled: values.isBadgeEnabled,
          isRewardEnabled: values.isRewardEnabled,
          isReputationPointsEnabled: values.isReputationPointsEnabled,
        });
      }
    } catch (err) {
      error(err.message);
    }
  };

  useEffect(() => {
    if (state) {
      setInitialValues({
        isBadgeEnabled: state.gamification.isBadgeEnabled,
        badgeActivities: state.gamification.badgeActivities,
        isRewardEnabled: state.gamification.isRewardEnabled,
        isPathwayEnabled: state.gamification.isPathwayEnabled,
        reputationActivities: state.gamification.reputationActivities,
        isReputationPointsEnabled: state.gamification.isReputationPointsEnabled,
      });
    }
  }, [state]);

  return (
    <div className="content-wrapper">
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize={true}
        initialValues={initialValues}
      >
        {({ isSubmitting, dirty }) => {
          return (
            <Form as={FormikForm}>
              <SecondaryHeader>
                <div className="secondary-header-wrap">
                  <BsFillArrowLeftCircleFill
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  />
                  <SecondaryHeaderInfo
                    SecondaryHeaderTitle="Gamification setting"
                    SecondaryHeaderText=""
                    SecondaryText
                  />
                </div>
                <Button
                  variant="secondary"
                  disabled={!dirty || isSubmitting}
                  type="submit"
                >
                  Update
                </Button>
              </SecondaryHeader>
              <div className="p-4">
                <Masonry
                  breakpointCols={Columns}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <OrganizationGamificationActivities />
                  <OrganizationBadgesActivities />
                  <OrganizationGamificationPathway />
                  <Card>
                    <Card.Header
                      style={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      <SwitchButton
                        name="isRewardEnabled"
                        label="Allow Rewards"
                      />
                    </Card.Header>
                    <Card.Body></Card.Body>
                  </Card>
                </Masonry>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default GamificationSetting;
