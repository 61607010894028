import { useNavigate } from "react-router-dom";

import { Button } from "components";

import View from "assets/images/view.svg";

export const logTableColumns = [
  {
    Header: "Request Id",
    accessor: "requestId",
    disableSortby: true,
  },
  {
    Header: "Domain",
    accessor: "domain",
  },
  {
    Header: "Operation",
    accessor: "operation",
  },
  {
    Header: "Actions",
    Cell: ({
      row: {
        original: { _id },
      },
    }) => {
      const navigate = useNavigate();

      return (
        <Button
          variant="leading-icon"
          className="leading-icon"
          onClick={() => navigate(`/log/${_id}`)}
        >
          <img src={View} alt="view" />
          Show Detail
        </Button>
      );
    },
  },
];
