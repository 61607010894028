import React, { FC } from "react";

import GamificationActivityTableDeleteAction from "./gamificationActivityDeleteAction";
import GamificationActivityEditAction from "./gamificationActivityEditAction";

interface IGamificationActivityActionsProps {
  rowData: any;
}
const GamificationActivityTableActions: FC<
  IGamificationActivityActionsProps
> = ({ rowData }) => {
  return (
    <div className="d-flex">
      <GamificationActivityEditAction
        rowData={rowData}
        key="gamificationActivityEditAction"
      />
      <GamificationActivityTableDeleteAction
        rowData={rowData}
        key="gamificationActivityDeleteAction"
      />
    </div>
  );
};

export default GamificationActivityTableActions;
