import {
  Table,
  SecondaryHeader,
  SecondaryHeaderInfo,
  SecondaryHeaderSearchField,
  Button,
} from "components";

import { useGetPurchaseHistory } from "./useGetPurchaseHistory";
import { purchaseHistoryTableColumns } from "./purchaseHistoryTableColumns";
import PurchaseHistoryFilter from "./purchaseHistoryFilter";

const PurchaseHistory = () => {
  const {
    data,
    search,
    isLoading,
    dateRange,
    setPerPage,
    setFilters,
    currentPage,
    setDateRange,
    paymentMethod,
    paymentStatus,
    onClearSearch,
    setCurrentPage,
    onSearchChange,
    setPaymentMethod,
    setPaymentStatus,
  } = useGetPurchaseHistory();

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryText
            SecondaryHeaderText={`${data?.pagination?.totalCounts} purchase history`}
            SecondaryHeaderTitle="Purchase History"
          />
        </div>
        <SecondaryHeaderSearchField
          placeholder="Search User"
          value={search}
          data-testid="search"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={onSearchChange}
          onClear={onClearSearch}
        />
        <div className="button-wrap">
          <Button variant="secondary" style={{ visibility: "hidden" }}>
            Create
          </Button>
        </div>
      </SecondaryHeader>
      <div className="filter-block">
        <PurchaseHistoryFilter
          key="purchaseHistory-filter"
          dateRange={dateRange}
          isLoading={isLoading}
          setFilters={setFilters}
          setDateRange={setDateRange}
          paymentMethod={paymentMethod}
          paymentStatus={paymentStatus}
          setPaymentMethod={setPaymentMethod}
          setPaymentStatus={setPaymentStatus}
        />
      </div>
      <div className="content-block px-4 py-2">
        <Table
          tableData={data ? data?.data : []}
          pageCount={data?.pagination?.totalPages}
          isLoading={isLoading}
          columnData={purchaseHistoryTableColumns}
          setPerPage={setPerPage}
          pagination={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default PurchaseHistory;
