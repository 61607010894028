import React, { useContext, useState } from "react";

import { useMutation, useQueryClient } from "react-query";

import {
  Button,
  Modal,
  CustomModal,
  ModalContext,
  ModalContents,
  ModalOpenButton,
  ModalDismissButton,
} from "components";

import { deleteOrgVideo } from "api";

import { error, success } from "utils";

import Delete from "assets/images/delete.svg";

const ModalContent = ({ id }: any) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setIsOpen] = useContext(ModalContext);

  const deleteMutation = useMutation(deleteOrgVideo, {
    onSuccess: (data) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("orgVideoList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  return (
    <>
      <Modal.Body>
        Click delete button to delete or cancel button to cancel action.
      </Modal.Body>
      <Modal.Footer>
        <ModalDismissButton>
          <Button variant="outline" type="button">
            Cancel
          </Button>
        </ModalDismissButton>

        <Button
          onClick={() => {
            setIsSubmitting(true);
            deleteMutation
              .mutateAsync({
                sectionId: id,
              })
              .then(() => {
                setIsOpen(false);
                setIsSubmitting(false);
              });
          }}
          disabled={isSubmitting}
          type="submit"
          variant="orange"
        >
          {isSubmitting ? "Deleting..." : "Delete"}
        </Button>
      </Modal.Footer>
    </>
  );
};

const OrgAssetDeleteAction = ({ rowData }: any) => {
  return (
    <CustomModal centered={false} backdrop={false}>
      <ModalOpenButton>
        <Button type="button" className="leading-icon" variant="leading-icon">
          <img src={Delete} alt="delete" />
          Delete
        </Button>
      </ModalOpenButton>
      <ModalContents title="Are you sure? You want to delete this section?">
        <ModalContent id={rowData?._id} />
      </ModalContents>
    </CustomModal>
  );
};

export default OrgAssetDeleteAction;
