import config from "../configs";
import { errorHandler } from "./errorHandler";

export async function login(data: any) {
	const response = await fetch(config.mainApiEndpoint + "/auth/adminLogin", {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});
	errorHandler(response);
	return response.json();
}
