import { success } from "utils";

import ClipBoard from "assets/images/clipboard.svg";

import ThirdPartyTableActions from "../thirdPartyTableActions/thirdPartyTableActions";

/**
 *
 * @param val - value to be copied
 * @return null
 */
const copyValue = (val: string) => {
  navigator.clipboard.writeText(val);
  success("Copied to clipboard.");
};

//predefined columns for table
export const thirdPartyTableColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "ClientId",

    Cell: ({ row: { original } }: any) => {
      return (
        <div className="d-flex align-items-center gap-2">
          <div>{original.client_id}</div>
          <img
            onClick={() => copyValue(original.client_id)}
            src={ClipBoard}
            alt="clipborad"
          />
        </div>
      );
    },
  },
  {
    Header: "Client Secret",
    Cell: ({ row: { original } }: any) => {
      return (
        <div className="d-flex align-items-center gap-2">
          <div
            className="d-inline-block text-truncate"
            style={{ width: "250px" }}
          >
            {original.client_secret}
          </div>
          <img
            onClick={() => copyValue(original.client_secret)}
            src={ClipBoard}
            alt="clipborad"
          />
        </div>
      );
    },
  },
  {
    Header: "Created Date",
    accessor: "createdAt",
  },
  {
    Header: "Actions",
    width: 300,
    Cell: ({ row: { original } }: any) => {
      return (
        <ThirdPartyTableActions
          rowData={original}
          key="thirdPartyTableActions"
        />
      );
    },
  },
];
