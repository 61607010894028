import { useQuery } from "react-query";

import { getAppSettings, modifyAppSettings } from "api";

import { useActionOnData } from "hooks";

const useAppSetting = () => {
  const { data, isLoading } = useQuery(["appSettings"], getAppSettings, {
    select: (data) => ({
      enableClientMarketplace: data?.data?.enable_client_marketplace,
      changeLoaderStatus: data?.data?.enable_loader,
      changeGamificationStatus: data?.data?.enable_gamification,
      changePaymentStatus: data?.data?.enable_payment,
      changeBundleStatus: data?.data?.show_bundle,
      id: data?.data?._id,
      paymentSettings: data?.data?.paymentSettings,
      changeCommunityForumStatus: data?.data?.show_community_forum,
    }),
  });

  const { mutateAsync } = useActionOnData({
    actionFunction: modifyAppSettings,
    queryToBeInvalidated: "appSettings",
  });

  return { data, isLoading, mutateAsync };
};

export default useAppSetting;
