import React, { useEffect, useState } from "react";

import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

import { Col, Row, SecondaryHeader, SecondaryHeaderInfo } from "components";

import formattedDateAndTime from "utils/formattedDateAndTime";

import InfoBox from "./infoBox";
import InfoTable from "./infoTable";
import DetailBox from "./detailBox";

const SinglePurchaseHistoryDetail = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.state?.data) {
      const {
        state: {
          data: { createdAt, currency, id, totalAmount, currencySymbol },
        },
      } = location;
      setPaymentDetails({
        "Transaction Id": id,
        "Total amount paid": `${currencySymbol}${parseFloat(
          totalAmount
        ).toFixed(2)}`,
        Currency: currency,
        "Date of payment": formattedDateAndTime(createdAt),
      });
    }
  }, []);

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <BsFillArrowLeftCircleFill
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <SecondaryHeaderInfo
            SecondaryText
            SecondaryHeaderText={""}
            SecondaryHeaderTitle={"Purchase History Detail"}
          />
        </div>
      </SecondaryHeader>

      <div className="p-4">
        <Row
          className="g-3"
          style={{
            gridAutoFlow: "column",
          }}
        >
          <Col xl={4}>
            <InfoBox
              title="Email"
              value={location?.state?.data?.userDetails?.email}
            />
          </Col>
          <Col xl={4}>
            <InfoBox
              title="Domain"
              value={location?.state?.data?.userDetails?.domain}
            />
          </Col>
          <Col xl={4}>
            <InfoBox
              title="Role"
              value={location?.state?.data?.userDetails?.role_name}
            />
          </Col>
          <Col xl={4}>
            <InfoBox
              title="Payment Method"
              value={location?.state?.data?.paymentMethod}
            />
          </Col>
          <Col xl={4}>
            <InfoBox
              title="Payment Status"
              value={location?.state?.data?.paymentStatus}
            />
          </Col>

          <Col xl={4}>
            <InfoBox
              title="Provider Email"
              value={
                location?.state?.data?.providerDetails?.email ??
                "No provider found"
              }
            />
          </Col>
          <Col xl={6}>
            <InfoTable
              columns={[
                {
                  Header: "Title",
                  accessor: "cb_title",
                },
                {
                  Header: "Price",
                  Cell: ({ row: { original } }: any) => {
                    const { cb_price } = original;
                    return (
                      <div>{`${
                        location?.state?.data?.currencySymbol
                      }${parseFloat(cb_price).toFixed(2)}`}</div>
                    );
                  },
                },
              ]}
              data={location?.state?.data?.courseDetails}
              noDataText="No Course found"
              title={
                location.state.data.type === "course"
                  ? "Course Details"
                  : "Bundle details"
              }
              key="courseDetailsTable"
            />
          </Col>
          <Col xl={6}>
            <DetailBox
              title="Payment Details"
              data={paymentDetails}
              noDataText="No payment Details found"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SinglePurchaseHistoryDetail;
