import { useRef, useState } from "react";

import { useQuery } from "react-query";

import { getLogs } from "api";

import { useDebounce } from "hooks";

const useLog = () => {
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const timerRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [selectedOrg, setSelectedOrg] = useState(undefined);
  const [selectedOperation, setSelectedOperation] = useState(undefined);
  const debounceQuery = useDebounce(searchText, 500);
  const [filters, setFilters] = useState({
    domain: "",
    operation: "",
  });
  const { data, isLoading } = useQuery(
    ["logList", currentPage, perPage, debounceQuery, filters],
    () => getLogs(currentPage, perPage, debounceQuery, filters)
  );

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setSearchText(value);
      setCurrentPage(1);
    }, 500);
  };

  const onClearSearch = () => {
    setSearch("");
    setSearchText("");
  };

  return {
    data,
    search,
    isLoading,
    setFilters,
    setPerPage,
    selectedOrg,
    currentPage,
    onClearSearch,
    onSearchChange,
    setSelectedOrg,
    setCurrentPage,
    selectedOperation,
    setSelectedOperation,
  };
};

export default useLog;
