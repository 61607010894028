export const currencyTypes = [
	{ value: "EUR", label: "Euro" },
	{ value: "USD", label: "Dollar" },
];

export const intervalTypes = [
	{ value: "day", label: "daily" },
	{ value: "week", label: "week" },
	{ value: "month", label: "month" },
	{ value: "year", label: "year" },
];
