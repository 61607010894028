import config from "../configs";
import { getToken } from "../utils/token";
import { errorHandler } from "./errorHandler";

export async function getLogs(
  page = 0,
  perPage = 10,
  search = "",
  filters = {
    domain: "",
    operation: "",
  }
) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/log/list-logs?page=${page}&perPage=${perPage}&delay=1&search=${search}&domain=${filters["domain"]}&operation=${filters["operation"]}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );

  errorHandler(response);
  return response.json();
}

export async function getLogId(logId: string) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/log/${logId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function deleteLogs(data) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/log/delete`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
}
