import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Button,
  Spinner,
  CheckBox,
  SecondaryHeader,
  SecondaryHeaderInfo,
} from "components";

import { getOrgVideo, saveOrgVideo, updateOrgVideo } from "api";

import { error, success } from "utils";

export const roles = [
  { value: "all", label: "All", isDisabled: false },
  { value: "tutor", label: "Tutor", isDisabled: false },
  { value: "mentor", label: "Mentor", isDisabled: false },
  { value: "admin", label: "Subadmin", isDisabled: false },
  { value: "learner", label: "Student", isDisabled: false },
  { value: "content_editor", label: "Content Editor", isDisabled: false },
  { value: "branch_manager", label: "Branch Manager", isDisabled: false },
  { value: "Finance Manager", label: "Finance Manager", isDisabled: false },
];

const addValidationSchema = Yup.object().shape({
  isActive: Yup.boolean(),
  name: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  role: Yup.array()
    .required("Required")
    .min(1, "Atleast one option must be selected."),
  assets: Yup.array()
    .of(
      Yup.object().shape({
        isActive: Yup.boolean(),
        description: Yup.string().required("Required"),
        title: Yup.string().required("Required"),
        link: Yup.string().required("Required"),
        type: Yup.string().required("Required"),
        role: Yup.array()
          .required("Required")
          .min(1, "Atleast one option must be selected."),
      })
    )
    .required("Required"),
});

const updateValidationSchema = Yup.object().shape({
  isActive: Yup.boolean(),
  name: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  role: Yup.array()
    .required("Required")
    .min(1, "Atleast one option must be selected."),
});

const AddOrgSection = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const params = useParams();
  const [roleOptions, setRoleOptions] = useState(roles);

  const { data, isLoading } = useQuery<any>(
    ["orgSection", params.sectionId],
    () => getOrgVideo(params.sectionId),
    {
      enabled: !!params.sectionId,
      select(data) {
        return data?.data;
      },
    }
  );

  const saveMutation = useMutation(saveOrgVideo, {
    onSuccess: (data) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("orgVideoList");
        // navigate("/videoList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const updateMutation = useMutation(updateOrgVideo, {
    onSuccess: (data) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("orgVideoList");
        // navigate("/videoList");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const handleSubmit = (values, formik) => {
    if (data) {
      updateMutation
        .mutateAsync({
          name: values?.name,
          description: values?.description,
          assets: values?.assets,
          sectionId: params?.sectionId,
          domain: data?.domain,
          isActive: values?.isActive,
          role: values?.role,
        })
        .then(() => formik.setSubmitting(false))
        .then(() => navigate(-1));
    } else {
      saveMutation
        .mutateAsync({
          name: values?.name,
          description: values?.description,
          assets: values?.assets,
          domain: params?.domain,
          isActive: values?.isActive,
          role: values?.role,
        })
        .then(() => formik.setSubmitting(false))
        .then(() => navigate(-1));
    }
  };

  return (
    <>
      <div className="content-wrapper">
        {isLoading ? (
          <div
            style={{
              height: "300px",
            }}
            className="d-flex w-100 justify-content-center align-items-center"
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <Formik
            initialValues={{
              name: data ? data?.name : "",
              description: data ? data?.description : "",
              isActive: data ? data?.isActive : false,
              assets: data ? data?.assets : [],
              role: data ? data?.role : [],
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={
              data ? updateValidationSchema : addValidationSchema
            }
          >
            {(formik) => {
              const { isSubmitting, isValid, dirty, values } = formik;
              useEffect(() => {
                if (values?.role) {
                  setRoleOptions((prevOptions) =>
                    prevOptions?.map((item) => {
                      if (
                        values?.role?.length === 1 &&
                        values?.role[0] === "all"
                      ) {
                        const updated =
                          item?.value === "all"
                            ? item
                            : { ...item, isDisabled: true };

                        return updated;
                      } else if (
                        values?.role?.length > 0 &&
                        !values?.role?.includes("all")
                      ) {
                        return item?.value !== "all"
                          ? item
                          : { ...item, isDisabled: true };
                      } else {
                        return { ...item, isDisabled: false };
                      }
                    })
                  );
                }
              }, [values?.role]);
              return (
                <Form as={FormikForm}>
                  <SecondaryHeader>
                    <div className="secondary-header-wrap">
                      <BsFillArrowLeftCircleFill
                        style={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(-1)}
                      />
                      <SecondaryHeaderInfo
                        SecondaryHeaderTitle={
                          state ? "Update Asset" : "Add Asset"
                        }
                        SecondaryHeaderText=""
                        SecondaryText
                      />
                    </div>

                    <div className="button-wrap">
                      <Button
                        variant="secondary"
                        type="submit"
                        disabled={!(isValid && dirty) || isSubmitting}
                      >
                        {!state ? "Create Asset" : "Update Asset"}
                      </Button>
                    </div>
                  </SecondaryHeader>

                  <Row className="mx-4 my-4">
                    <Col xl={4}>
                      <Input label="name" placeholder="name" name="name" />
                      <Input
                        label="description"
                        placeholder="description"
                        name="description"
                      />
                      <Select
                        placeholder="Select Role"
                        name="role"
                        isMulti={true}
                        defaultValue={
                          values.role
                            ? values?.role?.map((item) =>
                                roles.find((role) => role?.value === item)
                              )
                            : null
                        }
                        options={roleOptions}
                      />
                      <CheckBox
                        label="active"
                        name="isActive"
                        defaultChecked={values?.isActive}
                      />
                    </Col>
                  </Row>
                  {/* {!data && (
                    <FieldArray
                      name="assets"
                      render={({ insert, remove, push }) => (
                        <div>
                          <Row className="mx-4 mb-4">
                            {values?.assets?.length > 0 &&
                              values?.assets?.map((defaultLabel, index) => (
                                <Col xl={4} key={index}>
                                  <Input
                                    marginBottom="20px"
                                    name={`assets.${index}.title`}
                                    label="Enter title"
                                    placeholder="Enter title"
                                  />

                                  <Input
                                    marginBottom="20px"
                                    name={`assets.${index}.description`}
                                    label="Enter description"
                                    placeholder="Enter description"
                                  />

                                  <Select
                                    placeholder="Select Type"
                                    name={`assets.${index}.type`}
                                    defaultValue={
                                      values?.assets[index].type
                                        ? {
                                            label: values?.assets[index]?.type,
                                            value: values?.assets[index]?.type,
                                          }
                                        : null
                                    }
                                    options={[
                                      {
                                        label: "video",
                                        value: "video",
                                      },
                                      {
                                        label: "image",
                                        value: "image",
                                      },
                                    ]}
                                  />

                                  <Input
                                    marginBottom="20px"
                                    name={`assets.${index}.link`}
                                    label="Enter link"
                                    placeholder="Enter link"
                                  />

                                  <CheckBox
                                    name={`assets.${index}.isActive`}
                                    label="Status"
                                    defaultChecked={
                                      values.assets[index]?.isActive
                                    }
                                  />

                                  <Button
                                    type="button"
                                    className="d-flex align-items-center gap-1 mb-4"
                                    variant="orange"
                                    onClick={() => remove(index)}
                                  >
                                    <AiOutlineDelete />
                                    Delete
                                  </Button>
                                </Col>
                              ))}
                          </Row>
                          <div className=" d-flex justify-content-center w-100">
                            <Button
                              type="button"
                              className="d-flex align-items-center justify-content-center gap-1"
                              variant="primary"
                              onClick={() =>
                                push({
                                  title: "",
                                  description: "",
                                  type: "",
                                  link: "",
                                  isActive: true,
                                })
                              }
                            >
                              <AiOutlinePlus />
                              Add Section
                            </Button>
                          </div>
                        </div>
                      )}
                    />
                  )} */}
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
};

export default AddOrgSection;
