import React, { FC } from "react";

import ReactSelect from "react-select";

import { Control, getCustomStyles, ShowFilter } from "components";

import { success } from "utils";

interface ICourseProviderFilterProps {
  isActive: any;
  isDefault: any;
  isLoading: boolean;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  setIsActive: React.Dispatch<React.SetStateAction<any>>;
  setIsDefault: React.Dispatch<React.SetStateAction<any>>;
}

const CourseProviderFilter: FC<ICourseProviderFilterProps> = ({
  isLoading,
  isActive,
  isDefault,
  setFilters,
  setIsActive,
  setIsDefault,
}) => {
  return (
    <ShowFilter
      triggerTitle="Show Filters"
      isLoading={isLoading}
      handleApply={() => {
        setFilters({
          isDefault: isDefault?.value,
          isActive: isActive?.value,
        });
        success("Filter applied successfully!!");
        return "success";
      }}
    >
      <div className="d-flex flex-column gap-3">
        <div>
          <label>Select Default or Non-Default Course Provider:</label>
          <ReactSelect
            options={[
              {
                label: "Default",
                value: true,
              },
              {
                label: "Non default",
                value: false,
              },
            ]}
            key="default-course-providers"
            components={{
              Control: (props) => (
                <Control {...props} placeholder="Select Option" />
              ),
            }}
            isMulti={false}
            value={isDefault}
            isClearable={true}
            styles={getCustomStyles({ error: null, touched: null })}
            onChange={(property: any) => setIsDefault(property)}
          />
        </div>
        <div>
          <label>Select Active or Inactive Course Provider:</label>
          <ReactSelect
            options={[
              {
                label: "Active",
                value: true,
              },
              {
                label: "InActive",
                value: false,
              },
            ]}
            key="default-course-providers"
            components={{
              Control: (props) => (
                <Control {...props} placeholder="Select Option" />
              ),
            }}
            name="isActive"
            value={isActive}
            styles={getCustomStyles({ error: null, touched: null })}
            isMulti={false}
            isClearable={true}
            onChange={(property: any) => setIsActive(property)}
          />
        </div>
      </div>
    </ShowFilter>
  );
};

export default CourseProviderFilter;
