import React, { useState } from "react";

import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import { SecondaryHeader, SecondaryHeaderInfo, Spinner } from "components";

import { getLogId } from "api";

export const LogDetail = () => {
  const [log, setLog] = useState<any>();
  const params = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isFetched } = useQuery<any>(
    ["logDetail", params.logId],
    () => getLogId(params.logId)
  );
  React.useEffect(() => {
    if (data && data.data) {
      setLog(data.data);
    }
  }, [data, isFetched]);

  return (
    <>
      <div className="content-wrapper">
        <SecondaryHeader>
          <div className="secondary-header-wrap">
            <BsFillArrowLeftCircleFill
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <SecondaryHeaderInfo
              SecondaryText
              SecondaryHeaderText={""}
              SecondaryHeaderTitle={"Log Detail"}
            />
          </div>
        </SecondaryHeader>
        <div className="content-block">
          <div className="col-12 col-md-12 col-lg-12">
            {log ? (
              <div className="card">
                <div className="card-header">
                  <h4>Logs</h4>
                </div>

                <div className="card-body">
                  {isLoading && log && log._id ? (
                    <Spinner animation="border" />
                  ) : (
                    <div className="row">
                      <div className="col-md-6">
                        <strong>Domain</strong>
                        <p>{log?.domain ?? ""}</p>
                      </div>
                      <div className="col-md-6">
                        <strong>Request Id</strong>
                        <p>{log?.requestId ?? ""}</p>
                      </div>
                      <div className="col-md-6">
                        <strong>Operation</strong>
                        <p>{log?.operation ?? ""}</p>
                      </div>
                      <div className="col-md-6">
                        <strong>Status Code</strong>
                        <p>{log?.statusCode ?? ""}</p>
                      </div>{" "}
                      <div className="col-md-6">
                        <strong>Uri</strong>
                        <p>{log?.uri ?? ""}</p>
                      </div>
                      {log?.createdAt && (
                        <div className="col-md-12">
                          <strong className="text-capitalize">
                            Created At
                          </strong>
                          <p>{log?.createdAt ?? ""}</p>
                        </div>
                      )}
                      {log?.updatedAt && (
                        <div className="col-md-12">
                          <strong className="text-capitalize">
                            Updated At
                          </strong>
                          <p>{log?.updatedAt ?? ""}</p>
                        </div>
                      )}
                      {log?.user &&
                        Object.entries(log?.user).map(([key]) => (
                          <div className="col-md-6" key={key}>
                            <strong className="text-capitalize">{key}</strong>
                            <p>{log?.user[key]}</p>
                          </div>
                        ))}
                      {log?.ipaddress && (
                        <div className="col-md-6">
                          <strong className="text-capitalize">
                            Ip Address
                          </strong>
                          <p>{log?.ipaddress}</p>
                        </div>
                      )}
                      {log?.requestBody && (
                        <div className="col-md-6">
                          <strong className="text-capitalize">
                            Request Body
                          </strong>
                          <pre>{JSON.stringify(log?.requestBody, null, 2)}</pre>
                        </div>
                      )}
                      {log?.responseBody && (
                        <div className="col-md-12">
                          <strong className="text-capitalize">
                            Response Body
                          </strong>
                          <pre>
                            {JSON.stringify(log?.responseBody, null, 2)}
                          </pre>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
              >
                <Spinner animation="border" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
