import React from "react";

export type IPlanMemebershipeContext = [
  any,
  React.Dispatch<React.SetStateAction<any>>,
  React.Dispatch<React.SetStateAction<boolean>>
];

const PlanMembershipContext = React.createContext<IPlanMemebershipeContext>([
  {},
  () => null,
  () => null,
]);

export { PlanMembershipContext };
