export const planColumns = [
	{
		Header: "Title",
		accessor: "title",
	},
	{
		Header: "Description",
		accessor: "description",
	},
	{
		Header: "Email",
		accessor: "email",
	},
	{
		Header: "Currency",
		accessor: "currency",
	},
	{
		Header: "Interval Type",
		accessor: "intervalType",
	},
];
