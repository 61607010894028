import { useState } from "react";

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import {
  getOrganizationEmailSetting,
  getOrgLabelPageList,
  getOrgVideoList,
  getPendingUserInvitationList,
} from "api";
import { getSingleOrganization } from "api/organization.api";

const useGetOrganizationDetail = () => {
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [assetPerPage, setAssetPerPage] = useState(10);
  const [assetCurrentPage, setAssetCurrentPage] = useState(1);
  const { organizationId }: any = useParams();

  const { data: singleOrgData, isLoading: isSingleOrgDataLoading } = useQuery(
    ["getSingleOrg", organizationId],
    () => getSingleOrganization(organizationId),
    {
      select(data) {
        const paymentSetting = [
          data?.data?.stripe,
          data?.data?.paypal,
          data?.data?.razorpay,
        ];
        // eslint-disable-next-line no-unsafe-optional-chaining
        const updatedData = { ...data?.data, paymentSetting };
        return updatedData;
      },
    }
  );

  const { data, isLoading } = useQuery(
    ["organizationEmailSettingList", organizationId],
    () => getOrganizationEmailSetting(organizationId),
    {
      select: (data) => {
        if (data && data.status === "success") {
          return data.data.email_templates.map((item) => {
            const {
              _id,
              subject,
              content,
              scope,
              description,
              email_variables,
              super_admin_setting_id,
            } = item;
            return {
              id: _id,
              subject,
              content,
              scope,
              description,
              email_variables,
              super_admin_setting_id,
              domain: data?.data?.domain,
              orgId: data?.data?.org_id,
            };
          });
        }
      },
    }
  );

  const { data: orgLabelPageList, isLoading: isOrgLabelPageListLoading } =
    useQuery(
      ["orgLabelPageList", organizationId],
      () => getOrgLabelPageList(organizationId),
      {
        select(data) {
          return data?.data;
        },
      }
    );

  const { data: orgVideoList, isLoading: isOrgVideoListLoading } = useQuery(
    ["orgVideoList", organizationId, assetCurrentPage, assetPerPage],
    () => getOrgVideoList(organizationId, assetCurrentPage, assetPerPage),
    {
      select(data) {
        return {
          assetList: data?.data?.datas,
          totalCount: data?.data?.totalCounts,
          totalPage: Math.ceil(data?.data?.totalCounts / assetPerPage),
        };
      },
    }
  );

  const { data: pendingUserInvitationList, isLoading: loading } = useQuery(
    ["pendingUserInvitationList", currentPage, perPage, organizationId],
    () => getPendingUserInvitationList(currentPage, perPage, organizationId),
    {
      select: (data) => {
        if (data && data.status === "success") {
          const res = data?.data?.map((item) => {
            const {
              learner_name: name,
              learner_email: email,
              learner_image: image,
              learner_phone: phone,
              learner_id,
              learner_country: country,
            } = item.akademyDetails;
            return {
              _id: item._id,
              name,
              email,
              learner_id,
              image,
              phone,
              country,
            };
          });
          return {
            total_pages: data?.total_pages,
            data: res,
          };
        }
      },
    }
  );

  return {
    data,
    loading,
    isLoading,
    setPerPage,
    currentPage,
    orgVideoList,
    singleOrgData,
    setCurrentPage,
    setAssetPerPage,
    assetCurrentPage,
    orgLabelPageList,
    setAssetCurrentPage,
    isOrgVideoListLoading,
    isSingleOrgDataLoading,
    pendingUserInvitationList,
    isOrgLabelPageListLoading,
    assetPerPage,
  };
};

export default useGetOrganizationDetail;
