import React, { useState, useMemo } from "react";

interface SelectedItemForEditProps {
  selectedItemForEdit: any;
  setSelectedItemForEdit: (state: any) => void;
}

const SelectedItemForEditContext = React.createContext<
  SelectedItemForEditProps | undefined
>(undefined);

SelectedItemForEditContext.displayName = "SelectedItemForEditContext";

function SelectedItemForEditProvider(props: any) {
  const [selectedItemForEdit, setSelectedItemForEdit] = useState<string>("");

  const value = useMemo(() => {
    return {
      selectedItemForEdit,
      setSelectedItemForEdit,
    };
  }, [selectedItemForEdit]);

  return <SelectedItemForEditContext.Provider value={value} {...props} />;
}

function useSelectedItemForEditContext() {
  const context = React.useContext(SelectedItemForEditContext);

  if (context === undefined) {
    throw new Error(
      `useSelectedItemForEditContext must be used within a SelectedItemForEditProvider`
    );
  }

  return context;
}

export { SelectedItemForEditProvider, useSelectedItemForEditContext };
