import { useRef, useState } from "react";

import { useQuery } from "react-query";

import { getPurchaseHistory } from "api";

import { useDebounce } from "hooks";

import formattedDateAndTime from "utils/formattedDateAndTime";

const useGetPurchaseHistory = () => {
  const [perPage, setPerPage] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const [dateRange, setDateRange] = useState(["", ""]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [filters, setFilters] = useState({
    payment_method: paymentMethod,
    payment_status: paymentStatus,
    datePicked: dateRange,
  });
  const timerRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const debounceQuery = useDebounce(searchText, 500);
  const { data, isLoading, isSuccess } = useQuery(
    ["purchaseHistoryList", currentPage, perPage, debounceQuery, filters],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    () => getPurchaseHistory(currentPage, perPage, debounceQuery, filters),
    {
      select: (data) => {
        const formattedData = data?.data?.data?.map((item) => ({
          id: item._id,
          currency: item.currency_details.name,
          totalCourses: item.course_details?.length,
          paymentStatus: item.payment_status,
          userDetails: item.user_details,
          paymentMethod: item.payment_method,
          createdAt: formattedDateAndTime(item.createdAt),
          providerDetails: item.provider_details,
          paymentDetails: item.payment_details,
          courseDetails: item.course_details,
          currencySymbol: item.currency_details.symbol,
          totalAmount: item.totalAmount,
          type: item.type,
        }));
        return {
          data: formattedData,
          pagination: {
            totalPages: Math.ceil(data?.data?.totalCounts / perPage),
            totalCounts: data?.data?.totalCounts,
          },
        };
      },
    }
  );

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setSearchText(value);
      setCurrentPage(1);
    }, 500);
  };

  const onClearSearch = () => {
    setSearch("");
    setSearchText("");
    setCurrentPage(1);
  };

  return {
    data,
    search,
    perPage,
    isLoading,
    dateRange,
    isSuccess,
    setPerPage,
    setFilters,
    currentPage,
    setDateRange,
    paymentMethod,
    paymentStatus,
    onClearSearch,
    setCurrentPage,
    onSearchChange,
    setPaymentMethod,
    setPaymentStatus,
  };
};

export { useGetPurchaseHistory };
