import { useQuery } from "react-query";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Col,
  Row,
  Table,
  Image,
  Drawer,
  Button,
  SecondaryHeader,
  OffcanvasOpenButton,
  SecondaryHeaderInfo,
} from "components";

import { getLabelPage } from "api";

import { useSelectedItemForEditContext } from "context";

import { labelTableColumns } from "./labelTableColumns";
import AddLabel from "./addLabel";

const LabelPageList = () => {
  const params = useParams();
  const { state } = useLocation();

  const navigate = useNavigate();

  const { setSelectedItemForEdit } = useSelectedItemForEditContext();

  const { isLoading, data } = useQuery(
    ["labelList", { params }],
    () => getLabelPage(params?.labelPageId),
    {
      select: (data) => {
        return data?.data;
      },
    }
  );

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <BsFillArrowLeftCircleFill
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />

          <SecondaryHeaderInfo
            SecondaryHeaderTitle={state}
            SecondaryHeaderText={`${data?.defaultLabels?.length} data`}
            SecondaryText
          />
        </div>

        <div className="button-wrap">
          <Drawer handleExit={() => setSelectedItemForEdit(null)}>
            <OffcanvasOpenButton>
              <Button type="button" variant="primary">
                Add Label
              </Button>
            </OffcanvasOpenButton>

            <AddLabel />
          </Drawer>
        </div>
      </SecondaryHeader>
      <Row
        className="g-2 mb-3"
        style={{
          gridAutoFlow: "column",
        }}
      >
        <Col xl={8}>
          <div className="content-block px-4 py-3 h-100">
            <h3 className="fw-bold mb-4">Label Page Details</h3>
            <Row className="g-3 mb-3">
              <Col xl={3}>
                <strong>Label Page Name: </strong>
                <div>{data?.labelPage}</div>
              </Col>
              <Col xl={3}>
                <strong>Status: </strong>
                <div>{data?.isActive ? "active" : "inactive"}</div>
              </Col>
              <Col xl={3}>
                <strong>Total Labels: </strong>
                <div>{data?.defaultLabels?.length}</div>
              </Col>
              <Col xl={3}>
                <strong>Created At: </strong>
                <div>{new Date(data?.createdAt).toDateString()}</div>
              </Col>
              <Col xl={3}>
                <strong>Updated At: </strong>
                <div>{new Date(data?.updatedAt).toDateString()}</div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xl={4}>
          <div className="content-block px-4 py-3 h-100">
            <Image
              src={
                data?.image ??
                "https://www.waterfieldtechnologies.com/wp-content/uploads/2019/02/placeholder-image-gray-3x2.png"
              }
              alt="labelPage"
              width="100%"
              height="100%"
              className="border"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <div className="content-block px-4 py-2">
            <Table
              columnData={labelTableColumns}
              tableData={data ? data?.defaultLabels : []}
              pageCount={data?.defaultLabels?.length}
              isLoading={isLoading}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LabelPageList;
