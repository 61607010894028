import React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "components";

import Info from "assets/images/info.svg";

const LabelPageTableActions = ({ rowData }: any) => {
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center gap-3">
      <Button
        variant="leading-icon"
        className="leading-icon"
        type="button"
        onClick={() =>
          navigate(`/orgLabelList/${rowData?._id}`, {
            state: rowData?.labelPage,
          })
        }
      >
        <img src={Info} alt="view detail" />
        View Labels
      </Button>
      <Button
        variant="leading-icon"
        className="leading-icon"
        type="button"
        onClick={() =>
          navigate(`/keyList/${rowData?._id}`, {
            state: rowData?.labelPage,
          })
        }
      >
        <img src={Info} alt="view detail" />
        View Keys
      </Button>
    </div>
  );
};

export default LabelPageTableActions;
