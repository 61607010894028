import { errorHandler } from "./errorHandler";

export async function uploadImage(data: any) {
  const response = await fetch(
    "https://awsuploader.services.olive.media/upload",
    {
      method: "POST",
      body: data,
    }
  );
  errorHandler(response);
  return response.json();
}
