import config from "../configs";
import { getToken } from "../utils/token";
import { errorHandler } from "./errorHandler";

/**
 * This function is used to get all the default email setting
 * @returns - [emailSetting]
 */
export async function getEmailSettings() {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/settings/super-admin-setting`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

/**
 * This function fetches all the available scopes
 * @returns [string]
 */

export async function getAvailabeScopes() {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/settings/super-admin-setting/available-scopes`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

/**
 * This function fetches all the available email variables
 * @returns [string]
 */
export async function getEmailVariables() {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/settings/super-admin-setting/available-email-variables`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

/**
 * This function updates particular email setting
 * @param dataId - Id of setting which is to be edited
 * @param bodyData - Data which is to be updated
 * @returns - [emailSetting]
 */
export async function editEmailSettings(data: any) {
  const token: string = getToken();
  const { id, ...rest } = data;
  const response = await fetch(
    config.mainApiEndpoint + `/settings/super-admin-setting/${id}`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(rest),
    }
  );
  errorHandler(response);
  return response.json();
}

/**
 * This function creates email setting
 * @param data - Setting data which is to be created
 * @returns - emailSetting
 */
export async function createEmailSetting(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/settings/super-admin-setting`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
}
