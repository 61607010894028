import { useNavigate } from "react-router-dom";

import { Button } from "components";

import Info from "assets/images/info.svg";

export const organizationTableColumns = [
  {
    Header: "Domain",
    accessor: "domain",
  },
  {
    Header: "Total Learner",
    accessor: "total_learners",
  },
  {
    Header: "Current Plan",
    accessor: "plan_id",
    Cell: ({ value }) => {
      return <span>{value?.title}</span>;
    },
  },
  {
    Header: "Interval",

    Cell: ({
      row: {
        original: { plan_id },
      },
    }) => {
      return <span>{plan_id && plan_id.intervalType}</span>;
    },
  },
  {
    Header: "Subscription Status",
    accessor: "subscription_status",
    Cell: ({ value }) => {
      return <span style={{ textTransform: "capitalize" }}>{value}</span>;
    },
  },
  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      const navigate = useNavigate();
      return (
        <Button
          variant="leading-icon"
          className="leading-icon"
          onClick={() =>
            navigate(`/organization/${original.domain}`, {
              state: {
                data: original,
              },
            })
          }
        >
          <img src={Info} alt="view detail" />
          Details
        </Button>
      );
    },
  },
];
