import React from "react";

import { Button } from "components";
import { CustomModal, ModalContents, ModalOpenButton } from "components";
import AskPasswordForm from "components/askPasswordForm";

import Delete from "assets/images/delete.svg";

const GamificationActivityTableDeleteAction = ({ rowData }: any) => {
  return (
    <CustomModal className="w-80vw">
      <ModalOpenButton>
        <Button
          type="button"
          className="leading-icon"
          variant="leading-icon"
          style={{
            marginRight: "10px",
          }}
        >
          <img src={Delete} alt="delete" />
          Delete
        </Button>
      </ModalOpenButton>
      <ModalContents title="Enter Password To Delete">
        <AskPasswordForm
          actionType="deleteGamificationActivity"
          data={{ id: rowData._id }}
        />
      </ModalContents>
    </CustomModal>
  );
};

export default GamificationActivityTableDeleteAction;
