import React from "react";

import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Input,
  Col,
  Row,
  Form,
  Button,
  SecondaryHeader,
  SecondaryHeaderInfo,
} from "components";

import { addKey, updateKey } from "api";

import { useActionOnData } from "hooks";

const validationSchema = Yup.object().shape({
  keyName: Yup.string().required("Key name is required"),
  description: Yup.string(),
});

const AddKey = () => {
  const navigate = useNavigate();
  const { state }: any = useLocation();

  const { mutateAsync: createKey } = useActionOnData({
    actionFunction: addKey,
    navigateTo: "keyList",
    queryToBeInvalidated: "keyList",
  });

  const { mutateAsync: modifyKey } = useActionOnData({
    actionFunction: updateKey,
    navigateTo: "keyList",
    queryToBeInvalidated: "keyList",
  });

  const handleSubmit = (data, formik) => {
    formik.setSubmitting(true);

    if (state) {
      modifyKey({
        id: state?._id,
        keyName: data?.keyName,
        description: data?.description,
      }).then(() => {
        formik.setSubmitting(false);
        formik.resetForm();
      });
    } else {
      createKey({
        keyName: data?.keyName,
        description: data?.description,
      }).then(() => {
        formik.setSubmitting(false);
        formik.resetForm();
      });
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <Formik
          initialValues={{
            keyName: state ? state?.keyName : "",
            description: state ? state?.description : "",
          }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => {
            const { isSubmitting, isValid, dirty } = formik;

            return (
              <Form as={FormikForm}>
                <SecondaryHeader>
                  <div className="secondary-header-wrap">
                    <BsFillArrowLeftCircleFill
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(-1)}
                    />
                    <SecondaryHeaderInfo
                      SecondaryHeaderTitle={state ? "Update Key" : "Add Key"}
                      SecondaryHeaderText=""
                      SecondaryText
                    />
                  </div>

                  <div className="button-wrap">
                    <Button
                      variant="secondary"
                      type="submit"
                      disabled={!(isValid && dirty) || isSubmitting}
                    >
                      {!state ? "Create key" : "Update key"}
                    </Button>
                  </div>
                </SecondaryHeader>

                <Row className="m-4 px-0 py-2">
                  <Col xl={4}>
                    <Input
                      name="keyName"
                      label="Enter key name"
                      placeholder="Enter key name"
                    />
                    <Input
                      name="description"
                      label="description"
                      placeholder="description"
                    />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddKey;
