import OrgLabelTableActions from "../orgLabelTableActions";

//predefined columns for table
export const orgLabelTableColumns = [
  {
    Header: "Key",
    accessor: "key",
  },
  {
    Header: "Label",
    accessor: "label",
  },
  {
    Header: "Placeholder",
    accessor: "placeholder",
  },

  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      return <OrgLabelTableActions rowData={original} />;
    },
  },
];
