import React, { FC } from "react";

import ReactSelect from "react-select";

import { Control, DatePicker, getCustomStyles, ShowFilter } from "components";

import { success } from "utils";

interface IPurchaseHistoryFilterProps {
  dateRange: any;
  isLoading: boolean;
  paymentMethod: any;
  paymentStatus: any;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  setDateRange: React.Dispatch<React.SetStateAction<any>>;
  setPaymentMethod: React.Dispatch<React.SetStateAction<any>>;
  setPaymentStatus: React.Dispatch<React.SetStateAction<any>>;
}

const PurchaseHistoryFilter: FC<IPurchaseHistoryFilterProps> = ({
  dateRange,
  isLoading,
  setFilters,
  setDateRange,
  paymentMethod,
  paymentStatus,
  setPaymentMethod,
  setPaymentStatus,
}) => {
  const [startDate, endDate] = dateRange;

  return (
    <ShowFilter
      triggerTitle="Show Filters"
      isLoading={isLoading}
      data-testid="filter-form"
      handleApply={() => {
        setFilters({
          payment_method: paymentMethod ? paymentMethod.value : undefined,
          datePicked: [
            dateRange[0]
              ? new Date(dateRange[0]).toISOString().slice(0, 10)
              : null,
            dateRange[1]
              ? new Date(dateRange[1]).toISOString().slice(0, 10)
              : null,
          ],
          payment_status: paymentStatus ? paymentStatus.value : undefined,
        });
        success("Filter applied successfully!!");
        return "success";
      }}
    >
      <div className="d-flex flex-column gap-3">
        <div>
          <label htmlFor="payment-method-select">Choose payment method:</label>
          <ReactSelect
            options={[
              {
                label: "Stripe",
                value: "stripe",
              },
              {
                label: "Paypal",
                value: "paypal",
              },
              {
                label: "Razorpay",
                value: "razorpay",
              },
            ]}
            key="org-filter"
            components={{
              Control: (props) => (
                <Control {...props} placeholder="Select Filter Options" />
              ),
            }}
            isMulti={false}
            value={paymentMethod}
            name="paymentMethod"
            isClearable={true}
            styles={getCustomStyles({ error: null, touched: null })}
            onChange={(property: any) => setPaymentMethod(property)}
          />
        </div>

        <div>
          <label htmlFor="payment-status-select">Choose payment status:</label>
          <ReactSelect
            options={[
              {
                label: "Paid",
                value: "paid",
              },
              {
                label: "Unpaid",
                value: "unpaid",
              },
            ]}
            key="org-filter-by-party"
            components={{
              Control: (props) => (
                <Control {...props} placeholder="Select Filter Options" />
              ),
            }}
            isMulti={false}
            value={paymentStatus}
            name="paymentStatus"
            isClearable={true}
            styles={getCustomStyles({ error: null, touched: null })}
            onChange={(property: any) => setPaymentStatus(property)}
          />
        </div>
        <div>
          <label htmlFor="date-picked">Date Range:</label>
          <DatePicker
            key="date-picker"
            endDate={endDate}
            startDate={startDate}
            setDateRange={setDateRange}
          />
        </div>
      </div>
    </ShowFilter>
  );
};

export default PurchaseHistoryFilter;
