import LabelPageTableActions from "./labelPageTableActions";

//predefined columns for table
export const labelPageTableColumns = [
  {
    Header: "Name",
    accessor: "labelPage",
  },
  {
    Header: "Created Date",
    Cell: ({ row: { original } }) => {
      return <span>{new Date(original?.createdAt).toDateString()}</span>;
    },
  },
  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      return <LabelPageTableActions rowData={original} />;
    },
  },
];
