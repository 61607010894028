import { FC } from "react";

import {
  Table,
  Badge,
  Button,
  CustomModal,
  ModalContents,
  ModalOpenButton,
} from "components";

import Edit from "assets/images/edit.svg";
import Delete from "assets/images/delete.svg";

import VideoForm, { roles } from "./videoForm";

interface IVideoList {
  videoList: any;
  updateVideo: any;
  deleteVideo: any;
}
const getColumns = ({ updateVideo, deleteVideo }) => [
  {
    Header: "Title",
    accessor: "title",
    width: 450,
  },
  {
    Header: "Video Link",
    accessor: "videoLink",
    width: 300,
  },
  {
    Header: "Role",
    width: 100,
    Cell: ({ row: { index, original } }) => {
      return (
        <div className="d-flex gap-2" key={index}>
          {original?.type?.map((item) => (
            <Badge key={item} className="badge dark">
              {roles?.find((role) => role?.value === item)?.label}
            </Badge>
          ))}
        </div>
      );
    },
  },
  {
    Header: "Action",
    Cell: ({ row: { index, original } }) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <CustomModal>
            <ModalOpenButton>
              <Button
                variant="leading-icon"
                className="leading-icon"
                type="button"
              >
                <img src={Edit} alt="edit" />
                Edit
              </Button>
            </ModalOpenButton>
            <ModalContents title="Add Video">
              <VideoForm
                video={original}
                updateVideo={updateVideo}
                isEdit={true}
                index={index}
              />
            </ModalContents>
          </CustomModal>

          <Button
            variant="leading-icon"
            className="leading-icon"
            type="button"
            onClick={() =>
              deleteVideo(original._id !== undefined ? original._id : index)
            }
          >
            <img src={Delete} alt="delete" />
            Delete
          </Button>
        </div>
      );
    },
  },
];

const VideoList: FC<IVideoList> = ({ videoList, updateVideo, deleteVideo }) => {
  const tableData = videoList?.sort((a, b) => a.position - b.position);
  return (
    <Table
      columnData={getColumns({ updateVideo, deleteVideo })}
      tableData={tableData ? tableData : []}
    />
  );
};

export default VideoList;
