import config from "../configs";
import { getToken } from "../utils/token";
import { errorHandler } from "./errorHandler";

export async function getVideoList(page = 0, perPage = 10, search = "") {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/list-vle-videos?page=${page}&perPage=${perPage}&delay=1&search=${search}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function getOrgVideoList(domain: any, page = 1, limit = 10) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/asset-settings/${domain}?page=${page}&&limit=${limit}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function getDefaultVideoList() {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/video-lists`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

/**
 *
 * @param data
 * @returns
 */
export async function saveOrgVideo(data: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + "/asset", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
}

export async function saveVideoList(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + "/vle-video/create-video-list",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function updateOrgVideo(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/asset-section/${data?.sectionId}`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        name: data?.name,
        description: data?.description,
        domain: data?.domain,
        isActive: data?.isActive,
        role: data?.role,
      }),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function deleteOrgVideo(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/asset-section/${data?.sectionId}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function getVleVideo(vleVideoId: string) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + "/vle-videos/" + vleVideoId,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function getOrgVideo(sectionId: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + "/asset-setting/" + sectionId,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function deleteVleVideo(id: string) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + "/vle-video/" + id, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}
/**
 *
 * @param data
 * @returns
 */
export async function updateVideo(data: any, id: string) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + "/vle-video/" + id, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
}

export async function createAsset(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + "/asset/" + data?.sectionId,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        title: data?.title,
        description: data?.description,
        type: data?.type,
        link: data?.link,
        isActive: data?.isActive,
        role: data?.role,
      }),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function updateAsset(data: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + "/asset", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      title: data?.title,
      description: data?.description,
      type: data?.type,
      link: data?.link,
      isActive: data?.isActive,
      sectionId: data?.sectionId,
      assetId: data?.assetId,
      role: data?.role,
    }),
  });
  errorHandler(response);
  return response.json();
}

export async function deleteAsset(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/asset/${data?.sectionId}/${data?.assetId}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}
