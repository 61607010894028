import { toast } from "react-toastify";

export function success(message: string) {
  toast.success(message, {
    autoClose: 5000,
    draggable: true,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    position: "top-right",
    hideProgressBar: false,
  });
}
export function error(message: string) {
  toast.error(message, {
    autoClose: 5000,
    draggable: true,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    position: "top-right",
    hideProgressBar: false,
  });
}
