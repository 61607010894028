import React from "react";

import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import {
  Button,
  CustomModal,
  ModalContents,
  SecondaryHeader,
  AskPasswordForm,
  ModalOpenButton,
  SecondaryHeaderInfo,
} from "components";

import { getPlanList } from "api";

import ChangePlan from "../changePlan";

const OrganizationDetailHeader = ({ data }: any) => {
  const planList = useQuery("planlist", () => getPlanList(1, 100));
  const navigate = useNavigate();

  return (
    <SecondaryHeader>
      <div className="secondary-header-wrap">
        <BsFillArrowLeftCircleFill
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <SecondaryHeaderInfo
          SecondaryText
          SecondaryHeaderText={""}
          SecondaryHeaderTitle={data?.domain}
        />
      </div>

      <div className="d-flex gap-2 flex-wrap">
        {data?.plan_id ? (
          <CustomModal className="w-auto" size="lg">
            <ModalOpenButton>
              <Button variant="secondary">Change Plan</Button>
            </ModalOpenButton>
            <ModalContents title="Change Plan">
              <ChangePlan
                org={data}
                perPage={10}
                planList={planList}
                currentPage={1}
              />
            </ModalContents>
          </CustomModal>
        ) : null}

        <CustomModal className="w-50vw">
          <ModalOpenButton>
            <Button
              variant="secondary"
              className={` ${data?.tenant_id ? "" : "d-none"}`}
            >
              Remove SSO configuration
            </Button>
          </ModalOpenButton>
          <ModalContents title="Remove SSO Configuration">
            <AskPasswordForm
              actionType="removeSSO"
              organizationId={data?._id}
            />
          </ModalContents>
        </CustomModal>
        <Button
          variant="secondary"
          style={{ display: data?.enable_gamification ? "block" : "none" }}
          onClick={() =>
            navigate("/organization/gamification", {
              state: data,
            })
          }
        >
          Gamification Setting
        </Button>
        <Button
          variant="secondary"
          onClick={() =>
            navigate(`/organization/change-organization-setting/${data?._id}`, {
              state: data,
            })
          }
        >
          Organization Setting
        </Button>
      </div>
    </SecondaryHeader>
  );
};

export default OrganizationDetailHeader;
