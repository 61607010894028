import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Login,
  AddKey,
  KeyList,
  AddPlan,
  LogList,
  AddScope,
  AddVideo,
  PlanList,
  LogDetail,
  VideoList,
  LabelList,
  AssetList,
  AssetForm,
  PageKeyList,
  EmailSetting,
  AddLabelPage,
  OrgLabelList,
  PageNotFound,
  AddOrgSection,
  AddThirdParty,
  LabelPageList,
  ThirdPartyList,
  PurchaseHistory,
  OrganizationList,
  OrganizationDetail,
  ApplicationSetting,
  CourseProviderList,
  OrganizationSetting,
  GamificationActivities,
  PendingUserInvitationDetail,
  SinglePurchaseHistoryDetail,
  EditOrganizationEmailSetting,
  GamificationSetting,
} from "screens";
import { AddFromDefault } from "screens/organization/addOrgSection";

import { ProtectedRoute } from "components";

import { AuthProvider } from "context/authContext";

function FullPageErrorFallback({ error }: any) {
  return (
    <div
      role="alert"
      style={{
        color: "red",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>Uh oh... There is a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  );
}

function App() {
  return (
    <ErrorBoundary FallbackComponent={FullPageErrorFallback}>
      <Router>
        <AuthProvider>
          <ToastContainer />

          <Routes>
            <Route path="/login" element={<Login />} />

            <Route
              path="/planList"
              element={
                <ProtectedRoute>
                  <PlanList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/plan/addPlan"
              element={
                <ProtectedRoute>
                  <AddPlan />
                </ProtectedRoute>
              }
            />
            <Route
              path="/plan/edit/:planId"
              element={
                <ProtectedRoute>
                  <AddPlan />
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <OrganizationList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/organization/:organizationId"
              element={
                <ProtectedRoute>
                  <OrganizationDetail />
                </ProtectedRoute>
              }
            />

            <Route
              path="/organization/editEmailTemplate/:organizationId/:templateId"
              element={
                <ProtectedRoute>
                  <EditOrganizationEmailSetting />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pendingUserInvitation/:id"
              element={
                <ProtectedRoute>
                  <PendingUserInvitationDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/videoList"
              element={
                <ProtectedRoute>
                  <VideoList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/video/addVideo"
              element={
                <ProtectedRoute>
                  <AddVideo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/video/edit/:vleVideoId"
              element={
                <ProtectedRoute>
                  <AddVideo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/thirdPartyList"
              element={
                <ProtectedRoute>
                  <ThirdPartyList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/thirdParty/addThirdParty"
              element={
                <ProtectedRoute>
                  <AddThirdParty />
                </ProtectedRoute>
              }
            />
            <Route
              path="/logList"
              element={
                <ProtectedRoute>
                  <LogList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/log/:logId"
              element={
                <ProtectedRoute>
                  <LogDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/courseProviderList"
              element={
                <ProtectedRoute>
                  <CourseProviderList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/organization/section/assetList/:sectionId"
              element={
                <ProtectedRoute>
                  <AssetList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/organization/section/addAsset/:sectionId"
              element={
                <ProtectedRoute>
                  <AssetForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/organization/section/editAsset/:sectionId"
              element={
                <ProtectedRoute>
                  <AssetForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/organization/addSection/:domain"
              element={
                <ProtectedRoute>
                  <AddOrgSection />
                </ProtectedRoute>
              }
            />
            <Route
              path="/organization/addSectionFromDefault/:domain"
              element={
                <ProtectedRoute>
                  <AddFromDefault />
                </ProtectedRoute>
              }
            />
            <Route
              path="/organization/editSection/:sectionId"
              element={
                <ProtectedRoute>
                  <AddOrgSection />
                </ProtectedRoute>
              }
            />

            <Route
              path="/settings/email"
              element={
                <ProtectedRoute>
                  <EmailSetting />
                </ProtectedRoute>
              }
            />

            <Route
              path="/settings/application"
              element={
                <ProtectedRoute>
                  <ApplicationSetting />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/email/addScope"
              element={
                <ProtectedRoute>
                  <AddScope />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/editEmailSetting/:emailSettingId"
              element={
                <ProtectedRoute>
                  <AddScope />
                </ProtectedRoute>
              }
            />
            <Route
              path="/keyList"
              element={
                <ProtectedRoute>
                  <KeyList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/addLabelKey"
              element={
                <ProtectedRoute>
                  <AddKey />
                </ProtectedRoute>
              }
            />
            <Route
              path="/editLabelKey/:labelKeyId"
              element={
                <ProtectedRoute>
                  <AddKey />
                </ProtectedRoute>
              }
            />
            <Route
              path="/labelPageList"
              element={
                <ProtectedRoute>
                  <LabelPageList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/labelList/:labelPageId"
              element={
                <ProtectedRoute>
                  <LabelList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/keyList/:labelPageId"
              element={
                <ProtectedRoute>
                  <PageKeyList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orgLabelList/:labelPageId"
              element={
                <ProtectedRoute>
                  <OrgLabelList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/addLabelPage"
              element={
                <ProtectedRoute>
                  <AddLabelPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/editLabelPage/:id"
              element={
                <ProtectedRoute>
                  <AddLabelPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/organization/change-organization-setting/:id"
              element={
                <ProtectedRoute>
                  <OrganizationSetting />
                </ProtectedRoute>
              }
            />
            <Route
              path="/purchaseHistory"
              element={
                <ProtectedRoute>
                  <PurchaseHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/purchaseHistory/:id"
              element={
                <ProtectedRoute>
                  <SinglePurchaseHistoryDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/gamification"
              element={
                <ProtectedRoute>
                  <GamificationActivities />
                </ProtectedRoute>
              }
            />
            <Route
              path="/organization/gamification"
              element={
                <ProtectedRoute>
                  <GamificationSetting />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
