import * as React from "react";

import { AuthProvider } from "./authContext";
import { SelectedItemForEditProvider } from "./selectedItemForEdit.context";

function AppProviders({ children }: any) {
  return (
    <AuthProvider>
      <SelectedItemForEditProvider>{children}</SelectedItemForEditProvider>
    </AuthProvider>
  );
}

export default AppProviders;
