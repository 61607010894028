import { useState, useRef } from "react";

import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import useOutsideAlerter from "../../hooks/useOutSideAlerter";

function NavUserProfileDropDown() {
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const [dropDownToggle, setDropDownToggle] = useState(false);

  useOutsideAlerter(wrapperRef, setDropDownToggle);

  const onDropDownClick = (toggle, e) => {
    if (e.target.classList.contains("user-profile")) {
      return setDropDownToggle((dropToggle) => !dropToggle);
    }
    setDropDownToggle(toggle);
  };

  const onLogoutClick = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
  };

  let dropDownClasses = "dropdown-menu mw-dropdown-default dropdown-menu-end";
  if (dropDownToggle) {
    dropDownClasses += " show";
  }

  return (
    <div
      ref={wrapperRef}
      className="dropdown dropdown-select"
      onClick={onDropDownClick.bind(null, true)}
    >
      <div
        data-testid="profileicon"
        className="circle-wrap circle-wrap-md bg-circle-twilight user-profile text-white"
        data-bs-toggle="dropdown"
        data-bs-offset="0,7"
        aria-expanded={dropDownToggle}
      >
        A
      </div>
      <ul
        data-testid="profiledropdown"
        className={dropDownClasses}
        aria-labelledby="dropdownMenuButton1"
        data-bs-popper="none"
      >
        <li>
          <a className="dropdown-item" href="/login" onClick={onLogoutClick}>
            <MdLogout />
            Log out
          </a>
        </li>
      </ul>
    </div>
  );
}

export default NavUserProfileDropDown;
