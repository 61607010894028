import React from "react";
import { useEffect, useState } from "react";

import Col from "react-myoneui/lib/Col";
import Row from "react-myoneui/lib/Row";
import Form from "react-myoneui/lib/Form";
import Button from "react-myoneui/lib/Button";
import { Form as FormikForm, Formik } from "formik";
import { currencyTypes, intervalTypes } from "constant";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import SecondaryHeader from "react-myoneui/lib/SecondaryHeader";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SecondaryHeaderInfo from "react-myoneui/lib/SecondaryHeaderInfo";

import {
  Input,
  Select,
  CheckBox,
  CustomModal,
  ModalContents,
  ModalOpenButton,
} from "components";

import { getPlan, savePlan } from "api";

import { PlanMembershipContext } from "context";

import { error, success } from "utils";

import PlanMemberShipForm from "./planMemberShipForm";
import PlanMembershipList from "./planMembershipList";
import { validationSchema } from "./validationSchema";

const AddPlan = () => {
  const navigate = useNavigate();

  const { state }: any = useLocation();

  const params = useParams();

  const [minMember, setMinMember] = useState(0);

  const [plan, setPlan] = useState<any>(null);

  const [isEdit] = useState(params && params.planId ? true : false);

  const [updatePrice, setUpdatePrice] = useState(!isEdit);

  const queryClient = useQueryClient();

  const [isDirty, setIsDirty] = useState(false);

  const [memberships, setMemberships] = useState<any>([]);
  const [planMembership, setPlanMembership] = useState<any>({});

  const { data } = useQuery<any>(
    ["plan", isEdit ? params.planId : null],
    () => getPlan(params.planId),
    {
      enabled: isEdit,
    }
  );

  const handleSubmit = (data, formik) => {
    formik.setSubmitting(true);
    if (memberships.length > 0) {
      data.memberships = memberships;
      data.updatePrice = updatePrice;
      if (isEdit) {
        data._id = params.planId;
      }
      saveMutation.mutateAsync(data).then(() => formik.setSubmitting(false));
    } else {
      error("Plan Membership required.");
    }
  };

  const saveMutation = useMutation(savePlan, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("planList");
        if (isEdit && params.planId) {
          queryClient.invalidateQueries([
            "planList",
            isEdit ? params.planId : null,
          ]);
        }
        navigate("/");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  //add from context
  useEffect(() => {
    if (planMembership.name) {
      const array = [...memberships, planMembership];
      setMemberships(array);
      setUpdatePrice(true);
      setPlanMembership({});
    }
  }, [planMembership, memberships]);

  useEffect(() => {
    if (state && state?._id) {
      setPlan(state);
      setMemberships(state?.membershipPlans);
    }
  }, [state]);

  useEffect(() => {
    if (data && data.data) {
      setPlan(data.data);
      setMemberships(data.data.membershipPlans);
    }
  }, [data]);

  const updatePlanMember = (id: any, data: any) => {
    let index = -1;

    if (typeof id === "string") {
      index = memberships.findIndex((x) => x._id === id);
    } else {
      index = id;
    }

    if (index > -1) {
      let updateItem = memberships[index];
      if (
        updateItem.price !== data.price ||
        updateItem.min_members !== data.min_members ||
        updateItem.max_members !== data.max_members
      ) {
        setUpdatePrice(true);
      }
      updateItem = {
        ...data,
      };

      if (typeof id === "string") {
        updateItem._id = id;
      }

      setMemberships([
        ...memberships.slice(0, index),
        updateItem,
        ...memberships.slice(index + 1),
      ]);
    }
  };

  const deletePlanMember = (id: any) => {
    let index = -1;

    if (typeof id === "string") {
      index = memberships.findIndex((x) => x._id === id);
    } else {
      index = id;
    }

    if (index > -1) {
      // assigning the list to temp variable
      const temp = [...memberships];

      // removing the element using splice
      temp.splice(index, 1);

      setUpdatePrice(true);
      setMemberships(temp);
    }
  };

  useEffect(() => {
    if (memberships.length > 0) {
      setMinMember(
        memberships.sort((a, b) => a.min_members - b.min_members)[
          memberships.length - 1
        ] !== undefined
          ? memberships.sort((a, b) => a.min_members - b.min_members)[
              memberships.length - 1
            ].max_members + 1
          : 0
      );
    }
  }, [memberships]);

  return (
    <>
      <div className="content-wrapper">
        <Formik
          initialValues={{
            title: plan && plan.title ? plan.title : "",
            description: plan && plan.description ? plan.description : "",
            enterprise_contact_email:
              plan && plan.enterprise_contact_email
                ? plan.enterprise_contact_email
                : "hello@olivegroup.io",
            is_active: plan && plan.is_active ? plan.is_active === 1 : false,
            is_default: plan && plan.is_default ? plan.is_default === 1 : false,
            is_visible: plan && plan.is_visible ? plan.is_visible === 1 : false,
            intervalType: plan && plan.intervalType ? plan.intervalType : "",
            currency: plan && plan.currency ? plan.currency : "",
          }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => {
            const { isSubmitting, isValid, dirty, values } = formik;

            return (
              <Form as={FormikForm}>
                <SecondaryHeader>
                  <div className="secondary-header-wrap">
                    <BsFillArrowLeftCircleFill
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(-1)}
                    />
                    <SecondaryHeaderInfo
                      SecondaryHeaderTitle="Add Plan"
                      SecondaryHeaderText=""
                      SecondaryText
                    />
                  </div>

                  <div className="button-wrap">
                    <Button
                      variant="secondary"
                      type="submit"
                      disabled={
                        !(isValid && (dirty || isDirty)) || isSubmitting
                      }
                    >
                      {isEdit
                        ? isSubmitting
                          ? "Updating Plan"
                          : "Update Plan"
                        : isSubmitting
                        ? "Creating Plan"
                        : "Create Plan"}
                    </Button>
                  </div>
                </SecondaryHeader>

                <Row className="m-4 px-0 py-2">
                  <Col xl={4}>
                    <Input name="title" label="title" placeholder="title" />
                    <Input
                      name="description"
                      label="Description"
                      placeholder="Description"
                    />

                    <Input
                      name="enterprise_contact_email"
                      label="Email"
                      placeholder="Email"
                    />
                    <div className="row mb-28">
                      <Select
                        placeholder="Select Interval Type"
                        name="intervalType"
                        defaultValue={
                          values.intervalType
                            ? {
                                label: values?.intervalType,
                                value: values?.intervalType,
                              }
                            : null
                        }
                        options={intervalTypes}
                      />

                      <Select
                        name="currency"
                        defaultValue={
                          values.currency
                            ? {
                                label: values?.currency,
                                value: values?.currency,
                              }
                            : null
                        }
                        options={currencyTypes}
                        placeholder="Select Currency Type"
                      />
                    </div>
                    <Row>
                      <Col>
                        <CheckBox
                          name="is_active"
                          label="Active"
                          defaultChecked={values.is_active}
                        />
                      </Col>
                      <Col>
                        <CheckBox
                          name="is_default"
                          label="Default"
                          defaultChecked={values.is_default}
                        />
                      </Col>
                      <Col>
                        <CheckBox
                          name="is_visible"
                          defaultChecked={values.is_visible}
                          label="Visible"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={8}>
                    <PlanMembershipContext.Provider
                      value={[planMembership, setPlanMembership, setIsDirty]}
                    >
                      <CustomModal>
                        <ModalOpenButton>
                          <Button variant="secondary">
                            Add Plan MemberShip
                          </Button>
                        </ModalOpenButton>
                        <ModalContents title="Add MemberShip Plan">
                          <PlanMemberShipForm min={minMember} />
                        </ModalContents>
                      </CustomModal>

                      <PlanMembershipList
                        memberships={memberships}
                        updatePlanMember={updatePlanMember}
                        deletePlanMember={deletePlanMember}
                        minMember={minMember}
                      />
                    </PlanMembershipContext.Provider>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddPlan;
