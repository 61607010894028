import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { Form as FormikForm, Formik } from "formik";

import {
  Button,
  Form,
  Offcanvas,
  Input,
  CheckBox,
  useDrawerContext,
  OffcanvasContents,
  OffCanvasDismissButton,
} from "components";

import { updateOrganizationPaymentSetting } from "api";

import { useSelectedItemForEditContext } from "context";

import { error, success } from "utils";

const validationSchema = Yup.object().shape({
  isDefault: Yup.boolean(),
  publishableKey: Yup.string().required("Required"),
  secretKey: Yup.string().required("Required"),
});

const PaymentSettingForm = () => {
  const [, setIsOpen] = useDrawerContext();
  const { selectedItemForEdit, setSelectedItemForEdit } =
    useSelectedItemForEditContext();
  const queryClient = useQueryClient();

  const updateMutation = useMutation(updateOrganizationPaymentSetting, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("getSingleOrg");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const handleSubmit = (data: any, setSubmitting, resetForm) => {
    if (selectedItemForEdit) {
      updateMutation
        .mutateAsync({
          name: selectedItemForEdit?.name,
          publishableKey: data?.publishableKey,
          secretKey: data?.secretKey,
          isDefault: data?.isDefault,
          domain: selectedItemForEdit?.domain,
          account_id: data?.accountId,
        })
        .then(() => {
          setSubmitting(false);
          setIsOpen(false);
          setSelectedItemForEdit(null);
          resetForm();
        });
    }
  };

  return (
    <Formik
      initialValues={{
        isDefault: selectedItemForEdit ? selectedItemForEdit.isDefault : false,
        publishableKey: selectedItemForEdit
          ? selectedItemForEdit.publishableKey
          : "",
        secretKey: selectedItemForEdit ? selectedItemForEdit.secretKey : "",
        accountId: selectedItemForEdit ? selectedItemForEdit.account_id : "",
      }}
      onSubmit={() => console.log("hello")}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(formik) => {
        const {
          values,
          isSubmitting,
          isValid,
          dirty,
          setSubmitting,
          resetForm,
        } = formik;

        return (
          <Form as={FormikForm}>
            <OffcanvasContents title="Update payment setting">
              <Offcanvas.Body>
                <Input
                  type="text"
                  label="accountId"
                  placeholder="accountId"
                  name="accountId"
                />
                <Input
                  type="text"
                  label="publishableKey"
                  placeholder="publishableKey"
                  name="publishableKey"
                />
                <Input
                  type="text"
                  label="secretKey"
                  placeholder="secretKey"
                  name="secretKey"
                />

                <CheckBox
                  name="isDefault"
                  label="Default"
                  disabled={values?.isDefault}
                  defaultChecked={values?.isDefault}
                />
              </Offcanvas.Body>
              <Offcanvas.Footer>
                <OffCanvasDismissButton>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setSelectedItemForEdit(false)}
                  >
                    Cancel
                  </Button>
                </OffCanvasDismissButton>
                <Button
                  variant="secondary"
                  type="button"
                  className="ms-2"
                  onClick={() => handleSubmit(values, setSubmitting, resetForm)}
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  Update Payment Setting
                </Button>
              </Offcanvas.Footer>
            </OffcanvasContents>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PaymentSettingForm;
