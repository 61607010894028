import React, { FC } from "react";

import LabelPageTableViewKeys from "./labelPageViewKeys";
import LabelPageTableViewAction from "./labelPageViewAction";
import LabelPageActivateAction from "./labelPageActivateActions";
import LabelPageTableEditAction from "./labelPageTableEditAction";

interface IPlanTableActionsProps {
  rowData: any;
}

const LabelPageTableActions: FC<IPlanTableActionsProps> = ({ rowData }) => {
  return (
    <div className="d-flex align-items-center gap-3">
      <LabelPageTableViewAction
        rowData={rowData}
        key="labelPageViewEditAction"
      />
      <LabelPageTableViewKeys key="labelPageViewKeys" rowData={rowData} />
      <LabelPageTableEditAction
        rowData={rowData}
        key="labelPageTableEditAction"
      />
      <LabelPageActivateAction rowData={rowData} />

      {/* <CustomModal centered={false} backdrop={false}>
        <ModalOpenButton>
          <Button type="button" className="leading-icon" variant="leading-icon">
            <img src={Delete} alt="delete" />
            Delete
          </Button>
        </ModalOpenButton>
        <ModalContents title="Are you sure? You want to delete this label page?">
          <LabelPageTableDeleteAction data={rowData} />
        </ModalContents>
      </CustomModal> */}
    </div>
  );
};

export default LabelPageTableActions;
