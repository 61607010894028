/* eslint-disable react/jsx-key */
import { FC, useEffect, useMemo } from "react";
import * as React from "react";

import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import { usePagination, useSortBy, useTable } from "react-table";

import {
  Form,
  Spinner,
  Dropdown,
  PageCount,
  Pagination,
  PaginationWrapper,
} from "components";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
interface ITableProps {
  columnData: any;
  tableData: any;
  isLoading?: boolean;
  pageCount?: number;
  setPerPage?: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  currentPage?: number;
  pagination?: boolean;
  noDataText?: string;
  showPaginationInsideTable?: boolean;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const pageSizes = [10, 20, 50, 100];

function RowPerPageDropdown({ items = pageSizes, value, onChange }) {
  return (
    <Dropdown className="dropdown-select" onSelect={onChange}>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <p className="page-count">{value}</p>
        <div className="caret-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.119"
            height="11.658"
            viewBox="0 0 6.119 11.658"
          >
            <path
              id="Path_21616"
              data-name="Path 21616"
              d="M0,0,4.42,3.71,8.84,0"
              transform="translate(1.409 10.249) rotate(-90)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.map((item, i) => (
          <Dropdown.Item key={i} eventKey={item} active={value === item}>
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const Table: FC<ITableProps> = ({
  setPerPage,
  setCurrentPage,
  columnData,
  tableData,
  isLoading,
  pageCount: controlledPageCount,
  pagination = false,
  noDataText = "No Data",
  showPaginationInsideTable = false,
  currentPage,
}) => {
  const [columns, data] = useMemo(() => {
    const serialNumberColumn = {
      Header: "S.N",
      width: 50,
      Cell: ({ row: { index } }) => {
        const count = currentPage * pageSize - pageSize;
        return <span>{count ? index + count + 1 : index + 1}</span>;
      },
    };
    return [[serialNumberColumn, ...columnData], tableData];
  }, [tableData, columnData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage, pageSize: 10 },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
      disableResizing: true,
    },

    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (pageSize && pagination) {
      setPerPage(pageSize);
    }
  }, [pageSize, pagination, setPerPage]);

  return (
    <div className="table-wrap">
      <table
        {...getTableProps()}
        style={{
          height: !isLoading && page.length > 0 ? "100%" : "200px",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      style: {
                        width: column.width,
                      },
                    })
                  )}
                  className="text-truncate"
                  scope="col"
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TiArrowDown />
                      ) : (
                        <TiArrowUp />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {(page.length > 0 &&
            page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: { width: cell.column.width },
                        })}
                        className="text-truncate"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })) || (
            <div className="position-absolute start-50 translate-middle top-50">
              {isLoading ? (
                <Spinner animation="border" />
              ) : (
                <span className="text-truncate">{noDataText}</span>
              )}
            </div>
          )}
        </tbody>
      </table>
      {pagination && page.length > 0 ? (
        <div className={`${showPaginationInsideTable ? "" : "page-footer"}`}>
          <div
            className={` ${
              showPaginationInsideTable ? "invisible" : ""
            } row-page-wrap`}
          >
            <span>Rows per page</span>
            <RowPerPageDropdown
              items={[10, 20, 30, 40, 50]}
              value={pageSize}
              onChange={setPageSize}
            />
          </div>
          <PaginationWrapper>
            <Pagination>
              <Pagination.Prev
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1 ? true : false}
              />
              <Pagination.Item active>
                <Form.Control
                  type="text"
                  className="page-link text-center"
                  disabled={true}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  onClick={(event) => event.target.select()}
                  value={currentPage}
                  onChange={(event) =>
                    setCurrentPage(parseInt(event.target.value) * 1)
                  }
                />
              </Pagination.Item>
              <Pagination.Next
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage < pageCount ? false : true}
              />
            </Pagination>
            <PageCount pageTotal={pageCount} />
          </PaginationWrapper>
        </div>
      ) : null}
    </div>
  );
};
export default Table;
