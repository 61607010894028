import { FC } from "react";

import { useField } from "formik";
import { GroupBase } from "react-select";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";

import { Control, getCustomStyles } from "./select";

interface IAsyncPaginateSelectProps {
  name: string;
  placeholder?: string;
  isDisabled?: boolean;
  handleLoadOptions: LoadOptions<
    any,
    GroupBase<any>,
    {
      page: any;
    }
  >;
}

export const AsyncPaginateSelect: FC<IAsyncPaginateSelectProps> = ({
  name,
  isDisabled,
  placeholder,
  handleLoadOptions,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  const { setTouched, setValue } = helpers;
  const { touched, error, value } = meta;

  const handleBlur = () => {
    setTouched(true);
  };

  const handleChange = (property: any) => {
    setValue(property);
  };

  return (
    <div className="mb-4">
      <AsyncPaginate
        value={value}
        isDisabled={isDisabled}
        onChange={handleChange}
        onBlur={handleBlur}
        isClearable={true}
        styles={getCustomStyles({ error, touched })}
        components={{
          Control: (props) => <Control {...props} placeholder={placeholder} />,
        }}
        loadOptions={handleLoadOptions}
        additional={{
          page: 1,
        }}
        debounceTimeout={1500}
        key="async-paginate-select"
        loadOptionsOnMenuOpen={false}
      />
      {touched && error ? (
        <small className="text-danger ">{error}</small>
      ) : null}
    </div>
  );
};
