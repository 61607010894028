import React from "react";

import { Drawer, OffcanvasOpenButton, Button } from "components";

import { useSelectedItemForEditContext } from "context";

import Edit from "assets/images/edit.svg";

import AddCourseProvider from "../addCourseProvider/addCourseProvider";

const CourseProviderTableEditAction = ({ rowData }: any) => {
  const { isActive, isDefault } = rowData;
  const { setSelectedItemForEdit } = useSelectedItemForEditContext();

  return (
    <Drawer handleExit={() => setSelectedItemForEdit(null)}>
      <OffcanvasOpenButton>
        <Button
          type="button"
          variant="leading-icon"
          style={{
            marginRight: "10px",
          }}
          className={`${!isActive || isDefault ? "d-none" : " leading-icon"} `}
          onClick={() => setSelectedItemForEdit(rowData)}
        >
          <img src={Edit} alt="edit" />
          Edit
        </Button>
      </OffcanvasOpenButton>

      <AddCourseProvider />
    </Drawer>
  );
};

export default CourseProviderTableEditAction;
