import { Formik, Form as FormikForm, Field } from "formik";

import { Card, Form, Button, Spinner } from "components";

export const FormGroup = ({ label, fieldName }) => {
  return (
    <div className="d-flex  gap-2 mb-2" aria-labelledby={label}>
      <Field type="checkbox" name={fieldName} />

      <label>{label}</label>
    </div>
  );
};

const ApplicationSetting = ({ data, isLoading, mutateAsync }: any) => {
  const handleSubmit = (values, formik) => {
    formik.setSubmitting(true);

    mutateAsync({
      show_community_forum: values["changeCommunityForumStatus"],
      enable_gamification: values["changeGamificationStatus"],
      enable_loader: values["changeLoaderStatus"],
      enable_payment: values["changePaymentStatus"],
      enable_client_marketplace: values["changeClientMarketplaceVisibility"],
      show_bundle: values["changeBundleStatus"],
      id: data?.id,
    }).then(() => formik.setSubmitting(false));
  };
  return (
    <div className=" p-4">
      <Card>
        <Card.Body>
          {isLoading ? (
            <div
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <Formik
              initialValues={{
                changeCommunityForumStatus: data?.changeCommunityForumStatus,
                changeLoaderStatus: data?.changeLoaderStatus,
                changeClientMarketplaceVisibility:
                  data?.enableClientMarketplace,
                changeGamificationStatus: data?.changeGamificationStatus,
                changePaymentStatus: data?.changePaymentStatus,
                changeBundleStatus: data?.changeBundleStatus,
              }}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {(formik) => {
                const { isSubmitting, isValid, dirty } = formik;
                return (
                  <Form as={FormikForm} data-testid="app-setting-form">
                    <FormGroup
                      label="Enable Loader"
                      fieldName="changeLoaderStatus"
                    />
                    <FormGroup
                      label="Enable Client Marketplace"
                      fieldName="changeClientMarketplaceVisibility"
                    />
                    <FormGroup
                      label="Enable Gamification"
                      fieldName="changeGamificationStatus"
                    />
                    <FormGroup
                      label="Enable Payment"
                      fieldName="changePaymentStatus"
                    />
                    <FormGroup
                      label="Enable Bundle Library"
                      fieldName="changeBundleStatus"
                    />
                    <FormGroup
                      label="Enable Community Forum"
                      fieldName="changeCommunityForumStatus"
                    />
                    <Button
                      variant="dark"
                      type="submit"
                      className="mt-2"
                      disabled={!(isValid && dirty) || isSubmitting}
                    >
                      {isSubmitting ? "Updating" : "Update"}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ApplicationSetting;
