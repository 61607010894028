import React from "react";

export type IVideoListContext = [
  any,
  React.Dispatch<React.SetStateAction<any>>,
  React.Dispatch<React.SetStateAction<boolean>>
];

export const VideoListContext = React.createContext<IVideoListContext>([
  {},
  () => null,
  () => null,
]);
