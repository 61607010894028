import React, { FC } from "react";

import {
  CustomModal,
  ModalContents,
  ModalOpenButton,
  Button,
} from "components";

import Delete from "assets/images/delete.svg";

import PageKeyEditAction from "./pageKeyEditAction";
import PageKeyDeleteAction from "./pageKeyDeletAction";

interface IPlanTableActionsProps {
  rowData: any;
}

const PageKeyTableActions: FC<IPlanTableActionsProps> = ({ rowData }) => {
  return (
    <div className="d-flex align-items-center gap-3">
      <PageKeyEditAction rowData={rowData} key="labelTableEditAction" />

      <CustomModal centered={false} backdrop={false}>
        <ModalOpenButton>
          <Button type="button" className="leading-icon" variant="leading-icon">
            <img src={Delete} alt="delete" />
            Delete
          </Button>
        </ModalOpenButton>
        <ModalContents title="Are you sure? You want to delete this key?">
          <PageKeyDeleteAction data={rowData} />
        </ModalContents>
      </CustomModal>
    </div>
  );
};

export default PageKeyTableActions;
