import React, { FC } from "react";

import { Card, Table } from "components";

interface IOrganizationDetailComponentTableProps {
  data: any;
  columns: any;
  title: string;
  noDataText: string;
}

const InfoTable: FC<IOrganizationDetailComponentTableProps> = ({
  data,
  title,
  columns,
  noDataText,
}) => {
  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-end align-items-center">
        <strong
          style={{
            fontSize: "16px",
            flexGrow: "1",
            textAlign: "center",
          }}
        >
          {title}
        </strong>
      </Card.Header>
      <Card.Body>
        <Table tableData={data} columnData={columns} noDataText={noDataText} />
      </Card.Body>
    </Card>
  );
};

export default InfoTable;
