import config from "../configs";
import { getToken } from "../utils/token";
import { errorHandler } from "./errorHandler";

export async function getOrganizations(
  page = 0,
  perPage = 10,
  filterText = "",
  filters: {
    sso: undefined;
    datePicked: [null, null];
    isThirdpartyOrg: null;
  }
) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/getOrgList?page=${page}&perPage=${perPage}&delay=1&search=${filterText}&ssoConfigured=${filters["sso"]}&from=${filters["datePicked"][0]}&to=${filters["datePicked"][1]}&isThirdpartyOrg=${filters["isThirdpartyOrg"]}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function filterOrganization(page = 0, filterText = "") {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/getOrgList?page=${page}&delay=1&search=${filterText}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function getSingleOrganization(domain: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/get-single-org/${domain}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}
export async function getMykademyOrganizations(page = 0, filterText = "") {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/get-akademy-orgs?page_limit=10&page=${page}&status=1&search_keyword=${filterText}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function searchOrganization(filterText = "") {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/search-org?q=${filterText}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function assignedPlanToOrganization(data: any) {
  const response = await fetch(
    config.mainApiEndpoint + "/update-subscription",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token") as string,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
}

export const getOrganizationEmailSetting = async (organizationId: string) => {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/settings/super-admin-setting/email-setting/${organizationId}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
};

export const modifyOrganizationEmailSetting = async (
  organizationId: string,
  templateId: string,
  data: any
) => {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/settings/super-admin-setting/email-setting/${organizationId}/${templateId}`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
};

export const resetOrganizationEmailSetting = async (
  organizationId: string,
  templateId: string,
  data: any
) => {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/settings/super-admin-setting/email-setting/revert-to-default-template/${organizationId}/${templateId}`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
};

export async function getPendingUserInvitationList(
  page = 0,
  perPage = 5,
  organizationId
) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/superadmin/teachers/${organizationId}?page=${page}&perPage=${perPage}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export const removeSSO = async (organizationId: string, data: any) => {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/remove-sso-configuration/${organizationId}`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
};

export const modifyOrganizationSetting = async (data: any) => {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/update-org-flags`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
};

export const modifyOrganizationSupportEmail = async (data: any) => {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/update-emails`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
};

export const resetEmailSettingToOtherOrganization = async (data: any) => {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/setting/reset-email-setting`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
};

export const resetAsset = async (data: any) => {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/reset-asset`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
};

export const updateOrganizationPaymentSetting = async (data: any) => {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/update-org-payment-settings`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
};

export async function getRole(domain: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/get-role/${domain}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export const updateOrganizationRole = async (data: any) => {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/org-set-role`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
};
