import { ErrorMessage, useField } from "formik";

import { Form, FloatingLabel } from "components";

interface Props {
  label?: string;
  requiredLabel?: boolean;
  name: string;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  className?: string;
  marginBottom?: string;
}

export const Input: React.FC<Props> = ({
  label,
  marginBottom = "30px",

  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        marginBottom: marginBottom,
        height: "calc(2.875rem + 2px)",
      }}
    >
      <Form.Group>
        <FloatingLabel
          className="text-capitalize"
          controlId="floatingInput"
          label={label}
        >
          <Form.Control
            type={props.type}
            {...field}
            {...props}
            className={meta.error && meta.touched ? "border-danger" : ""}
          />
        </FloatingLabel>
      </Form.Group>

      <span className="position-absolute bottom-10 text-danger">
        <ErrorMessage
          name={props.name}
          render={(err) => <small>{err}</small>}
        />
      </span>
    </div>
  );
};
