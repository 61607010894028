import React, { FC } from "react";

import {
  Button,
  CustomModal,
  ModalContents,
  ModalOpenButton,
} from "components";

import Delete from "assets/images/delete.svg";

import AssetTableEditAction from "./assetTableEditAction";
import AssetTableDeleteAction from "./assetTableDeleteAction";

interface IPlanTableActionsProps {
  rowData: any;
}

const AssetTableActions: FC<IPlanTableActionsProps> = ({ rowData }) => {
  return (
    <div className="d-flex align-items-center gap-3">
      <AssetTableEditAction rowData={rowData} key="labelTableEditAction" />

      <CustomModal centered={false} backdrop={false}>
        <ModalOpenButton>
          <Button type="button" className="leading-icon" variant="leading-icon">
            <img src={Delete} alt="delete" />
            Delete
          </Button>
        </ModalOpenButton>
        <ModalContents title="Are you sure? You want to delete this asset?">
          <AssetTableDeleteAction data={rowData} />
        </ModalContents>
      </CustomModal>
    </div>
  );
};

export default AssetTableActions;
