import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import {
  Input,
  Button,
  Card,
  Col,
  Form,
  Row,
  SecondaryHeader,
  SecondaryHeaderInfo,
} from "components";

import { addLabelPage, updateLabelPage } from "api/labelPage.api";
import { uploadImage } from "api";

import { useActionOnData } from "hooks";

const validationSchema = Yup.object().shape({
  pageName: Yup.string().required("Page name is required"),
  image: Yup.mixed().required("Label page image is required!!"),
});

const AddLabelPage = () => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [isUploading, setIsUploading] = useState(false);
  const [image, setImage] = useState(
    "https://www.waterfieldtechnologies.com/wp-content/uploads/2019/02/placeholder-image-gray-3x2.png"
  );

  const { mutateAsync: createLabelPage } = useActionOnData({
    actionFunction: addLabelPage,
    queryToBeInvalidated: "labelPageList",
    navigateTo: "labelPageList",
  });

  const { mutateAsync: modifyLabelPage } = useActionOnData({
    actionFunction: updateLabelPage,
    queryToBeInvalidated: "labelPageList",
    navigateTo: "labelPageList",
  });

  const handleImageUpload = async (e, setFieldValue) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("client", "BT101");
    formData.append("bucket", "video-communication");
    await uploadImage(formData)
      .then((res) => {
        setFieldValue("image", res.data[0].file.Location);
        setImage(res.data[0].file.Location);
      })
      .finally(() => setIsUploading(false));
  };

  useEffect(() => {
    if (state) {
      setImage(state?.image);
    }
  }, []);

  const handleSubmit = (data, formik) => {
    formik.setSubmitting(true);

    if (state) {
      modifyLabelPage({
        id: state?._id,
        updatedData: {
          labelPage: data?.pageName,
          image: data?.image,
        },
      }).finally(() => formik.setSubmitting(false));
    } else {
      createLabelPage({
        labelPage: data?.pageName,
        image: data?.image,
      }).finally(() => formik.setSubmitting(false));
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <Formik
          initialValues={{
            pageName: state ? state?.labelPage : "",
            image: state ? state?.image : "",
          }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => {
            const { isSubmitting, isValid, dirty, errors, setFieldValue } =
              formik;

            return (
              <Form as={FormikForm}>
                <SecondaryHeader>
                  <div className="secondary-header-wrap">
                    <BsFillArrowLeftCircleFill
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(-1)}
                    />
                    <SecondaryHeaderInfo
                      SecondaryHeaderTitle={
                        state ? "Update Label Page" : "Add Label Page"
                      }
                      SecondaryHeaderText=""
                      SecondaryText
                    />
                  </div>

                  <div className="button-wrap">
                    <Button
                      variant="secondary"
                      type="submit"
                      disabled={!(isValid && dirty) || isSubmitting}
                    >
                      {!state ? "Create label page" : "Update label page"}
                    </Button>
                  </div>
                </SecondaryHeader>

                <Row className="mx-4 my-4">
                  <Col xl={4}>
                    <Input
                      marginBottom="30px"
                      name="pageName"
                      label="Enter page Name"
                      placeholder="Enter page name"
                    />
                    <Card className="border-0">
                      <Card.Body>
                        <Row className="align-items-center gap-2">
                          <Col xl={5}>
                            <Card.Img
                              src={image}
                              className="rounded w-100 course-image"
                              alt="Course Image"
                              width={200}
                              height="auto"
                            />
                          </Col>
                          <Col xl={5} className="card-info">
                            <Card.Title as="h5">
                              Upload Label Page Image
                            </Card.Title>
                            <p className="help-text">
                              Some quick example text to build on the card title
                              and make up the bulk of the card content.
                            </p>
                            <div>
                              <input
                                type="file"
                                hidden
                                id="actual-btn"
                                onChange={(e) =>
                                  handleImageUpload(e, setFieldValue)
                                }
                                name="image"
                                disabled={isUploading}
                              />
                              <label
                                htmlFor="actual-btn"
                                role="button"
                                className="border border-primary px-3 py-2 rounded-2 text-primary  fw-bolder fs-6 "
                              >
                                {isUploading ? "Uploading..." : "Upload Image"}
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    {typeof errors["image"] === "string" ? (
                      <span className="text-danger fs-6">{errors.image}</span>
                    ) : null}
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddLabelPage;
