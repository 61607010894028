import React, { FC } from "react";

import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { error, success } from "utils";

interface IActionOnDataProps {
  actionFunction: (data: any) => Promise<any>;
  navigateTo?: string;
  queryToBeInvalidated: string;
}

export const useActionOnData = ({
  actionFunction,
  navigateTo,
  queryToBeInvalidated,
}: IActionOnDataProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, mutateAsync, isLoading, ...rest } = useMutation(
    actionFunction,
    {
      onSuccess: (data: any) => {
        if (data.status === "success" && data?.code === 200) {
          success(data.message);

          queryClient.invalidateQueries(queryToBeInvalidated);
          if (navigateTo) {
            navigate(`/${navigateTo}`);
          }
        } else if (data?.status === "fail") {
          error(data?.message);
        }
      },
      onError: (data: any) => {
        error(data?.message);
      },
    }
  );
  return {
    mutate,
    mutateAsync,
    isLoading,
    ...rest,
  };
};
