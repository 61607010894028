import React, { useEffect, useState } from "react";

import { Card } from "components";

const EachDetail = ({ label, value }) => {
  return (
    <div>
      <strong>{label}</strong>
      <p>{value}</p>
    </div>
  );
};

const OrganizationOtherDetail = ({ data }: { data: any }) => {
  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (data) {
      const {
        _id,
        phone,
        medium,
        tenant_id,
        createdAt,
        mk_org_id,
        is_card_added,
        enable_pathway,
        stripe_user_id,
        total_learners,
        show_powerbi,
        show_sso_button,
        show_content_pro,
        show_get_started,
        subscription_status,
        payment_failed_count,
        show_video_classroom,
        show_community_forum,
        show_payment_setting,
        content_support_email,
        contact_support_email,
        show_sso_update_button,
        platform_support_email,
        show_client_marketplace,
        show_learner_marketplace,
        can_unenroll_paid_course,
        show_upcoming_event_widget,
        stripe_subscription_item_id,
        show_pending_user_invitation,
      } = data;
      setDetails({
        Card: is_card_added ? "Added" : "Not Added",
        "Tenant Id": tenant_id ?? "SSO not configured",

        "Role Update Button Status": tenant_id
          ? show_sso_update_button
            ? "Enabled"
            : "Disabled"
          : "SSO not configured",
        "Phone Number": phone ?? "",
        "Power Bi Status": show_powerbi ? "Enabled" : "Disabled",
        "Created Date": new Date(createdAt).toLocaleString() ?? "",
        "Stripe User Id": stripe_user_id ?? "",
        "Total Learners": total_learners ?? "",
        "Organization Id": _id ?? "",
        "Content Support Email": content_support_email ?? "",
        "Can Unenroll Paid Course": can_unenroll_paid_course ? "Yes" : "No",
        "Platform Support Email": platform_support_email ?? "",
        "Contact Support Email": contact_support_email ?? "",
        "Subscription Status": subscription_status ?? "",
        "Payment Failed Count": payment_failed_count ?? "",
        "AI Content Pro Status": show_content_pro ? "Enabled" : "Disabled",
        "Created By Third Party": medium ? "Yes" : "No",
        "Payment Setting": show_payment_setting ? "Enabled" : "Disabled",
        "Get Started Page Status": show_get_started ? "Enabled" : "Disabled",
        "Video Class Room Status": show_video_classroom
          ? "Enabled"
          : "Disabled",
        "Learning Pathway": enable_pathway ? "Enabled" : "Disabled",
        "Mykademy Organization Id": mk_org_id ?? "",
        "Learner Marketplace Status": show_learner_marketplace
          ? "Enabled"
          : "Disabled",

        "Stripe Subscription Item Id": stripe_subscription_item_id ?? "",
        "Pending User Invitation Status": show_pending_user_invitation
          ? "Enabled"
          : "Disabled",

        "Upcoming Sessions Widget Status": show_upcoming_event_widget
          ? "Enabled"
          : "Disabled",
        "Client Market Place Status": show_client_marketplace
          ? "Enabled"
          : "Disabled",
        "Community Forum Status": show_community_forum ? "Enabled" : "Disabled",
        "SSO Config Button Status": show_sso_button ? "Enabled" : "Disabled",
      });
    }
  }, [data]);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-center">
        <strong
          style={{
            fontSize: "16px",
          }}
        >
          Other Details
        </strong>
      </Card.Header>
      <Card.Body>
        {details &&
          Object.entries(details)?.map((detail, index) => (
            <EachDetail key={index} label={detail[0]} value={detail[1]} />
          ))}
      </Card.Body>
    </Card>
  );
};

export default OrganizationOtherDetail;
