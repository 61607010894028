import * as React from "react";

function PaypalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80px"
      height="80px"
      viewBox="0 0 780 501"
      xmlSpace="preserve"
      fill="#000"
    >
      <path
        fill="#003087"
        d="M168.379 169.853c-8.399-5.774-19.359-8.668-32.88-8.668H83.153c-4.145 0-6.435 2.073-6.87 6.215L55.018 300.883c-.221 1.311.107 2.51.981 3.6.869 1.092 1.962 1.635 3.271 1.635h24.864c4.361 0 6.758-2.068 7.198-6.215l5.888-35.986c.215-1.744.982-3.162 2.291-4.254 1.308-1.09 2.944-1.803 4.907-2.13 1.963-.324 3.814-.487 5.562-.487 1.743 0 3.814.11 6.217.327 2.397.218 3.925.324 4.58.324 18.756 0 33.478-5.285 44.167-15.866 10.684-10.577 16.032-25.243 16.032-44.004 0-12.867-4.202-22.191-12.597-27.974zm-26.99 40.08c-1.094 7.635-3.926 12.649-8.506 15.049-4.581 2.403-11.124 3.598-19.629 3.598l-10.797.327 5.563-35.007c.434-2.397 1.851-3.597 4.252-3.597h6.218c8.72 0 15.049 1.257 18.975 3.761 3.924 2.51 5.233 7.801 3.924 15.869z"
      />
      <path
        fill="#009CDE"
        d="M720.794 161.185h-24.208c-2.405 0-3.821 1.2-4.253 3.6l-21.267 136.099-.328.654c0 1.096.437 2.127 1.311 3.109.868.98 1.963 1.471 3.27 1.471h21.595c4.138 0 6.429-2.068 6.871-6.215L725.05 166.09v-.325c-.001-3.053-1.423-4.58-4.256-4.58z"
      />
      <path
        fill="#003087"
        d="M428.31 213.856c0-1.088-.439-2.126-1.306-3.106-.875-.981-1.858-1.474-2.945-1.474h-25.192c-2.404 0-4.366 1.096-5.889 3.271l-34.679 51.04-14.395-49.075c-1.095-3.487-3.492-5.236-7.197-5.236h-24.541c-1.093 0-2.074.492-2.941 1.474-.875.98-1.309 2.019-1.309 3.106 0 .44 2.127 6.871 6.379 19.303 4.252 12.435 8.832 25.849 13.74 40.245 4.908 14.393 7.469 22.031 7.688 22.898-17.886 24.43-26.826 37.517-26.826 39.259 0 2.838 1.416 4.254 4.253 4.254h25.192c2.398 0 4.36-1.088 5.889-3.27l83.427-120.399c.434-.433.652-1.193.652-2.29z"
      />
      <path
        fill="#009CDE"
        d="M662.887 209.276h-24.866c-3.055 0-4.904 3.6-5.558 10.798-5.677-8.721-16.031-13.088-31.083-13.088-15.704 0-29.066 5.89-40.077 17.668-11.016 11.778-16.521 25.631-16.521 41.551 0 12.871 3.761 23.121 11.285 30.752 7.525 7.639 17.612 11.451 30.266 11.451 6.323 0 12.757-1.311 19.3-3.926 6.544-2.617 11.665-6.105 15.379-10.469 0 .219-.222 1.199-.655 2.943-.44 1.748-.655 3.059-.655 3.926 0 3.494 1.414 5.234 4.254 5.234h22.576c4.138 0 6.541-2.068 7.194-6.215l13.415-85.39c.215-1.309-.112-2.507-.982-3.599-.875-1.089-1.963-1.636-3.272-1.636zm-42.694 64.453c-5.562 5.453-12.268 8.178-20.12 8.178-6.328 0-11.449-1.742-15.377-5.234-3.927-3.484-5.89-8.283-5.89-14.395 0-8.065 2.726-14.886 8.18-20.447 5.447-5.562 12.214-8.343 20.285-8.343 6.101 0 11.173 1.8 15.212 5.397 4.032 3.6 6.054 8.563 6.054 14.889-.001 7.851-2.783 14.505-8.344 19.955z"
      />
      <path
        fill="#003087"
        d="M291.231 209.276h-24.865c-3.058 0-4.908 3.6-5.563 10.798-5.889-8.721-16.25-13.088-31.081-13.088-15.704 0-29.065 5.89-40.078 17.668-11.016 11.778-16.521 25.631-16.521 41.551 0 12.871 3.763 23.121 11.288 30.752 7.525 7.639 17.61 11.451 30.262 11.451 6.104 0 12.433-1.311 18.975-3.926 6.543-2.617 11.778-6.105 15.704-10.469-.875 2.617-1.309 4.908-1.309 6.869 0 3.494 1.417 5.234 4.253 5.234h22.574c4.141 0 6.543-2.068 7.198-6.215l13.413-85.39c.215-1.309-.111-2.507-.981-3.599-.873-1.089-1.963-1.636-3.269-1.636zm-42.696 64.615c-5.563 5.35-12.382 8.016-20.447 8.016-6.329 0-11.4-1.742-15.214-5.234-3.819-3.484-5.726-8.283-5.726-14.395 0-8.065 2.725-14.886 8.18-20.447 5.449-5.562 12.211-8.343 20.284-8.343 6.104 0 11.175 1.8 15.214 5.397 4.032 3.6 6.052 8.563 6.052 14.889 0 8.07-2.781 14.779-8.343 20.117z"
      />
      <path
        fill="#009CDE"
        d="M540.036 169.853c-8.398-5.774-19.356-8.668-32.879-8.668h-52.019c-4.365 0-6.765 2.073-7.198 6.215l-21.265 133.483c-.221 1.311.106 2.51.981 3.6.866 1.092 1.962 1.635 3.271 1.635h26.826c2.617 0 4.361-1.416 5.235-4.252l5.89-37.949c.216-1.744.98-3.162 2.29-4.254 1.309-1.09 2.943-1.803 4.908-2.13 1.962-.324 3.813-.487 5.562-.487 1.743 0 3.814.11 6.214.327 2.399.218 3.93.324 4.58.324 18.759 0 33.479-5.285 44.168-15.866 10.687-10.577 16.031-25.243 16.031-44.004.001-12.867-4.2-22.191-12.595-27.974zm-33.534 53.82c-4.799 3.271-11.997 4.906-21.592 4.906l-10.47.327 5.563-35.007c.432-2.397 1.849-3.597 4.252-3.597h5.887c4.797 0 8.614.218 11.454.653 2.831.439 5.561 1.799 8.178 4.089 2.619 2.29 3.926 5.618 3.926 9.979 0 9.162-2.402 15.376-7.198 18.65z"
      />
    </svg>
  );
}

export default PaypalIcon;
