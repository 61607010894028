import Button from "react-myoneui/lib/Button";

import { CustomModal, ModalContents, ModalOpenButton, Table } from "components";

import Edit from "assets/images/edit.svg";
import Delete from "assets/images/delete.svg";

import PlanMemberShipForm from "./planMemberShipForm";

const getColumns = ({ updatePlanMember, deletePlanMember, memberships }) => [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Min Members",
    accessor: "min_members",
  },
  {
    Header: "Max Members",
    accessor: "max_members",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Actions",
    Cell: ({ row: { index, original } }) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <CustomModal>
            <ModalOpenButton>
              <Button variant="leading-icon" className="leading-icon">
                <img src={Edit} alt="edit" />
                Edit
              </Button>
            </ModalOpenButton>
            <ModalContents title="Add Video">
              <PlanMemberShipForm
                membership={original}
                updatePlanMember={updatePlanMember}
                isEdit={true}
                index={index}
                min={
                  memberships.sort((a, b) => a.min_members - b.min_members)[
                    index - 1
                  ] !== undefined
                    ? memberships.sort((a, b) => a.min_members - b.min_members)[
                        index - 1
                      ].max_members + 1
                    : 0
                }
              />
            </ModalContents>
          </CustomModal>

          <Button
            variant="leading-icon"
            className="leading-icon"
            onClick={() =>
              deletePlanMember(
                original._id !== undefined ? original._id : index
              )
            }
          >
            <img src={Delete} alt="delete" />
            Delete
          </Button>
        </div>
      );
    },
  },
];

const PlanMembershipList = ({
  memberships,
  updatePlanMember,
  deletePlanMember,
}: {
  memberships: any;
  updatePlanMember: any;
  deletePlanMember: any;
  minMember: number;
}) => {
  const tableData =
    memberships.length > 0 &&
    memberships.sort((a, b) => a.min_members - b.min_members);
  return (
    <div className="overflow-auto">
      <Table
        columnData={getColumns({
          updatePlanMember,
          deletePlanMember,
          memberships,
        })}
        tableData={tableData ? tableData : []}
      />
    </div>
  );
};

export default PlanMembershipList;
