import { useRef, useState } from "react";

import { useQuery } from "react-query";

import { getVideoList } from "api";

import { useDebounce } from "hooks";

const useVideoList = () => {
  const [perPage, setPerPage] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState("");

  const timerRef = useRef(null);

  const [searchText, setSearchText] = useState("");

  const debounceQuery = useDebounce(searchText, 500);

  const { data, isLoading } = useQuery(
    ["videoList", currentPage, perPage, debounceQuery],
    () => getVideoList(currentPage, perPage, debounceQuery)
  );

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setSearchText(value);
      setCurrentPage(1);
    }, 500);
  };

  const onClearSearch = () => {
    setSearch("");
    setSearchText("");
  };

  return {
    data,
    search,
    isLoading,
    setPerPage,
    currentPage,
    onClearSearch,
    onSearchChange,
    setCurrentPage,
  };
};

export default useVideoList;
