import { useEffect, useState } from "react";

import { Formik, Form as FormikForm } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { Input, Button, Card, Form } from "components";

import { modifyOrganizationSupportEmail } from "api";

import { error, success } from "utils";

import { validationSchema } from "./validationSchema";

const UpdateSupportEmail = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [initialValues, setInitialValues] = useState({
    contentSupportEmail: "",
    platformSupportEmail: "",
    contactSupportEmail: "",
  });
  const { state }: any = useLocation();

  const updateMutation = useMutation(
    (updateData: any) => modifyOrganizationSupportEmail(updateData),
    {
      onSuccess: (data: any) => {
        if (data && data.status && data.status === "success") {
          success(data.message);
          queryClient.invalidateQueries("getSingleOrg");
          navigate(-1);
        } else {
          error(data.message);
        }
      },
      onError: () => {
        error("there was an error");
      },
    }
  );

  const handleSubmit = (values, formik) => {
    try {
      if (state) {
        updateMutation.mutate({
          domain: state?.domain,
          content_support_email: values?.contentSupportEmail,
          platform_support_email: values?.platformSupportEmail,
          contact_support_email: values?.contactSupportEmail,
        });
      }
    } catch (err) {
      error(err.message);
    } finally {
      formik.setSubmitting(false);
    }
  };

  useEffect(() => {
    if (state) {
      setInitialValues({
        contentSupportEmail: state?.content_support_email,
        platformSupportEmail: state?.platform_support_email,
        contactSupportEmail: state?.contact_support_email,
      });
    }
  }, [state]);

  return (
    <Card className="h-auto">
      <Card.Header
        style={{
          fontWeight: "bold",
          fontSize: "0.9rem",
        }}
      >
        Change organization support email
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => {
            const { isSubmitting, isValid, dirty } = formik;

            return (
              <Form as={FormikForm}>
                <Input
                  name="contentSupportEmail"
                  placeholder="Content Support Email"
                  label="Content Support Email"
                />
                <Input
                  name="platformSupportEmail"
                  placeholder="Platform Support Email"
                  label="Platform Support Email"
                />
                <Input
                  name="contactSupportEmail"
                  placeholder="Contact Support Email"
                  label="Contact Support Email"
                />

                <Button
                  variant="dark"
                  type="submit"
                  className="mt-2"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  {isSubmitting ? "Updating" : "Update"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default UpdateSupportEmail;
