import { useQuery } from "react-query";

import {
  Table,
  Drawer,
  Button,
  ShowFilter,
  SecondaryHeader,
  OffcanvasOpenButton,
  SecondaryHeaderInfo,
} from "components";

import { getThirdParty } from "api";

import { useSelectedItemForEditContext } from "context";

import { thirdPartyTableColumns } from "../thirdPartyTableColumns";
import AddThirdParty from "../addThirdParty";

const ThirdPartyList = () => {
  const { setSelectedItemForEdit } = useSelectedItemForEditContext();
  const { isLoading, data } = useQuery("thirdPartyList", getThirdParty);

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle="Third Party"
            SecondaryHeaderText={`${data?.data?.length} data`}
            SecondaryText
          />
        </div>

        <div className="button-wrap">
          <Drawer handleExit={() => setSelectedItemForEdit(null)}>
            <OffcanvasOpenButton>
              <Button type="button" variant="secondary">
                Create
              </Button>
            </OffcanvasOpenButton>

            <AddThirdParty />
          </Drawer>
        </div>
      </SecondaryHeader>
      <div className="filter-block">
        <ShowFilter triggerTitle="Show Filters" />
      </div>
      <div className="content-block px-4 py-2">
        <Table
          columnData={thirdPartyTableColumns}
          tableData={data ? data?.data : []}
          pageCount={data?.data?.length}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default ThirdPartyList;
