import config from "../configs";
import { getToken } from "../utils/token";
import { errorHandler } from "./errorHandler";

export async function getPlanList(page = 0, perPage = 10, search = "") {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/v1/list-plans?page=${page}&perPage=${perPage}&delay=1&search=${search}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}
/**
 *
 * @param data
 * @returns
 */
export async function savePlan(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + "/v1/createPlanWithMembership",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function getPlan(planId: string) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + "/v1/plans/" + planId, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function deletePlan(planId: string) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + "/v1/deletePlan/" + planId,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}
