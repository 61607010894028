import {
  Table,
  Drawer,
  Button,
  SecondaryHeader,
  OffcanvasOpenButton,
  SecondaryHeaderInfo,
  SecondaryHeaderSearchField,
} from "components";

import { useSelectedItemForEditContext } from "context";

import CourseProviderFilter from "../courseProviderFilter";
import useGetCourseProviderList from "../useGetCourseProviderList";
import { courseProviderTableColumns } from "../courseProviderTableColumns";
import AddCourseProvider from "../addCourseProvider/addCourseProvider";

const CourseProviderList = () => {
  const {
    data,
    search,
    isActive,
    isLoading,
    isDefault,
    setPerPage,
    setFilters,
    currentPage,
    setIsActive,
    setIsDefault,
    onClearSearch,
    setCurrentPage,
    onSearchChange,
  } = useGetCourseProviderList();

  const { setSelectedItemForEdit } = useSelectedItemForEditContext();

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryText
            SecondaryHeaderText={`${data?.pagination?.totalDocs} course providers`}
            SecondaryHeaderTitle="Coure Providers"
          />
        </div>
        <SecondaryHeaderSearchField
          placeholder="Search course provider..."
          value={search}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={onSearchChange}
          onClear={onClearSearch}
        />
        <div className="button-wrap ">
          <Drawer handleExit={() => setSelectedItemForEdit(null)}>
            <OffcanvasOpenButton>
              <Button type="button" variant="secondary">
                Create
              </Button>
            </OffcanvasOpenButton>

            <AddCourseProvider />
          </Drawer>
        </div>
      </SecondaryHeader>
      <div className="filter-block">
        <CourseProviderFilter
          isActive={isActive}
          isDefault={isDefault}
          isLoading={isLoading}
          setFilters={setFilters}
          setIsActive={setIsActive}
          setIsDefault={setIsDefault}
          key="course-provider-filters"
        />
      </div>
      <div className="content-block px-4 py-2">
        <Table
          tableData={data ? data?.data : []}
          pageCount={data?.pagination?.totalPages}
          isLoading={isLoading}
          columnData={courseProviderTableColumns}
          setPerPage={setPerPage}
          pagination={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default CourseProviderList;
