import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import {
  Col,
  Row,
  Image,
  Table,
  Button,
  Drawer,
  CustomModal,
  ModalContents,
  SecondaryHeader,
  ModalOpenButton,
  AskPasswordForm,
  SecondaryHeaderInfo,
  OffcanvasOpenButton,
  Spinner,
} from "components";

import { getOrgLabelPage } from "api";

import { useSelectedItemForEditContext } from "context";

import { orgLabelTableColumns } from "./orgLabelTableColumns";
import AddOrgLabel from "./addLabelOrg";

const LabelPageList = () => {
  const params = useParams();
  const { state } = useLocation();

  const navigate = useNavigate();

  const { setSelectedItemForEdit } = useSelectedItemForEditContext();

  const { isLoading, data } = useQuery(
    ["orgLabelList", { params }],
    () => getOrgLabelPage(params?.labelPageId),
    {
      select: (data) => {
        return data?.data;
      },
    }
  );

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <BsFillArrowLeftCircleFill
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <SecondaryHeaderInfo
            SecondaryHeaderTitle={state}
            SecondaryHeaderText={`${data?.labels?.length} data`}
            SecondaryText
          />
        </div>

        <div className="button-wrap">
          <CustomModal className="w-80vw">
            <ModalOpenButton>
              <Button variant="secondary" type="button">
                Reset
              </Button>
            </ModalOpenButton>
            <ModalContents title="Enter Password To Reset">
              <AskPasswordForm actionType="resetSingleLabelPage" />
            </ModalContents>
          </CustomModal>
          <Drawer handleExit={() => setSelectedItemForEdit(null)}>
            <OffcanvasOpenButton>
              <Button type="button" variant="primary">
                Add Label
              </Button>
            </OffcanvasOpenButton>

            <AddOrgLabel />
          </Drawer>
        </div>
      </SecondaryHeader>
      <Row
        className="g-2 mb-3"
        style={{
          gridAutoFlow: "column",
        }}
      >
        <Col xl={8}>
          <div className="content-block px-4 py-3 h-100">
            {isLoading ? (
              <div className="w-full h-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <>
                <h3 className="fw-bold mb-4">Label Page Details</h3>
                <Row className="g-3 mb-3">
                  <Col xl={3}>
                    <strong>Label Page Name: </strong>
                    <div>{data?.labelPage}</div>
                  </Col>
                  <Col xl={3}>
                    <strong>Status: </strong>
                    <div>{data?.isActive ? "active" : "inactive"}</div>
                  </Col>
                  <Col xl={3}>
                    <strong>Total Labels: </strong>
                    <div>{data?.labels?.length}</div>
                  </Col>
                  <Col xl={3}>
                    <strong>Created At: </strong>
                    <div>{new Date(data?.createdAt).toDateString()}</div>
                  </Col>
                  <Col xl={3}>
                    <strong>Updated At: </strong>
                    <div>{new Date(data?.updatedAt).toDateString()}</div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Col>
        <Col xl={4}>
          <div className="content-block px-4 py-3 h-100">
            <Image
              src={
                data?.image ??
                "https://www.waterfieldtechnologies.com/wp-content/uploads/2019/02/placeholder-image-gray-3x2.png"
              }
              alt="labelPage"
              width="100%"
              height="100%"
              className="border"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          <div className="content-block px-4 py-2">
            <Table
              columnData={orgLabelTableColumns}
              tableData={data ? data?.labels : []}
              pageCount={data?.labels?.length}
              isLoading={isLoading}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LabelPageList;
