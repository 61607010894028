import React, { useState } from "react";

const AuthContext = React.createContext<{
  // eslint-disable-next-line @typescript-eslint/ban-types
  selectedOption: {};
  setSelectedOption: (state: boolean) => null;
}>({ selectedOption: {}, setSelectedOption: () => null });

AuthContext.displayName = "AuthContext";

function AuthProvider(props: any) {
  const [selectedOption, setSelectedOption] = useState({});

  const value = { selectedOption, setSelectedOption };

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}

export { AuthProvider, useAuth, AuthContext };
