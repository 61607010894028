import { FC, useState } from "react";

import { FiBook, FiSettings } from "react-icons/fi";
import { GiOrganigram } from "react-icons/gi";
import { BsCameraVideo } from "react-icons/bs";
import { HiOutlineBookOpen, HiOutlineKey } from "react-icons/hi";
import { MdOutlineAppSettingsAlt, MdPayment } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AiOutlineFile,
  AiOutlineUser,
  AiOutlineCalendar,
} from "react-icons/ai";
import {
  RiArrowDropUpLine,
  RiMailSettingsLine,
  RiArrowDropDownLine,
} from "react-icons/ri";

import PathwayIcon from "assets/images/pathway.png";

import { INavBarAndSideBarProps } from "./layout";

export const menus = [
  {
    id: 1,
    title: "organization",
    icon: <GiOrganigram className="h-6 w-6" />,
    url: "/",
  },
  {
    id: 2,
    title: "plan",
    icon: <AiOutlineCalendar className="h-6 w-6" />,
    url: "/planList",
  },

  {
    id: 3,
    title: "Video List",
    icon: <BsCameraVideo className="h-6 w-6" />,
    url: "/videoList",
  },
  {
    id: 4,
    title: "Third Party",
    icon: <AiOutlineUser className="h-6 w-6" />,
    url: "/thirdPartyList",
  },
  {
    id: 5,
    title: "logs",
    icon: <AiOutlineFile className="h-6 w-6" />,
    url: "/logList",
  },
  {
    id: 6,
    title: "course providers",
    icon: <HiOutlineBookOpen className="h-6 w-6" />,
    url: "/courseProviderList",
  },
  {
    id: 7,
    title: "purchase history",
    icon: <MdPayment className="h-6 w-6" />,
    url: "/purchaseHistory",
  },

  {
    id: 8,
    title: "labels",
    icon: <HiOutlineBookOpen className="h-6 w-6" />,
    url: "",
    children: [
      {
        id: 1,
        title: "Pages",
        icon: <FiBook className="h-6 w-6" />,
        url: "/labelPageList",
      },
      {
        id: 2,
        title: "Key",
        icon: <HiOutlineKey className="h-6 w-6" />,
        url: "/keyList",
      },
    ],
  },

  {
    id: 9,
    title: "settings",
    icon: <FiSettings className="h-6 w-6" />,
    url: "",
    children: [
      {
        id: 1,
        title: "Email",
        icon: <RiMailSettingsLine className="h-6 w-6" />,
        url: "/settings/email",
      },
      {
        id: 2,
        title: "Application",
        icon: <MdOutlineAppSettingsAlt className="h-6 w-6" />,
        url: "/settings/application",
      },
    ],
  },
];

export const SideBar: FC<INavBarAndSideBarProps> = ({
  handleHamburgerClick,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [clicked, setClicked] = useState({
    status: false,
    name: "",
  });
  const listSidebarItems = menus.map((item, i) => {
    const isActivePath = location.pathname === item.url && !!item.url;

    return (
      <>
        <li key={i} className={isActivePath ? "active" : ""}>
          <div
            onClick={(e) => {
              if (item.url) {
                setClicked({
                  status: false,
                  name: item.title,
                });
                navigate(item.url);
                handleHamburgerClick(e);
              } else {
                setClicked({
                  status: !clicked.status,
                  name: item.title,
                });
              }
            }}
            className="text-white link"
          >
            <div className="icon-wrap">
              <div className="d-flex align-items-center gap-2">
                {item.icon}
                {item.title}
              </div>

              {item.children ? (
                clicked?.status ? (
                  <RiArrowDropUpLine />
                ) : (
                  <RiArrowDropDownLine />
                )
              ) : null}
            </div>
          </div>
        </li>
        {item.children && clicked.status && clicked.name === item.title
          ? item.children.map((child, index) => {
              const isActiveChildPath =
                location.pathname === child.url && !item.url;

              return (
                <li key={index} className={isActiveChildPath ? "active" : ""}>
                  <div
                    onClick={(e) => {
                      navigate(child.url);
                      handleHamburgerClick(e);
                    }}
                    className="link"
                  >
                    <div className="icon-wrap">
                      <div className="d-flex w-100 ms-3 align-items-center gap-2">
                        {child.icon}
                        {child.title}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })
          : null}
      </>
    );
  });

  return (
    <div className="sidebar">
      <ul className="sidebar-list">{listSidebarItems}</ul>
    </div>
  );
};
