import {
  Table,
  Button,
  SecondaryHeader,
  SecondaryHeaderInfo,
  SecondaryHeaderSearchField,
} from "components";

import OrganizationFilter from "../organizationFilter";
import useGetOrganizationList from "../useGetOrganizationList";
import { organizationTableColumns } from "../organizationTableColumns";

const OrganizationList = () => {
  const {
    data,
    search,
    dateRange,
    isLoading,
    setFilters,
    setPerPage,
    currentPage,
    setDateRange,
    onClearSearch,
    thirdPartyOrg,
    onSearchChange,
    setCurrentPage,
    isSsoConfigured,
    setThirdPartyOrg,
    setIsSsoConfigured,
  } = useGetOrganizationList();

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryText
            SecondaryHeaderText={`${data?.total} organizations`}
            SecondaryHeaderTitle="Organizations"
          />
        </div>
        <SecondaryHeaderSearchField
          placeholder="Search Organization..."
          value={search}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={onSearchChange}
          onClear={onClearSearch}
        />
        <div className="button-wrap">
          <Button variant="secondary" style={{ visibility: "hidden" }}>
            Create
          </Button>
        </div>
      </SecondaryHeader>
      <div className="filter-block">
        <OrganizationFilter
          key="org-filter"
          dateRange={dateRange}
          isLoading={isLoading}
          setFilters={setFilters}
          setDateRange={setDateRange}
          thirdPartyOrg={thirdPartyOrg}
          isSsoConfigured={isSsoConfigured}
          setThirdPartyOrg={setThirdPartyOrg}
          setIsSsoConfigured={setIsSsoConfigured}
        />
      </div>
      <div className="content-block px-4 py-2">
        <Table
          tableData={data ? data?.data : []}
          pageCount={data?.total_pages}
          isLoading={isLoading}
          columnData={organizationTableColumns}
          setPerPage={setPerPage}
          pagination={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default OrganizationList;
