import PageKeyTableActions from "../pageKeyTableActions";

//predefined columns for table
export const pageKeyTableColumns = [
  {
    Header: "Name",
    accessor: "keyName",
  },
  {
    Header: "Description",
    accessor: "description",
  },

  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      return <PageKeyTableActions rowData={original} />;
    },
  },
];
