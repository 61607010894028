/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";

import { useNavigate } from "react-router-dom";

import {
  Table,
  Button,
  ShowFilter,
  SecondaryHeader,
  SecondaryHeaderInfo,
  SecondaryHeaderSearchField,
} from "components";

import useGetVideoList from "../useGetVideoList";
import { videoTableColumns } from "../videoTableColumns";

const Video = () => {
  const navigate = useNavigate();
  const {
    data,
    search,
    isLoading,
    setPerPage,
    currentPage,
    onClearSearch,
    onSearchChange,
    setCurrentPage,
  } = useGetVideoList();

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle="Videos"
            SecondaryHeaderText={`${data?.total} videos`}
            SecondaryText
          />
        </div>
        <SecondaryHeaderSearchField
          placeholder="Search Video..."
          value={search}
          //@ts-ignore
          onChange={onSearchChange}
          onClear={onClearSearch}
        />
        <div className="button-wrap">
          <Button
            variant="secondary"
            onClick={() => navigate("/video/addVideo")}
          >
            Create
          </Button>
        </div>
      </SecondaryHeader>
      <div className="filter-block">
        <ShowFilter triggerTitle="Show Filters" />
      </div>
      <div className="content-block px-4 py-2">
        <Table
          columnData={videoTableColumns}
          tableData={data ? data?.data : []}
          setCurrentPage={setCurrentPage}
          setPerPage={setPerPage}
          pagination={true}
          pageCount={data?.total_pages}
          isLoading={isLoading}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default Video;
