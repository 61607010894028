import { Field, useFormikContext } from "formik";
import useGetGamificationActivities from "screens/gamificationActivities/useGetGamificationActivities";

import { Card, SwitchButton } from "components";

const OrganizationGamificationActivities = () => {
  const { data } = useGetGamificationActivities();
  const { values }: any = useFormikContext();

  return (
    <Card>
      <Card.Header
        style={{
          fontWeight: "bold",
          fontSize: "1rem",
        }}
      >
        <SwitchButton
          label="Allow Reputation Points"
          name="isReputationPointsEnabled"
        />
      </Card.Header>

      <Card.Body
        style={{
          display: "grid",
          gridTemplateRows: values?.isReputationPointsEnabled ? "1fr" : "0fr",
          transition: "grid-template-rows 0.5s ease-out",
        }}
      >
        <div
          style={{
            overflow: "hidden",
            padding: "0 0 0 0.8rem",
          }}
        >
          <h3 style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
            Select activities
          </h3>
          {data &&
            data?.length > 0 &&
            values?.isReputationPointsEnabled &&
            data.map((item, index) => (
              <div key={index} className="d-flex align-items-center gap-2">
                <Field
                  key={index}
                  name="reputationActivities"
                  type="checkbox"
                  value={item._id}
                />
                <label>{item.name}</label>
              </div>
            ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default OrganizationGamificationActivities;
