import React from "react";

import { useNavigate } from "react-router-dom";

import {
  Table,
  Button,
  SecondaryHeader,
  SecondaryHeaderInfo,
} from "components";

import useGetEmailSettings from "./useGetEmailSettings";
import { emailSettingTableColumns } from "./emailSettingTableColumns";

const EmailSetting = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetEmailSettings();

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle="Email Settings"
            SecondaryHeaderText=""
            SecondaryText
          />
        </div>

        <div className="button-wrap">
          <Button
            variant="secondary"
            onClick={() => navigate("/settings/email/addScope")}
          >
            Create
          </Button>
        </div>
      </SecondaryHeader>

      <div className="content-block px-4 py-2">
        <Table
          columnData={emailSettingTableColumns}
          tableData={data ? data : []}
          isLoading={isLoading}
          key="emailSettingTable"
        />
      </div>
    </div>
  );
};

export default EmailSetting;
