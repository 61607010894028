import { useNavigate } from "react-router-dom";

import { Button } from "components";

import View from "assets/images/view.svg";

export const pendingUserInvitationColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Country",
    accessor: "country",
  },

  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      const navigate = useNavigate();
      return (
        <Button
          variant="leading-icon"
          className="leading-icon"
          onClick={() =>
            navigate(`/pendingUserInvitation/${original._id}`, {
              state: original,
            })
          }
        >
          <img src={View} alt="view" />
          Show Detail
        </Button>
      );
    },
  },
];
