import { error } from "./toast";

export function getToken(): string | any {
  const token = localStorage.getItem("token");
  if (!token) {
    localStorage.clear();
    error("Please login");
    window.location.href = "/login";
    return;
  }
  return token;
}
