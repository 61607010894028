import React from "react";

import Button from "react-myoneui/lib/Button";
import { useNavigate } from "react-router-dom";

import Edit from "assets/images/edit.svg";

const PlanTableEditAction = ({ rowData }) => {
  const navigate = useNavigate();
  return (
    <Button
      variant="leading-icon"
      className="leading-icon"
      type="button"
      onClick={() => navigate(`/plan/edit/${rowData?._id}`, { state: rowData })}
    >
      <img src={Edit} alt="edit" />
      Edit
    </Button>
  );
};

export default PlanTableEditAction;
