import React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "components";

import Info from "assets/images/info.svg";

const LabelPageTableViewKeys = ({ rowData }) => {
  const navigate = useNavigate();
  return (
    <Button
      variant="leading-icon"
      className="leading-icon"
      type="button"
      onClick={() =>
        navigate(`/keyList/${rowData?._id}`, {
          state: rowData?.labelPage,
        })
      }
    >
      <img src={Info} alt="view detail" />
      View Keys
    </Button>
  );
};

export default LabelPageTableViewKeys;
