import React from "react";

import { Card } from "components";

const EachDetail = ({ label, value }) => {
  return (
    <div className="d-flex gap-2">
      <strong>{label}:</strong>
      <p>{value}</p>
    </div>
  );
};

const DetailBox = ({
  data,
  title,
  noDataText,
}: {
  data: any;
  title: string;
  noDataText: string;
}) => {
  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-center">
        <strong
          style={{
            fontSize: "16px",
          }}
        >
          {title}
        </strong>
      </Card.Header>
      <Card.Body>
        {data ? (
          Object.entries(data)?.map((detail, index) => (
            <EachDetail key={index} label={detail[0]} value={detail[1]} />
          ))
        ) : (
          <div className="d-flex h-100 w-100 align-items-center justify-content-center">
            {noDataText}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default DetailBox;
