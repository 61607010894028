import VideoTableActions from "../videoTableActions";

export const videoTableColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Section",
    accessor: "section",
  },
  {
    Header: "No. Of Videos",
    Cell: ({ row: { original } }: any) => {
      return <span>{original.vleVideoList.length}</span>;
    },
  },
  {
    Header: "Actions",
    Cell: ({ row: { original } }: any) => {
      return <VideoTableActions rowData={original} key="videoTableActions" />;
    },
  },
];
