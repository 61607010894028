import { FC, forwardRef } from "react";

import DateRangePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IDatePickerProps {
  startDate: any;
  endDate: any;
  setDateRange: React.Dispatch<React.SetStateAction<any[]>>;
}

// eslint-disable-next-line react/display-name
const ExampleCustomInput = forwardRef(
  ({ value, onClick }: { value: any; onClick: any }, ref: any) => (
    <div className="form-floating">
      <input
        className="form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        id="floatingDates"
        type="text"
        placeholder="Enter Date Range"
        readOnly
      />
      <label htmlFor="floatingDates">Enter Date Range</label>
    </div>
  )
);

const DatePicker: FC<IDatePickerProps> = ({
  endDate,
  setDateRange,
  startDate,
}) => {
  return (
    <DateRangePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      customInput={<ExampleCustomInput />}
      isClearable={true}
      wrapperClassName="date_picker"
    />
  );
};

export default DatePicker;
