import React from "react";

const PageNotFound = () => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center  w-100"
      style={{
        height: "100vh",
      }}
    >
      <h1 className="">404</h1>
      <h3>Oops! Page not found.</h3>
      <p>{`The page you're looking for doesn't exist.`}</p>
    </div>
  );
};

export default PageNotFound;
