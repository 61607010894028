import { useNavigate } from "react-router-dom";

import { Button } from "components";

import Edit from "assets/images/edit.svg";

export const emailSettingColumns = [
  {
    Header: "Scope",
    accessor: "scope",
  },
  {
    Header: "Description",
    accessor: "description",
  },

  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      const navigate = useNavigate();
      return (
        <div className=" d-flex align-items-center">
          <Button
            variant="leading-icon"
            className="leading-icon"
            onClick={() =>
              navigate(
                `/organization/editEmailTemplate/${original.domain}/${original.id}`,
                {
                  state: original,
                }
              )
            }
          >
            <img src={Edit} alt="edit plan" />
            Edit
          </Button>
        </div>
      );
    },
  },
];
