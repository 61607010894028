import config from "configs";

import { getToken } from "utils";

import { errorHandler } from "./errorHandler";

export async function getLabelPageList(currentPage = 1, perPage = 10) {
  const token: string = getToken();
  const url = `/label-pages?page=${currentPage}&&limit=${perPage}`;

  const response = await fetch(config.mainApiEndpoint + url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function getOrgLabelPageList(domain: any) {
  const token: string = getToken();
  const url = `/label-pages/${domain}`;

  const response = await fetch(config.mainApiEndpoint + url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function getOrgLabelPage(pageId: any) {
  const token: string = getToken();
  const url = `/org-label-page/${pageId}`;

  const response = await fetch(config.mainApiEndpoint + url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function getLabelPage(id: any) {
  const token: string = getToken();
  const url = `/label-page/${id}`;

  const response = await fetch(config.mainApiEndpoint + url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function addLabelPage(data: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + "/label-page", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
}

export async function addLabel(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/label-page/${data?.labelPageId}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        key: data?.key,
        placeholder: data?.placeholder,
        label: data?.label,
      }),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function addLabelForOrganization(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/org-label-admin/${data?.labelPageId}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        key: data?.key,
        placeholder: data?.placeholder,
        label: data?.label,
      }),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function deleteLabelPage(id: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/label-page/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function deleteLabel(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/label-page/${data?.labelPageId}/${data?.labelId}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function deleteLabelForOrganization(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/org-label-admin/${data?.labelPageId}/${data?.labelId}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function updateLabelPage(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/label-page/${data?.id}`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data?.updatedData),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function updateLabel(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint +
      `/label-page/${data?.labelPageId}/${data?.labelId}`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        key: data?.key,
        placeholder: data?.placeholder,
        label: data?.label,
      }),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function updateLabelForOrganization(data: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/org-label-page`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      key: data?.key,
      placeholder: data?.placeholder,
      label: data?.label,
      pageId: data?.labelPageId,
      labelId: data?.labelId,
    }),
  });
  errorHandler(response);
  return response.json();
}

export async function resetAllLabelPage(data: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/reset-label-pages`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      domain: data?.domain,
      password: data?.password,
    }),
  });
  errorHandler(response);
  return response.json();
}

export async function resetSingleLabelPage(data: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/reset-label-page`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      pageId: data?.pageId,
      password: data?.password,
    }),
  });
  errorHandler(response);
  return response.json();
}

export async function activateOrDeactivateLabelPage(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/activate-deactivate-label-page`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        isActive: data?.isActive,
        pageId: data?.pageId,
      }),
    }
  );
  errorHandler(response);
  return response.json();
}
