import { useNavigate, useParams } from "react-router-dom";

import { Col, Row } from "components";

import { planColumns } from "./planColumns";
import { labelPageTableColumns } from "./labelPageColumns";
import { emailSettingColumns } from "./emailSettingColumns";
import OrganizationOtherDetail from "./organizationOtherDetail";
import OrganizationDetailHeader from "./organizationDetailHeader";
import useGetOrganizationDetail from "./useGetOrganizationDetail";
import OrganizationMainDetailCard from "./organizationMainDetailCard";
import { pendingUserInvitationColumns } from "./pendingUserInvitationColumns";
import OrganizationDetailComponentTable from "./organizationDetailComponentTable";
import { orgAssetTableColumns } from "./orgAssetTableColumns";
import { paymentSettingTableColumns } from "./paymentSettings/paymentSettingColumns/paymentSettingColumns";

export const OrganizationDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    data,
    loading,
    singleOrgData,
    isSingleOrgDataLoading,
    isLoading,
    setPerPage,
    currentPage,
    orgVideoList,
    setCurrentPage,
    setAssetPerPage,
    assetCurrentPage,
    orgLabelPageList,
    setAssetCurrentPage,
    isOrgVideoListLoading,
    pendingUserInvitationList,
    isOrgLabelPageListLoading,
  } = useGetOrganizationDetail();

  return (
    <div className="content-wrapper">
      <OrganizationDetailHeader data={singleOrgData} />
      <div className="p-4">
        <Row className="g-3">
          <Col xl={9}>
            <Row
              className="g-3"
              style={{
                gridAutoFlow: "column",
              }}
            >
              <Col xl={3}>
                <OrganizationMainDetailCard
                  title="Domain"
                  isLoading={isSingleOrgDataLoading}
                  value={singleOrgData?.domain}
                />
              </Col>
              <Col xl={3}>
                <OrganizationMainDetailCard
                  title="Name"
                  value={singleOrgData?.name}
                  isLoading={isSingleOrgDataLoading}
                />
              </Col>
              <Col xl={3}>
                <OrganizationMainDetailCard
                  title="Email"
                  isLoading={isSingleOrgDataLoading}
                  value={singleOrgData?.email}
                />
              </Col>
              <Col xl={3}>
                <OrganizationMainDetailCard
                  title="Login Page Status"
                  isLoading={isSingleOrgDataLoading}
                  value={
                    singleOrgData?.tenant_id
                      ? singleOrgData?.show_login_page
                        ? "Enabled"
                        : "Disabled"
                      : "SSO Not configured"
                  }
                />
              </Col>
              <Col xl={12}>
                <OrganizationDetailComponentTable
                  title="Payment Settings"
                  columns={paymentSettingTableColumns}
                  key="paymentSettingTable"
                  data={singleOrgData ? singleOrgData?.paymentSetting : []}
                  noDataText="No plan set for this organization"
                  isLoading={isLoading}
                />
              </Col>

              <Col xl={12}>
                <OrganizationDetailComponentTable
                  title="Current Plan"
                  columns={planColumns}
                  key="planTable"
                  data={
                    singleOrgData?.plan_id
                      ? [
                          {
                            title: singleOrgData?.plan_id?.title,
                            description: singleOrgData?.plan_id?.description,
                            currency: singleOrgData?.plan_id?.currency,
                            intervalType: singleOrgData?.plan_id?.intervalType,
                            email:
                              singleOrgData?.plan_id?.enterprise_contact_email,
                          },
                        ]
                      : []
                  }
                  noDataText="No plan set for this organization"
                  isLoading={isLoading}
                />
              </Col>

              <Col xl={12}>
                <OrganizationDetailComponentTable
                  title="Email Settings"
                  columns={emailSettingColumns}
                  data={data ? data : []}
                  noDataText="No Email Setting set for this organization"
                  isLoading={isLoading}
                  key="emailSettingTable"
                />
              </Col>
              <Col xl={12}>
                <OrganizationDetailComponentTable
                  title="Pending User Invitation List"
                  columns={pendingUserInvitationColumns}
                  key="pendingUserInvitationTable"
                  data={
                    pendingUserInvitationList
                      ? pendingUserInvitationList?.data
                      : []
                  }
                  isLoading={loading}
                  pageCount={pendingUserInvitationList?.total_pages}
                  noDataText="No Pending User Invitation Found for this organization"
                  pagination={true}
                  setPerPage={setPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  showPaginationInsideTable={true}
                />
              </Col>
              <Col xl={12}>
                <OrganizationDetailComponentTable
                  title="Label Settings"
                  showResetButton={true}
                  columns={labelPageTableColumns}
                  data={orgLabelPageList ? orgLabelPageList : []}
                  noDataText="No Label Setting set for this organization"
                  isLoading={isOrgLabelPageListLoading}
                  actionType="resetAllLabelPages"
                  key="label Table"
                />
              </Col>
              <Col xl={12}>
                <OrganizationDetailComponentTable
                  showPrimaryButton={true}
                  primaryButtonTitle="Add New Asset"
                  handleClickPrimaryButton={() =>
                    navigate(
                      `/organization/addSection/${params?.organizationId}`
                    )
                  }
                  key="assetTable"
                  showResetButton={true}
                  actionType="resetAsset"
                  title="Assets"
                  columns={orgAssetTableColumns}
                  data={orgVideoList ? orgVideoList?.assetList : []}
                  noDataText="No asset set for this organization"
                  isLoading={isOrgVideoListLoading}
                  pagination={true}
                  setPerPage={setAssetPerPage}
                  currentPage={assetCurrentPage}
                  setCurrentPage={setAssetCurrentPage}
                  showPaginationInsideTable={true}
                  pageCount={orgVideoList?.totalPage}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={3}>
            <Row className="g-3">
              <Col xl={12}>
                <OrganizationOtherDetail data={singleOrgData} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
