/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import { useQuery } from "react-query";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";

import { Control, getCustomStyles, ShowFilter } from "components";

import { getOperations, searchOrganization } from "api";

import { debounce, success } from "utils";

interface ILogFilterProps {
  selectedOrg: any;
  isLoading: boolean;
  selectedOperation: any;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  setSelectedOrg: React.Dispatch<React.SetStateAction<any>>;
  setSelectedOperation: React.Dispatch<React.SetStateAction<any>>;
}

const LogFilter: FC<ILogFilterProps> = ({
  isLoading,
  setFilters,
  selectedOrg,
  setSelectedOrg,
  selectedOperation,
  setSelectedOperation,
}) => {
  const { data: operationData } = useQuery(
    ["operationList"],
    () => getOperations(),
    {
      select: (data) => {
        const arr = Object.entries(data?.data).map((item) => ({
          label: item[0],
          value: item[1],
        }));
        return arr;
      },
    }
  );

  const handleOrganizationLoadOptions = (inputText, callback) => {
    return new Promise((resolve) => {
      debounce(
        () =>
          resolve(
            searchOrganization(inputText).then((res) =>
              callback(
                res?.data?.map((item) => ({
                  label: item.domain,
                  value: item.domain,
                }))
              )
            )
          ),
        500
      )();
    });
  };

  return (
    <ShowFilter
      triggerTitle="Show Filters"
      isLoading={isLoading}
      handleApply={() => {
        setFilters({
          domain: selectedOrg ? selectedOrg?.value : "",
          operation: selectedOperation ? selectedOperation.value : "",
        });
        success("Filter applied successfully!!");
        return "success";
      }}
    >
      <div className="d-flex flex-column gap-3">
        <div>
          <label>Search And Select Organization:</label>
          <AsyncSelect
            value={selectedOrg}
            onChange={(property) => {
              setSelectedOrg(property);
            }}
            isClearable={true}
            styles={getCustomStyles({ error: null, touched: null })}
            components={{
              Control: (props) => (
                <Control {...props} placeholder="Search Organization" />
              ),
            }}
            //@ts-ignore
            loadOptions={handleOrganizationLoadOptions}
          />
        </div>
        <div>
          <label>Select Operation:</label>
          <ReactSelect
            options={operationData ? operationData : []}
            key="org-filter"
            components={{
              Control: (props) => (
                <Control {...props} placeholder="Select operation" />
              ),
            }}
            isMulti={false}
            value={selectedOperation}
            name="sso"
            isClearable={true}
            styles={getCustomStyles({ error: null, touched: null })}
            onChange={(property: any) => setSelectedOperation(property)}
          />
        </div>
      </div>
    </ShowFilter>
  );
};

export default LogFilter;
