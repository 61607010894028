export const fieldData = [
  {
    id: 1,
    label: "Enable AI Content Pro",
    fieldName: "showContentPro",
  },
  {
    id: 2,
    label: "Enable Get Started Page",
    fieldName: "showGetStartedPage",
  },
  {
    id: 3,
    label: "Enable Video Class Room",
    fieldName: "showVideoClassRoom",
  },
  {
    id: 4,
    label: "Enable Learner Marketplace",
    fieldName: "showLearnerMarketPlace",
  },
  {
    id: 5,
    label: "Enable Pending User Invitation",
    fieldName: "showPendingUserInvitation",
  },
  {
    id: 6,
    label: "Enable Upcoming Session Widget",
    fieldName: "showUpcomingWidget",
  },
  {
    id: 7,
    label: "Enable tutor approval process",
    fieldName: "showTutorApprovalProcess",
  },
  {
    id: 8,
    label: "Enable unenrollment for paid course",
    fieldName: "canUnEnrollPaidCourse",
  },
  {
    id: 9,
    label: "Enable client market place",
    fieldName: "showClientMarketPlace",
  },
  {
    id: 10,
    label: "Show SSO Config Button",
    fieldName: "showSsoButton",
  },
  {
    id: 11,
    label: "Show Community Forum",
    fieldName: "showCommunityForum",
  },
  {
    id: 12,
    label: "Enable Payment Settings",
    fieldName: "showPaymentSetting",
  },
  {
    id: 13,
    label: "Enable Gamification Settings",
    fieldName: "showGamificationSetting",
  },
];
