import { error } from "../utils/toast";

export function errorHandler(response: any){
  if (!response.ok) {
    if ([401, 403].includes(response.status) && localStorage.getItem('token')) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api

        error('Please login');
        localStorage.removeItem('token');
        document.location.href ="/login";
    }
  }
}