import {
  cloneElement,
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";

import { Modal } from "components";

const ModalContext = createContext<
  [
    isOpen: boolean,
    setIsOpen: (state: boolean) => null,
    className: string,
    size: "sm" | "lg" | "xl",
    centered: boolean,
    backdrop: boolean
  ]
>([false, () => null, "", "sm", true, true]);

const callAll =
  (...fns: any) =>
  (...args: any) =>
    fns.forEach((fn: any) => fn && fn(...args));

function CustomModal(props: any) {
  const [isOpen, setIsopen] = useState(false);
  const { className, size, centered, backdrop } = props;

  return (
    <ModalContext.Provider
      value={[isOpen, setIsopen, className, size, centered, backdrop]}
      {...props}
    />
  );
}

function ModalDismissButton({ children }: { children: any }) {
  const [, setIsOpen] = useContext(ModalContext);

  return cloneElement(children, {
    onClick: callAll(() => setIsOpen(false), children.props.onClick),
  });
}

function ModalOpenButton({ children }: { children: any }) {
  const [, setIsOpen] = useContext(ModalContext);

  return cloneElement(children, {
    onClick: callAll(() => setIsOpen(true), children.props.onClick),
  });
}

function ModalSubmitButton({ children }: { children: any }) {
  const [, setIsOpen] = useContext(ModalContext);

  return cloneElement(children, {
    onClick: callAll(children.props.onClick, () => setIsOpen(false)),
  });
}

const ModalBase = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen, className, size, centered = true, backdrop = true] =
    useContext(ModalContext);
  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      size={size}
      backdrop={backdrop}
      centered={centered}
      contentClassName={`bg-white ${className}`}
      style={{
        background: "rgba(0,0,0,0.2)",
      }}
    >
      {children}
    </Modal>
  );
};
function ModalContents({
  title,
  children,
  ...props
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <ModalBase {...props}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <div>{children}</div>
    </ModalBase>
  );
}

export {
  CustomModal,
  ModalDismissButton,
  ModalOpenButton,
  ModalContents,
  ModalContext,
  ModalSubmitButton,
};
