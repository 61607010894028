import Button from "react-myoneui/lib/Button";
import { useNavigate } from "react-router-dom";
import SecondaryHeader from "react-myoneui/lib/SecondaryHeader";
import SecondaryHeaderInfo from "react-myoneui/lib/SecondaryHeaderInfo";
import SecondaryHeaderSearchField from "react-myoneui/lib/SecondaryHeaderSearchField";

import { ShowFilter, Table } from "components";

import useGetPlanList from "../useGetPlanList";
import { planTablecolumns } from "../planTableColumns";

const PlanList = () => {
  const navigate = useNavigate();
  const {
    data,
    search,
    isLoading,
    setPerPage,
    currentPage,
    onClearSearch,
    onSearchChange,
    setCurrentPage,
  } = useGetPlanList();

  return (
    <div className="content-wrapper">
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle="Plans"
            SecondaryHeaderText={`${data?.total} plans`}
            SecondaryText
          />
        </div>
        <SecondaryHeaderSearchField
          placeholder="Search Plan..."
          value={search}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={onSearchChange}
          onClear={onClearSearch}
        />
        <div className="button-wrap">
          <Button variant="secondary" onClick={() => navigate("/plan/addPlan")}>
            Create
          </Button>
        </div>
      </SecondaryHeader>
      <div className="filter-block">
        <ShowFilter triggerTitle="Show Filters" />
      </div>
      <div className="content-block px-4 py-2">
        <Table
          columnData={planTablecolumns}
          tableData={data ? data?.data : []}
          setCurrentPage={setCurrentPage}
          setPerPage={setPerPage}
          pagination={true}
          pageCount={data?.total_pages}
          isLoading={isLoading}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default PlanList;
