import { getToken } from "utils";

import config from "../configs";
import { errorHandler } from "./errorHandler";

export async function getKeyList() {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/label-keys`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function getPageKeyList(labelPageId: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/page-label-keys/${labelPageId}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function getAllKeyList() {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/all-label-keys/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function addKey(data: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + "/label-key", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
}

export async function addPageKey(data: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + "/page-label-key", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
}

export async function deleteKey(id: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + `/label-key/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function updateKey(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/label-key/${data?.id}`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        keyName: data?.keyName,
        description: data?.description,
      }),
    }
  );
  errorHandler(response);
  return response.json();
}
