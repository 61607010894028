import { useContext, useEffect, useState } from "react";

import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";

import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Button,
  CheckBox,
  ModalContext,
} from "components";

import { VideoListContext } from "context";

export const roles = [
  { value: "all", label: "All", isDisabled: false },
  { value: "tutor", label: "Tutor", isDisabled: false },
  { value: "mentor", label: "Mentor", isDisabled: false },
  { value: "admin", label: "Subadmin", isDisabled: false },
  { value: "learner", label: "Student", isDisabled: false },
  { value: "content_editor", label: "Content Editor", isDisabled: false },
  { value: "branch_manager", label: "Branch Manager", isDisabled: false },
  { value: "Finance Manager", label: "Finance Manager", isDisabled: false },
];
const fromOptions = [
  {
    value: "all",
    label: "all",
  },
  {
    value: "mykademy",
    label: "mykademy",
  },
  {
    value: "portfolio",
    label: "portfolio",
  },
];
const videoTypeOptions = [
  {
    value: "vimeo",
    label: "vimeo",
  },
];

const validationSchema = Yup.object().shape({
  from: Yup.string(),
  isActive: Yup.boolean(),
  description: Yup.string(),
  title: Yup.string().required("Required"),
  videoType: Yup.string().required("Required"),
  videoLink: Yup.string().required("Required"),
  type: Yup.array().required("Required").nullable(),
});

const VideoForm = (props) => {
  const [, setIsOpen] = useContext(ModalContext);
  const [, setVleVideo, setIsVideoListDirty] = useContext(VideoListContext);
  const [roleOptions, setRoleOptions] = useState(roles);

  const handleSubmit = (data: any) => {
    if (props.isEdit) {
      if (props.video && props.video._id !== undefined) {
        props.updateVideo(props.video._id, data);
      } else {
        props.updateVideo(props.index, data);
      }
    } else {
      setVleVideo(data);
    }
    setIsOpen(false);
    setIsVideoListDirty(true);
  };

  return (
    <Formik
      initialValues={
        props.video
          ? props.video
          : {
              from: "",
              title: "",
              type: null,
              videoLink: "",
              isActive: true,
              description: "",
              videoType: videoTypeOptions[0]?.value,
            }
      }
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { values, isSubmitting, isValid, dirty } = formik;

        useEffect(() => {
          if (values?.type) {
            setRoleOptions((prevOptions) =>
              prevOptions?.map((item) => {
                if (values?.type?.length === 1 && values?.type[0] === "all") {
                  const updated =
                    item?.value === "all"
                      ? item
                      : { ...item, isDisabled: true };

                  return updated;
                } else if (
                  values?.type?.length > 0 &&
                  !values?.type?.includes("all")
                ) {
                  return item?.value !== "all"
                    ? item
                    : { ...item, isDisabled: true };
                } else {
                  return { ...item, isDisabled: false };
                }
              })
            );
          }
        }, [values?.type]);

        return (
          <Form as={FormikForm}>
            <Row>
              <Col>
                <Input
                  type="text"
                  label="title"
                  placeholder="title"
                  name="title"
                />
                <Input
                  type="text"
                  label="description"
                  placeholder="description"
                  name="description"
                />
                <Input
                  type="text"
                  label="videoLink"
                  placeholder="videoLink"
                  name="videoLink"
                />
                <Select
                  placeholder="Select"
                  name="from"
                  defaultValue={
                    values.from
                      ? {
                          label: values?.from,
                          value: values?.from,
                        }
                      : null
                  }
                  options={fromOptions}
                />

                <Select
                  placeholder="Select Role"
                  name="type"
                  isMulti={true}
                  defaultValue={
                    values.type
                      ? values?.type?.map((item) =>
                          roles.find((role) => role?.value === item)
                        )
                      : null
                  }
                  options={roleOptions}
                />
                <Select
                  placeholder="Select Video Type"
                  name="videoType"
                  defaultValue={videoTypeOptions[0]}
                  options={videoTypeOptions}
                />
                <CheckBox
                  name="isActive"
                  label="Status"
                  defaultChecked={values?.isActive}
                />
              </Col>
            </Row>
            <div className="d-flex align-items-center mt-4 gap-3">
              <Button
                variant="secondary"
                type="button"
                onClick={() => handleSubmit(values)}
                disabled={!(isValid && dirty) || isSubmitting}
              >
                Send
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VideoForm;
