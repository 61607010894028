import config from "configs";

import { getToken } from "utils";

import { errorHandler } from "./errorHandler";

export async function getCourseProviders(
  page = 0,
  perPage = 10,
  filterText = "",
  filters: {
    isActive: undefined;
    isDefault: undefined;
  }
) {
  const token: string = getToken();
  let url = `/paginate-course-providers?page=${page}&page_limit=${perPage}`;
  if (filters.isActive !== undefined) {
    url = url + `&isActive=${filters.isActive}`;
  }
  if (filters.isDefault !== undefined) {
    url = url + `&isDefault=${filters.isDefault}`;
  }

  if (filterText) {
    url = url + `&search=${filterText}`;
  }

  const response = await fetch(config.mainApiEndpoint + url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  errorHandler(response);
  return response.json();
}

export async function addCourseProvider(data: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + "/course-provider", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
}

export async function deleteCourseProvider(id: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/course-provider/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  errorHandler(response);
  return response.json();
}

export async function modifyCourseProviderStatus(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/activate-deactivate-provider/${data?.id}`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data?.updatedData),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function updateCourseProvider(data: any) {
  const token: string = getToken();
  const response = await fetch(
    config.mainApiEndpoint + `/course-provider/${data?.id}`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data?.updatedData),
    }
  );
  errorHandler(response);
  return response.json();
}

export async function linkToStripe(data: any) {
  const token: string = getToken();
  const response = await fetch(config.mainApiEndpoint + "/link-to-stripe", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  errorHandler(response);
  return response.json();
}
