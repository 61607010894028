import GamificationActivityTableActions from "../gamificationActivityTableActions";

export const gamificationActivityTableColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "activityType",
  },
  {
    Header: "Created At",
    Cell: ({ row: { original } }: any) => {
      return (
        <span>
          {new Date(original.createdAt).toLocaleDateString("en-US", {
            dateStyle: "medium",
          })}
        </span>
      );
    },
  },

  {
    Header: "Actions",
    width: 300,
    Cell: ({ row: { original } }: any) => {
      return (
        <GamificationActivityTableActions
          rowData={original}
          key="gamificationTableActions"
        />
      );
    },
  },
];
