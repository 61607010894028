import { Navigate, useLocation } from "react-router-dom";

import Layout from "../layout";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  if (token === null) {
    localStorage.clear();
    return <Navigate to="/login" state={{ path: location.pathname }} />;
  }
  return <Layout>{children}</Layout>;
};

export default ProtectedRoute;
