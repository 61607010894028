import React, { FC } from "react";

import ReactSlider from "react-slider";

interface ISliderProps {
  value: number;
  handleChange: (value: number) => void;
  label: string;
}
const Slider: FC<ISliderProps> = ({ handleChange, value, label }) => {
  return (
    <div className="w-100">
      <label>{label}</label>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        value={value}
        onChange={handleChange}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
      />
    </div>
  );
};

export default Slider;
