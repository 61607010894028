import { useRef, useState } from "react";

import { useQuery } from "react-query";

import { getCourseProviders } from "api";

import { useDebounce } from "hooks";

const useGetCourseProviderList = () => {
  const [perPage, setPerPage] = useState(10);
  const [isActive, setIsActive] = useState(true);
  const [isDefault, setIsDefault] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const timerRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const debounceQuery = useDebounce(searchText, 500);
  const [filters, setFilters] = useState({
    isActive: undefined,
    isDefault: undefined,
  });

  const { data, isLoading, isSuccess } = useQuery(
    ["courseProviderList", currentPage, perPage, debounceQuery, filters],
    () => getCourseProviders(currentPage, perPage, debounceQuery, filters),
    {
      select: (data) => {
        const formattedData = data?.data?.data?.map((item) => ({
          id: item._id,
          title: item.title,
          isActive: item.isActive,
          provider: item.provider,
          isDefault: item.isDefault,
          isMailSent: item.isMailSent,
          paymentConfig: item.paymentConfig,
          isLinkedToStripe: item.isLinkedToStripe,
        }));
        return {
          data: formattedData,
          pagination: data?.data?.pagination,
        };
      },
    }
  );

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setSearchText(value);
      setCurrentPage(1);
    }, 500);
  };

  const onClearSearch = () => {
    setSearch("");
    setSearchText("");
    setCurrentPage(1);
  };

  return {
    data,
    search,
    perPage,
    filters,
    isActive,
    isLoading,
    isSuccess,
    isDefault,
    setPerPage,
    setFilters,
    currentPage,
    setIsActive,
    setIsDefault,
    onClearSearch,
    setCurrentPage,
    onSearchChange,
  };
};

export default useGetCourseProviderList;
