import { roles } from "screens/video/addVideo/videoForm";

import { Badge } from "components";

import AssetTableActions from "./assetTableActions";

//predefined columns for table
export const assetTableColumns = [
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ row: { original } }) => {
      return (
        <div
          className="d-inline-block text-truncate"
          style={{
            width: "250px",
          }}
        >
          {original?.title}
        </div>
      );
    },
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Video Link",
    accessor: "link",

    Cell: ({ row: { original } }) => {
      return (
        <div
          className="d-inline-block text-truncate"
          style={{
            width: "250px",
          }}
        >
          {original?.link}
        </div>
      );
    },
  },
  {
    Header: "Role",

    Cell: ({ row: { index, original } }) => {
      return (
        <div
          className="d-flex gap-2 flex-wrap"
          key={index}
          style={{
            width: "250px",
          }}
        >
          {original?.role?.map((item) => (
            <Badge key={item} className="badge dark">
              {roles?.find((role) => role.value === item)?.label}
            </Badge>
          ))}
        </div>
      );
    },
  },
  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      return <AssetTableActions rowData={original} />;
    },
  },
];
