import React from "react";

import Button from "react-myoneui/lib/Button";

import { CustomModal, ModalContents, ModalOpenButton } from "components";

import Delete from "assets/images/delete.svg";

import {
  PlanTableDeleteAction,
  PlanTableEditAction,
} from "../planTableActions";

export const planTablecolumns = [
  {
    Header: "Name",
    accessor: "title",
  },
  {
    Header: "Interval",
    accessor: "intervalType",
  },
  {
    Header: "No. Of Members",
    Cell: ({ row: { original } }) => {
      return <span>{original.membershipPlans.length}</span>;
    },
  },
  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      const { is_default } = original;
      return (
        <div className="d-flex align-items-center gap-3">
          <PlanTableEditAction rowData={original} key="planTableEditAction" />

          {is_default === 1 ? null : (
            <CustomModal centered={false} backdrop={false}>
              <ModalOpenButton>
                <Button
                  type="button"
                  className="leading-icon"
                  variant="leading-icon"
                >
                  <img src={Delete} alt="delete" />
                  Delete
                </Button>
              </ModalOpenButton>
              <ModalContents title="Are you sure? You want to delete this plan?">
                <PlanTableDeleteAction data={original} />
              </ModalContents>
            </CustomModal>
          )}
        </div>
      );
    },
  },
];
