import React, { FC } from "react";

import { Field } from "formik";

interface IFormGroupProps {
  label: string;
  fieldName: string;
}

const FormGroup: FC<IFormGroupProps> = ({ fieldName, label }) => {
  return (
    <div className="d-flex align-items-center gap-2 mb-2">
      <Field type="checkbox" name={fieldName} />
      <label>{label}</label>
    </div>
  );
};

export default FormGroup;
