import React, { FC } from "react";

import CourseProviderTableEditAction from "./courseProviderTableEditAction";
import CourseProviderTableDeactivateAction from "./courseProviderTableDeactivateAction";
import CourseProviderTableDeleteAction from "./courseProviderDeleteAction";
import LinkToStripe from "./linkToStripe";

interface ICourseProviderTableActionsProps {
  rowData: any;
}
const CourseProviderTableActions: FC<ICourseProviderTableActionsProps> = ({
  rowData,
}) => {
  return (
    <div className="d-flex">
      <CourseProviderTableEditAction
        rowData={rowData}
        key="courseProviderEditAction"
      />

      <CourseProviderTableDeactivateAction
        rowData={rowData}
        key="courseProviderDeactivateAction"
      />
      <CourseProviderTableDeleteAction
        rowData={rowData}
        key="courseProviderDeleteAction"
      />
      <LinkToStripe rowData={rowData} key="linkToStripe" />
    </div>
  );
};

export default CourseProviderTableActions;
