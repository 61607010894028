import { useField } from "formik";

import { Form } from "components";

interface Props {
  label?: string;
  requiredLabel?: boolean;
  name: string;
  disabled?: boolean;
  type?: any;
  placeholder?: string;
  defaultChecked?: boolean;
}

export const CheckBox: React.FC<Props> = ({
  label,
  defaultChecked,
  ...props
}) => {
  const [field] = useField(props);
  return (
    <Form.Check>
      <Form.Check.Input
        type="checkbox"
        {...props}
        {...field}
        name={props.name}
        checked={defaultChecked}
      />
      <Form.Check.Label
        htmlFor="flexRadioAccessValidity1"
        className="text-capitalize"
      >
        {label}
      </Form.Check.Label>
    </Form.Check>
  );
};
