import LabelTableActions from "../labelTableActions";

//predefined columns for table
export const labelTableColumns = [
  {
    Header: "Key",
    accessor: "key",
  },
  {
    Header: "Label",
    accessor: "label",
  },
  {
    Header: "Placeholder",
    accessor: "placeholder",
  },

  {
    Header: "Actions",
    Cell: ({ row: { original } }) => {
      return <LabelTableActions rowData={original} />;
    },
  },
];
