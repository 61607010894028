import { useContext } from "react";

import * as Yup from "yup";
import { Formik } from "formik";
import Button from "react-myoneui/lib/Button";
import Form from "react-myoneui/lib/Form";

import { Input, ModalContext } from "components";

import { PlanMembershipContext } from "context";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  price: Yup.number().required("Required"),
  min_members: Yup.number().required("Required"),
  max_members: Yup.number().required("Required"),
});

const PlanMemberShipForm = (props) => {
  const [, setIsOpen] = useContext(ModalContext);

  const [, setPlanMember, setIsDirty] = useContext(PlanMembershipContext);

  const handleSubmit = (data: any) => {
    if (props.isEdit) {
      if (props.membership && props.membership._id !== undefined) {
        props.updatePlanMember(props.membership._id, data);
      } else {
        props.updatePlanMember(props.index, data);
      }
    } else {
      setPlanMember(data);
    }
    setIsOpen(false);
    setIsDirty(true);
  };
  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={
          props?.membership
            ? props.membership
            : {
                name: "",
                min_members: "",
                max_members: "",
                price: "",
              }
        }
        validationSchema={validationSchema}
      >
        {({ values, isValid, dirty, isSubmitting }) => {
          return (
            <Form>
              <Input type="text" name="name" placeholder="Name" label="Name" />
              <Input
                type="text"
                name="min_members"
                placeholder="Min Members"
                label="Min Members"
              />
              <Input
                type="text"
                name="max_members"
                placeholder="Max Members"
                label="Max Members"
              />
              <Input
                type="text"
                name="price"
                placeholder="Price"
                label="Price"
              />
              <div className="d-flex align-items-center gap-3">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => handleSubmit(values)}
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  Send
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PlanMemberShipForm;
