import { useRef, useState } from "react";

import { useQuery } from "react-query";

import { getOrganizations } from "api";

import { useDebounce } from "hooks";

const useGetOrganizationList = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [isSsoConfigured, setIsSsoConfigured] = useState(undefined);
  const [thirdPartyOrg, setThirdPartyOrg] = useState(undefined);
  const [filters, setFilters] = useState({
    sso: isSsoConfigured,
    datePicked: [null, null],
    isThirdpartyOrg: thirdPartyOrg,
  });
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const timerRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const debounceQuery = useDebounce(searchText, 500);
  const { data, isLoading } = useQuery(
    ["organizationList", currentPage, perPage, debounceQuery, filters],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    () => getOrganizations(currentPage, perPage, debounceQuery, filters)
  );

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setSearchText(value);
      setCurrentPage(1);
    }, 500);
  };

  const onClearSearch = () => {
    setSearch("");
    setSearchText("");
    setCurrentPage(1);
  };
  return {
    data,
    search,
    isLoading,
    dateRange,
    setPerPage,
    setFilters,
    perPage,
    currentPage,
    setDateRange,
    thirdPartyOrg,
    onClearSearch,
    onSearchChange,
    setCurrentPage,
    isSsoConfigured,
    setThirdPartyOrg,
    setIsSsoConfigured,
  };
};

export default useGetOrganizationList;
