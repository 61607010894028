import React, { FC } from "react";

import ReactSelect from "react-select";

import { Control, DatePicker, getCustomStyles, ShowFilter } from "components";

import { success } from "utils";

interface IOrganizationFilterProps {
  dateRange: any;
  isLoading: boolean;
  thirdPartyOrg: any;
  isSsoConfigured: any;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  setDateRange: React.Dispatch<React.SetStateAction<any>>;
  setThirdPartyOrg: React.Dispatch<React.SetStateAction<any>>;
  setIsSsoConfigured: React.Dispatch<React.SetStateAction<any>>;
}

const OrganizationFilter: FC<IOrganizationFilterProps> = ({
  dateRange,
  isLoading,
  setFilters,
  setDateRange,
  thirdPartyOrg,
  isSsoConfigured,
  setThirdPartyOrg,
  setIsSsoConfigured,
}) => {
  const [startDate, endDate] = dateRange;

  return (
    <ShowFilter
      triggerTitle="Show Filters"
      isLoading={isLoading}
      handleApply={() => {
        setFilters({
          sso: isSsoConfigured ? isSsoConfigured.value : undefined,
          datePicked: [
            dateRange[0]
              ? new Date(dateRange[0]).toISOString().slice(0, 10)
              : null,
            dateRange[1]
              ? new Date(dateRange[1]).toISOString().slice(0, 10)
              : null,
          ],
          isThirdpartyOrg: thirdPartyOrg ? thirdPartyOrg.value : undefined,
        });
        success("Filter applied successfully!!");
        return "success";
      }}
    >
      <div className="d-flex flex-column gap-3">
        <div>
          <label>Choose SSO configuration:</label>
          <ReactSelect
            options={[
              {
                label: "SSO Enabled",
                value: true,
              },
              {
                label: "SSO Disabled",
                value: false,
              },
            ]}
            key="org-filter"
            components={{
              Control: (props) => (
                <Control {...props} placeholder="Select Filter Options" />
              ),
            }}
            isMulti={false}
            value={isSsoConfigured}
            name="sso"
            isClearable={true}
            styles={getCustomStyles({ error: null, touched: null })}
            onChange={(property: any) => setIsSsoConfigured(property)}
          />
        </div>

        <div>
          <label>Choose organization:</label>
          <ReactSelect
            options={[
              {
                label: "Third Party",
                value: true,
              },
              {
                label: "Non-third party",
                value: false,
              },
            ]}
            key="org-filter-by-party"
            components={{
              Control: (props) => (
                <Control {...props} placeholder="Select Filter Options" />
              ),
            }}
            isMulti={false}
            value={thirdPartyOrg}
            name="thirdPartyOrg"
            isClearable={true}
            styles={getCustomStyles({ error: null, touched: null })}
            onChange={(property: any) => setThirdPartyOrg(property)}
          />
        </div>
        <div>
          <label>Date Range:</label>
          <DatePicker
            key="date-picker"
            endDate={endDate}
            startDate={startDate}
            setDateRange={setDateRange}
          />
        </div>
      </div>
    </ShowFilter>
  );
};

export default OrganizationFilter;
