import React, { FC } from "react";

import { Card } from "components";

interface IDetailBoxProps {
  title: string;
  value: any;
}

const InfoBox: FC<IDetailBoxProps> = ({ title, value }) => {
  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex flex-column align-items-center justify-content-center ">
          <strong
            style={{
              fontSize: "16px",
            }}
          >
            {title}
          </strong>
          <p>{value}</p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default InfoBox;
