import React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "components";

import Edit from "assets/images/edit.svg";

const EmailSettingTableActions = ({ rowData }: any) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="leading-icon"
      className="leading-icon"
      onClick={() =>
        navigate(`/settings/editEmailSetting/${rowData.id}`, {
          state: rowData,
        })
      }
    >
      <img src={Edit} alt="edit plan" />
      Edit Scope
    </Button>
  );
};

export default EmailSettingTableActions;
