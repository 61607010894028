import { useEffect, useState } from "react";

import * as Yup from "yup";
import { useLocation, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { Field, Form as FormikForm, Formik } from "formik";

import {
  Form,
  Input,
  Select,
  Button,
  CheckBox,
  Offcanvas,
  useDrawerContext,
  OffcanvasContents,
  OffCanvasDismissButton,
} from "components";

import { createAsset, updateAsset, uploadImage } from "api";

import { useSelectedItemForEditContext } from "context";

import { error, success } from "utils";

import { roles } from "./addOrgSection";

const validationSchema = Yup.object().shape({
  isActive: Yup.boolean(),
  description: Yup.string().required("Required"),
  title: Yup.string().required("Required"),
  link: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  role: Yup.array()
    .required("Required")
    .min(1, "Must select atleast one options"),
});

const SectionForm = () => {
  const [, setIsOpen] = useDrawerContext();
  const { selectedItemForEdit, setSelectedItemForEdit } =
    useSelectedItemForEditContext();
  const queryClient = useQueryClient();
  const params = useParams();
  const [roleOptions, setRoleOptions] = useState(null);

  const location = useLocation();

  const createMutation = useMutation(createAsset, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("orgSection");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const updateMutation = useMutation(updateAsset, {
    onSuccess: (data: any) => {
      if (data && data.status && data.status === "success") {
        success(data.message);
        queryClient.invalidateQueries("orgSection");
      } else {
        error(data.message);
      }
    },
    onError: () => {
      error("there was an error");
    },
  });

  const handleSubmit = (data: any, setSubmitting, resetForm) => {
    if (selectedItemForEdit) {
      updateMutation
        .mutateAsync({
          sectionId: params?.sectionId,
          title: data?.title,
          description: data?.description,
          type: data?.type,
          link: data?.link,
          isActive: data?.isActive,
          assetId: selectedItemForEdit?._id,
          role: data?.role,
        })
        .then(() => {
          setSubmitting(false);
          setIsOpen(false);
          setSelectedItemForEdit(null);
          resetForm();
        });
    } else {
      createMutation
        .mutateAsync({
          sectionId: params?.sectionId,
          title: data?.title,
          description: data?.description,
          type: data?.type,
          link: data?.link,
          isActive: data?.isActive,
          role: data?.role,
        })
        .then(() => {
          setSubmitting(false);
          setIsOpen(false);
          setSelectedItemForEdit(null);
          resetForm();
        });
    }
  };

  const handleImageUpload = async (e, setFieldValue) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("client", "BT101");
    formData.append("bucket", "video-communication");
    await uploadImage(formData).then((res) =>
      setFieldValue("link", res.data[0].file.Location)
    );
  };

  return (
    <Formik
      initialValues={{
        title: selectedItemForEdit ? selectedItemForEdit.title : "",
        type: selectedItemForEdit ? selectedItemForEdit.type : "",
        link: selectedItemForEdit ? selectedItemForEdit.link : "",
        isActive: selectedItemForEdit ? selectedItemForEdit.isActive : true,
        description: selectedItemForEdit ? selectedItemForEdit.description : "",
        role: selectedItemForEdit ? selectedItemForEdit?.role : [],
        assetType: "link",
      }}
      onSubmit={() => console.log("hello")}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(formik) => {
        const {
          values,
          isSubmitting,
          isValid,
          dirty,
          setSubmitting,
          setFieldValue,
          resetForm,
        } = formik;
        useEffect(() => {
          if (location.state.role) {
            const filteredRoles = roles.filter((role: any) => {
              if (
                location.state.role.length === 1 &&
                location.state.role[0] === "all"
              ) {
                return role;
              } else {
                return location.state.role.includes(role.value);
              }
            });
            setFieldValue("role", location.state.role);
            setRoleOptions(filteredRoles);
          }
        }, []);
        useEffect(() => {
          if (values?.role) {
            setRoleOptions((prevOptions) =>
              prevOptions?.map((item) => {
                if (values?.role?.length === 1 && values?.role[0] === "all") {
                  const updated =
                    item?.value === "all"
                      ? item
                      : { ...item, isDisabled: true };

                  return updated;
                } else if (
                  values?.role?.length > 0 &&
                  !values?.role?.includes("all")
                ) {
                  return item?.value !== "all"
                    ? item
                    : { ...item, isDisabled: true };
                } else {
                  return { ...item, isDisabled: false };
                }
              })
            );
          }
        }, [values?.role]);

        return (
          <Form as={FormikForm}>
            <OffcanvasContents
              title={selectedItemForEdit ? "Update Media" : "Add Media"}
            >
              <Offcanvas.Body>
                <Input
                  type="text"
                  label="title"
                  placeholder="title"
                  name="title"
                />
                <Input
                  type="text"
                  label="description"
                  placeholder="description"
                  name="description"
                />

                <Select
                  placeholder="Select Type"
                  name="type"
                  defaultValue={
                    values.type
                      ? {
                          label: values?.type,
                          value: values?.type,
                        }
                      : null
                  }
                  options={[
                    {
                      label: "video",
                      value: "video",
                    },
                    {
                      label: "image",
                      value: "image",
                    },
                  ]}
                />
                {values?.type === "image" && (
                  <div className="d-flex align-items-center gap-4 mb-3">
                    <div className="d-flex align-items-center gap-2">
                      <Field type="radio" value="link" name="assetType" />
                      <label>Link</label>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <Field type="radio" value="image" name="assetType" />
                      <label>Upload Image</label>
                    </div>
                  </div>
                )}
                {values?.assetType === "link" ? (
                  <>
                    <Input
                      type="text"
                      label={
                        values?.type === "image"
                          ? "Enter Image Link"
                          : "Enter video link"
                      }
                      placeholder={
                        values?.type === "image"
                          ? "Enter Image Link"
                          : "Enter video link"
                      }
                      name="link"
                      marginBottom={values?.type === "video" ? "15px" : "20px"}
                    />
                    {values?.type === "video" && (
                      <Form.Text>
                        If you are using youtube link. Please consider using
                        embed link rather than watch link. For e.g
                        https://www.youtube.com/embed/kDQb84qjqqs
                      </Form.Text>
                    )}
                  </>
                ) : (
                  <div className="mb-3">
                    <input
                      type="file"
                      name="link"
                      onChange={(e) => handleImageUpload(e, setFieldValue)}
                    />
                  </div>
                )}

                <Select
                  placeholder="Select Role"
                  name="role"
                  isMulti={true}
                  // isDisabled={location?.state?.role?.length === 1}
                  defaultValue={
                    values.role && values.role.length > 0
                      ? values?.role?.map((item) =>
                          roles.find((role) => role?.value === item)
                        )
                      : null
                  }
                  options={roleOptions}
                />

                <CheckBox
                  name="isActive"
                  label="active"
                  defaultChecked={values?.isActive}
                />
              </Offcanvas.Body>
              <Offcanvas.Footer>
                <OffCanvasDismissButton>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setSelectedItemForEdit(false)}
                  >
                    Cancel
                  </Button>
                </OffCanvasDismissButton>
                <Button
                  variant="secondary"
                  type="button"
                  className="ms-2"
                  onClick={() => handleSubmit(values, setSubmitting, resetForm)}
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  {selectedItemForEdit ? "Update Media" : "Add Media"}
                </Button>
              </Offcanvas.Footer>
            </OffcanvasContents>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SectionForm;
